import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export function RocketIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="129"
      viewBox="0 0 128 129"
      fill="none"
    >
      <g id="Icon/Support">
        <g id="Group" opacity="0.8">
          <path
            id="Vector"
            d="M95.0796 79.1929C93.8649 79.3188 92.6747 79.6192 91.5458 80.0849C94.9329 85.7301 95.7796 90.9237 93.3409 93.3512C90.9022 95.7786 85.765 94.8867 80.0859 91.556C79.6467 92.6904 79.3435 93.8728 79.1827 95.0786C78.898 96.5559 78.9365 98.0773 79.2956 99.5383C79.6463 100.998 80.313 102.362 81.2488 103.535C82.1847 104.707 83.3619 105.663 84.7003 106.339C86.0387 107.015 87.5071 107.395 89.0054 107.453L108.967 108.966L107.488 89.0382C107.429 87.5339 107.045 86.06 106.364 84.7175C105.683 83.375 104.72 82.1954 103.54 81.2595C102.361 80.3236 100.994 79.6535 99.5313 79.2948C98.0692 78.9362 96.5468 78.8976 95.0684 79.1817"
            fill="#FDCC09"
          />
          <path
            id="Vector_2"
            d="M79.5666 35.0587C75.5366 31.0209 71.2889 27.2066 66.8423 23.6328C59.4257 17.597 50.8767 13.1057 41.6985 10.423C21.7596 4.77777 10.8982 10.9198 10.8982 10.9198C10.8982 10.9198 4.72229 21.7699 10.4014 41.7088C13.0826 50.8921 17.5826 59.4427 23.6338 66.8526C27.1963 71.3233 31.0071 75.5903 35.0484 79.6333C35.0484 79.6333 55.2019 100.295 66.8988 99.3464C66.8988 99.3464 73.5714 99.9223 82.7054 93.0803C79.3679 91.0256 76.2899 88.5768 73.5375 85.7866C64.9907 77.2511 60.7906 67.5977 64.1439 64.2445C67.4972 60.8912 77.1392 65.1026 85.6748 73.6494C88.4649 76.4017 90.9137 79.4798 92.9683 82.8173C99.8103 73.6381 99.2345 67.0106 99.2345 67.0106C100.194 55.3138 79.5215 35.1603 79.5215 35.1603"
            fill="url(#paint0_linear_115_164)"
          />
          <path
            id="Vector_3"
            d="M73.0294 85.0076C63.4664 75.4446 58.3519 65.0235 61.739 61.7155C65.1261 58.4074 75.4682 63.4768 85.0312 73.0059C94.5942 82.535 99.7087 92.99 96.3216 96.2868C92.9345 99.5836 82.5811 94.5706 73.0294 85.0076ZM55.2696 119.15L47.5356 111.416C32.3951 96.2755 36.3693 80.2882 36.3693 80.2882L37.25 80.5705C44.9388 87.2205 56.1051 95.5868 63.5003 95.4513H63.76H64.3019H64.4148L60.1358 117.49C60.0253 118.072 59.7419 118.607 59.3228 119.026C59.0627 119.295 58.7519 119.51 58.4083 119.658C58.0539 119.819 57.6684 119.9 57.2793 119.895C56.8937 119.909 56.5095 119.844 56.1502 119.703C55.8253 119.574 55.5268 119.387 55.2696 119.15ZM117.536 60.1348L95.4974 64.4251V64.3122C95.4974 64.0751 95.4974 63.9058 95.4974 63.7703V63.7138C95.5022 63.65 95.5022 63.5858 95.4974 63.5219C95.6329 56.1154 87.2666 44.9492 80.6166 37.2717L80.3343 36.3797C80.3343 36.3797 96.3216 32.4055 111.462 47.5572L119.207 55.2912C119.467 55.5614 119.67 55.8802 119.806 56.2293C119.942 56.5783 120.008 56.9508 119.999 57.3253C119.991 57.6998 119.909 58.0689 119.757 58.4116C119.606 58.7542 119.388 59.0635 119.117 59.3219C118.692 59.7567 118.137 60.0419 117.536 60.1348ZM29.6854 57.6396C27.917 55.8131 26.5277 53.6546 25.5975 51.2886C24.6674 48.9225 24.2149 46.3958 24.266 43.854C24.4589 38.7322 26.5799 33.8725 30.2041 30.2483C33.8283 26.6241 38.688 24.5031 43.8098 24.3102C46.3545 24.2203 48.8906 24.6553 51.26 25.588C53.6294 26.5208 55.7813 27.9313 57.5818 29.7319C59.3824 31.5324 60.793 33.6844 61.7258 36.0538C62.6585 38.4232 63.0934 40.9592 63.0035 43.504C62.8159 48.6335 60.6942 53.5019 57.0647 57.1315C53.4351 60.761 48.5667 62.8827 43.4372 63.0703C40.8998 63.1192 38.3779 62.6644 36.0174 61.7322C33.6569 60.8001 31.5048 59.409 29.6854 57.6396ZM9.36264 36.1878C5.97551 19.7376 10.932 10.8746 10.932 10.8746C10.932 10.8746 19.795 5.9294 36.2452 9.30524C34.5182 15.7515 31.1247 21.6295 26.4058 26.3484C21.6869 31.0673 15.8089 34.4608 9.36264 36.1878Z"
            fill="#EA3327"
          />
          <path
            id="Vector_4"
            d="M49.1841 37.5878C50.338 38.7228 51.1247 40.1781 51.4422 41.7652C51.7557 43.3543 51.5906 45.0007 50.968 46.4959C50.3368 47.9867 49.2854 49.2616 47.9421 50.1653C46.6055 51.0624 45.0357 51.5491 43.426 51.5654C41.8187 51.5658 40.2473 51.0904 38.9098 50.1992C37.5652 49.3078 36.5191 48.0335 35.9066 46.5411C35.2908 45.0437 35.1336 43.3972 35.4549 41.8104C35.8297 39.9533 36.8345 38.2826 38.2994 37.0811C39.7642 35.8796 41.5991 35.221 43.4937 35.2168C45.6287 35.2269 47.6736 36.079 49.1841 37.5878Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_115_164"
          x1="55.9991"
          y1="8.03663"
          x2="55.9991"
          y2="99.3781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F4F6F8" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
