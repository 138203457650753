import angular from 'angular';

import { EmptyPageComponent } from './empty-page.component';

const MODULE_NAME = 'app.pickups.empty-page';

angular
  .module(MODULE_NAME, ['ui.router', 'core.config'])
  .component('esPickupsEmptyPage', EmptyPageComponent);

export default MODULE_NAME;
