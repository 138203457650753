import { IOptionalDimensionsModalService } from 'typings/dimension';
import { IReportService } from 'typings/report';
import { IAddressService } from 'typings/address';
import { IUserSession } from 'typings/user-session';
import {
  IShipmentListAdvancedTotalData,
  IShipmentListItem,
  IShipmentListLoading,
  IShipmentListDefaultViewsItem,
  IShipmentListCustomViewsItem,
  IShipmentSortByOptions,
  SortByAttribute,
  SortDirection,
} from 'typings/shipment';
import { IItemCategoryService } from 'typings/item-category';
import { IEndpointService } from 'typings/endpoint';
import { IShowChangePlanObject } from 'typings/subscription';
import { IStore, IStoreService } from 'typings/store';

import { IShipmentListFiltersData } from '@client/src/global/services/shipment-list/shipment-list-normalize.service';
import { ShipmentListAdvancedService as IShipmentListAdvancedService } from '@client/src/global/services/shipment-list/shipment-list-advanced.service';

import {
  DefinedSubscriptionPlanId,
  FEATURE_KEY,
  FeatureKey,
  FlexibleSubscriptionPlanId,
} from '@client/data/subscription';
import { IApiPrepareCheckoutResponse, ICheckoutService } from 'typings/checkout';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { TagsService } from '@client/src/global/services/tags/tags.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { IWalkthroughService } from 'typings/walkthrough';
import { toastError } from '@client/core/components/react/Toastify';
import template from './create-shipments-advanced.html?raw';
import style from './create-shipments-advanced.module.scss';
import { SectionName } from '../shipment-details-drawer';
import { IDashboardHeaderModal } from './types';

const SCOPE_ORDERS_ALL = 'orders_all';

const DEFAULT_LIST_LIMIT = 20;

class CreateShipmentsAdvanced implements IComponentController {
  style = style;
  showSpinner = false;
  isFilterOpen = false;
  keyword: string | null = null;
  viewsBusy = false;
  listLimit = DEFAULT_LIST_LIMIT;
  isDrawerOpen = false;
  drawerSelectedIndex = -1;
  drawerSelectedShipmentId: string | null = null;
  drawerSelectedSection: SectionName = 'order';
  drawerNavigationDisabled = false;
  disconnectedStores: IStore[] = [];
  changePlanModal: Partial<IShowChangePlanObject> = {
    enterpriseCall: false,
    upgrade: false,
  };
  triggerSource?: string;
  suggestedPlanId?: DefinedSubscriptionPlanId | FlexibleSubscriptionPlanId;
  dashboardHeaderActiveModal: IDashboardHeaderModal | '' = '';
  upsDapCountries: string[] = [];
  originAddressId = '';
  preparedData: IApiPrepareCheckoutResponse | null = null;

  static $inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$location',
    '$cookies',
    '$timeout',
    '$translate',
    'UserSession',
    'ShipmentListAdvancedService',
    'ItemCategoryService',
    'AddressService',
    'StoreService',
    'ReportService',
    'OptionalDimensionsModalService',
    'EndpointService',
    'TagsService',
    'SubscriptionService',
    'AppCuesService',
    'CheckoutService',
    'MixpanelService',
    'WalkthroughService',
  ];
  constructor(
    private $scope: ng.IScope,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private $location: ng.ILocationService,
    private $cookies: angular.cookies.ICookiesService,
    private $timeout: ng.ITimeoutService,
    private $translate: angular.translate.ITranslateService,
    private UserSession: IUserSession,
    private ShipmentListAdvancedService: IShipmentListAdvancedService,
    private ItemCategoryService: IItemCategoryService,
    private AddressService: IAddressService,
    private StoreService: IStoreService,
    private ReportService: IReportService,
    private OptionalDimensionsModalService: IOptionalDimensionsModalService,
    private EndpointService: IEndpointService,
    private TagsService: TagsService,
    private SubscriptionService: SubscriptionService,
    private AppCuesService: AppCuesService,
    private CheckoutService: ICheckoutService,
    private MixpanelService: MixpanelService,
    private WalkthroughService: IWalkthroughService
  ) {
    this.closeDrawer = this.closeDrawer.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
    this.showChangePlanModal = this.showChangePlanModal.bind(this);
    this.routeToCouriers = this.routeToCouriers.bind(this);
    this.hideStoreAlert = this.hideStoreAlert.bind(this);
    this.openDashboardHeaderModal = this.openDashboardHeaderModal.bind(this);
    this.onPrepareSingleShipment = this.onPrepareSingleShipment.bind(this);
    this.onPrepareMultipleShipments = this.onPrepareMultipleShipments.bind(this);
    this._onPrepareCheckoutError = this._onPrepareCheckoutError.bind(this);
    this._handlePrepareCheckoutResponse = this._handlePrepareCheckoutResponse.bind(this);

    if (this.canCompanyCustomizeShipmentTable) {
      this.onRowHeaderCheck = this.onRowHeaderCheck.bind(this);
      this.selectAll = this.selectAll.bind(this);
      this.deselectAll = this.deselectAll.bind(this);
      this.onCheckboxChange = this.onCheckboxChange.bind(this);
      this.onOrderCellClick = this.onOrderCellClick.bind(this);
      this.onReceiverCellClick = this.onReceiverCellClick.bind(this);
      this.onCourierCellClick = this.onCourierCellClick.bind(this);
      this.onRefreshCellClick = this.onRefreshCellClick.bind(this);
    }
  }

  $onInit(): void {
    // Reset Default state
    const isKeepData = this.$stateParams.from_request_pickup
      ? JSON.parse(this.$stateParams.from_request_pickup)
      : false;
    this.ShipmentListAdvancedService.resetAll(isKeepData, isKeepData);

    // Set page number and limit
    if (this.$stateParams && this.$stateParams.page) {
      this.ShipmentListAdvancedService.pageNum = parseInt(this.$stateParams.page, 10);
    }
    this.ShipmentListAdvancedService.setLimitFromUserSession();
    this.ItemCategoryService.getItemCategories();
    this.AddressService.getAddresses();

    if (this.ReportService.busy && !this.$stateParams.reload) {
      this.showSpinner = true;
      return;
    }

    if (this.$stateParams.valid_to_ship) {
      this.ShipmentListAdvancedService.setShipmentAndTotalBusy();
      this.fetchShipmentsWithPrepareData();
      this.ShipmentListAdvancedService.fetchAdvancedCustomViews();
    } else {
      this.fetchCustomViewsWithShipments();
    }

    this.StoreService.getStores().then(({ stores }) => {
      const dismissed = this.$cookies.getObject('ES_DISMISSED_ALERTS') || [];

      this.disconnectedStores = stores.filter(function (store) {
        return (
          (store.auth_state === 'auth_failed' || store.requires_reauthorize_permission) &&
          !dismissed.includes(store.id)
        );
      });
    });

    this.$scope.$watch(
      () => this.$state.params.shipment_id,
      () => {
        if (!this.shipments || this.isDrawerOpen) {
          return;
        }

        this.initShipmentPanel(this.shipments);
      }
    );

    this.$scope.$watch(
      () => this.shipments,
      (shipments) => {
        if (shipments) {
          if (shipments.length > 0) {
            // Sometimes shipments are created by background jobs non-detected by the FE
            // This even ensures AppCues always knows that shipments have been created
            this.AppCuesService.track('Advanced | Created Shipment', null, true);
          }

          const shipmentWithNoRates = shipments.find((shipment) =>
            this.isShipmentWithNoRates(shipment)
          );

          if (shipmentWithNoRates) {
            this.AppCuesService.track('Advanced | Shipment No Rates', null, true);
          }

          this.initShipmentPanel(shipments);

          this.$timeout(() => {
            // Ensure AppCues (re)triggers when the shipments are loaded
            // Certain AppCues flows would trigger too early and need the shipments table to be visible
            this.AppCuesService.page();

            // Delay is used as a workaround to ensure the HTML elements are loaded
          }, 0);
        }
      }
    );
  }

  get isNewShipmentsTableEnabled(): boolean {
    return this.UserSession.getFeatureFlagsByFlagName('new_shipments_table');
  }

  get isClassicViewUser(): boolean {
    return !!this.UserSession.user.dashboard_settings?.multiple.retain_classic_view;
  }

  get canCompanyCustomizeShipmentTable(): boolean {
    return (
      this.isNewShipmentsTableEnabled && !this.UserSession.isAmexUser() && !this.isClassicViewUser
    );
  }

  initShipmentPanel(shipments: IShipmentListItem[]): void {
    if (!this.$stateParams.shipment_id || !this.isShipmentPanelViewActive) return;

    const selectedIndex = shipments.findIndex(
      (shipment) => shipment.id === this.$stateParams.shipment_id
    );
    this.openDrawerOnSection(
      this.$stateParams.shipment_id,
      selectedIndex,
      this.drawerSelectedSection
    );
  }

  isShipmentWithNoRates(shipment: IShipmentListItem): boolean {
    return !shipment.is_valid_to_ship && shipment.total_charge === 0;
  }

  get isShipmentPanelViewActive(): boolean {
    return !!this.UserSession.showAdvancedShipmentPanelView();
  }

  onChangePage(value: number): void {
    this.resetListLimit();
    this.ShipmentListAdvancedService.pageNum = value;
    this.fetchShipments();
  }

  onRowHeaderCheck(value: boolean): void {
    this.ShipmentListAdvancedService.rowHeaderCheck(value);
  }

  onSelectItemPerPage(value: number): void {
    this.ShipmentListAdvancedService.resetShipmentsLimit(value, SCOPE_ORDERS_ALL);
  }

  selectAll(): void {
    this.ShipmentListAdvancedService.selectAllShipments();
  }

  deselectAll(): void {
    this.ShipmentListAdvancedService.deselectAllShipments();
  }

  onCheckboxChange(value: boolean, index: number): void {
    this.ShipmentListAdvancedService.rowListCheck(value, index);
  }

  closeDrawer(e: Event): void {
    const targetElement = e.target as HTMLElement;
    const classMatchRegex = /shipment-list-(?:order|receiver|courier)-cell/;

    const isSelectingShipment = classMatchRegex.test(targetElement.className);
    if (!isSelectingShipment) {
      this.isDrawerOpen = false;
      this.drawerSelectedIndex = -1;
      this.setShipmentIdToUrlParams(null);
    }
    this.$scope.$apply();
  }

  handleClickAway(e: Event): void {
    this.closeDrawer(e);
  }

  handleNavigation(indexOffset: number): void {
    if (this.shipments) {
      this.drawerSelectedIndex += indexOffset;

      if (this.drawerSelectedIndex < 0) {
        this.drawerSelectedIndex = 0;
      } else if (this.drawerSelectedIndex > this.shipments.length - 1) {
        this.drawerSelectedIndex = this.shipments.length - 1;
      }
    }
    const shipment = this.shipments?.[this.drawerSelectedIndex];
    if (!shipment) return;
    this.openDrawerOnSection(shipment.id, this.drawerSelectedIndex);
    this.$scope.$apply();
  }

  showChangePlanModal({
    triggerSource,
    featureKey,
  }: {
    triggerSource: string;
    featureKey: FeatureKey;
  }) {
    if (this.UserSession.isCompanyEfulfilment()) {
      toastError(this.$translate.instant('shipment-info.pay-on-scan.eful-error'));
      return;
    }

    const isEnterprisePlan =
      this.SubscriptionService.currentSubscription?.plan?.id ===
      FlexibleSubscriptionPlanId.Enterprise;

    if (isEnterprisePlan) {
      this.changePlanModal.enterpriseCall = true;
    } else {
      this.suggestedPlanId = this.SubscriptionService.getSuggestedPlanIdByFeatureKey(featureKey);
      this.triggerSource = triggerSource;
      this.changePlanModal.upgrade = true;
    }

    this.$scope.$apply();
  }

  routeToCouriers() {
    this.$state.go('app.couriers');
  }

  currentSubscription() {
    return this.SubscriptionService.currentSubscription;
  }

  setShipmentIdToUrlParams(shipmentId: string | null): void {
    this.$location.search('shipment_id', shipmentId).replace();
  }

  openDrawerOnSection(shipmentId: string, index: number, sectionName: SectionName = 'order'): void {
    this.setShipmentIdToUrlParams(shipmentId);
    this.drawerSelectedShipmentId = shipmentId;
    this.drawerSelectedSection = sectionName;
    this.drawerSelectedIndex = index;
    this.isDrawerOpen = true;
    this.drawerNavigationDisabled = false;
  }

  onOrderCellClick(_: MouseEvent, index: number): void {
    const shipment = this.shipments?.[index];

    if (!shipment) return;

    if (this.isShipmentPanelViewActive) {
      this.openDrawerOnSection(shipment.id, index, 'order');
    } else {
      this.ShipmentListAdvancedService.toggleOrderForm(index);
    }
  }

  onReceiverCellClick(_: MouseEvent, index: number): void {
    const shipment = this.shipments?.[index];

    if (!shipment) return;

    if (this.isShipmentPanelViewActive) {
      this.openDrawerOnSection(shipment.id, index, 'addresses');
    } else {
      this.ShipmentListAdvancedService.toggleReceiverForm(index);
    }
  }

  onCourierCellClick(_: MouseEvent, index: number): void {
    const shipment = this.shipments?.[index];

    if (!shipment) return;

    if (this.isShipmentPanelViewActive) {
      this.openDrawerOnSection(shipment.id, index, 'courier');
    } else {
      this.ShipmentListAdvancedService.toggleCourierModal(index);
    }
  }

  onRefreshCellClick(): void {
    this.fetchShipments();
  }

  onInputChange(value: string): void {
    this.keyword = value === '' ? null : value;
  }

  onInputSearch(): void {
    this.$timeout(() => {
      this.ShipmentListAdvancedService.setKeyword(this.keyword);
      this.ShipmentListAdvancedService.resetApplyFilter();
      this.ShipmentListAdvancedService.fetchShipmentTotalWithFilter();
      this.fetchShipments();
    }, 300);
  }

  onInputActionClick(): void {
    this.isFilterOpen = !this.isFilterOpen;
    this.EndpointService.set('shipments', SCOPE_ORDERS_ALL);
  }

  onFilterClose(): void {
    this.isFilterOpen = false;
  }

  onApplyFilter(data?: IShipmentListFiltersData): void {
    this.resetListLimit();
    this.ShipmentListAdvancedService.setKeyword(this.keyword);
    this.ShipmentListAdvancedService.resetApplyFilter();
    if (data) {
      this.ShipmentListAdvancedService.updateFilter(data);
    } else {
      this.ShipmentListAdvancedService.clearFilter();
    }

    this.ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter();
  }

  resetSearch(): void {
    this.TagsService.resetSelections();

    if (!this.selectedView) {
      this.keyword = null;
      this.ShipmentListAdvancedService.resetAll(true);
      this.ShipmentListAdvancedService.fetchShipmentTotal();
      this.ShipmentListAdvancedService.fetchShipmentEntities(SCOPE_ORDERS_ALL);
      return;
    }

    this.ShipmentListAdvancedService.setKeyword(this.selectedView.keyword || '');
    this.ShipmentListAdvancedService.updateFilter(this.selectedView.filters || {});
    this.ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter();
  }

  onSelectView(viewId?: string): void {
    this.resetListLimit();
    if (viewId) {
      this.ShipmentListAdvancedService.updateViewAndShipments(viewId);
      this.$state.go('app.multiple', { view_id: viewId });
    } else {
      this.ShipmentListAdvancedService.resetAll();
      this.ShipmentListAdvancedService.setLimitFromUserSession();
      this.ShipmentListAdvancedService.fetchShipmentEntitiesAndTotalWithFilter();
      this.$state.go('app.multiple', { view_id: null });
    }
  }

  onSaveViewSuccess(view?: IShipmentListCustomViewsItem): void {
    this.ShipmentListAdvancedService.fetchAdvancedCustomViews();
    if (view) {
      this.ShipmentListAdvancedService.updateSelectedView(view);
    }
  }

  onUpdateViewSuccess(view: IShipmentListCustomViewsItem): void {
    this.ShipmentListAdvancedService.fetchAdvancedCustomViews();
    this.ShipmentListAdvancedService.updateSelectedView(view);
  }

  onDeleteView(viewId: string): void {
    if (viewId === this.selectedView?.id) {
      this.onSelectView();
    }
    this.ShipmentListAdvancedService.fetchAdvancedCustomViews();
  }

  onPageNameClick(): void {
    //
  }

  onApplySortBy(sortKey: SortByAttribute): void {
    this.resetListLimit();
    if (sortKey === this.sortBy) {
      const direction = this.isReverseSortOrder(sortKey) ? 'desc' : 'asc';
      if (this.sortDirection === direction) {
        this.ShipmentListAdvancedService.resetSortBy();
      } else {
        this.ShipmentListAdvancedService.applySortBy(sortKey, direction);
      }
    } else {
      this.ShipmentListAdvancedService.applySortBy(
        sortKey,
        this.isReverseSortOrder(sortKey) ? 'asc' : 'desc'
      );
    }
    this.ShipmentListAdvancedService.fetchShipmentEntitiesWithFilter(SCOPE_ORDERS_ALL);
  }

  loadMore(last: boolean, inview: boolean) {
    if (last && inview && this.listLimit < this.limit) {
      this.listLimit += DEFAULT_LIST_LIMIT;
    }
  }

  hideStoreAlert(id: string) {
    this.disconnectedStores = this.disconnectedStores.filter((store) => {
      return store.id !== id;
    });
    this.$scope.$apply();
  }

  onPrepareSingleShipment(shipmentId: string) {
    return this.CheckoutService.prepareAndSave(
      {
        company_id: this.UserSession.company.id,
      },
      {
        by_search: true,
        shipment_ids: [shipmentId],
      }
    )
      .then(this._handlePrepareCheckoutResponse)
      .catch(this._onPrepareCheckoutError);
  }

  onPrepareMultipleShipments() {
    return this.CheckoutService.prepareMultipleOrders()
      .then(this._handlePrepareCheckoutResponse)
      .catch(this._onPrepareCheckoutError);
  }

  private _handlePrepareCheckoutResponse(data: IApiPrepareCheckoutResponse) {
    this.preparedData = data;

    if (data.request_ups_dap_agreement.length > 0) {
      this.upsDapCountries = data.request_ups_dap_agreement;
      this.originAddressId = data.origins[0].sender_address_id;
      this.openDashboardHeaderModal('ups-dap-agreement');
      return;
    }

    if (data.is_using_usps_lightweight && !this.UserSession.user.hide_usps_lightweight_message) {
      this.openDashboardHeaderModal('ship-ulw-warning-modal');
      return;
    }

    this.proceedToCheckout();
  }

  proceedToCheckout() {
    if (this.preparedData === null) return;
    this.CheckoutService.initCheckout(this.preparedData, {});

    /**
     * Make sure the `addonsSkipped` is properly set regardless of the flow
     * TODO: Check if we can just use the CheckoutService.skipAddOns() directly
     */
    this.CheckoutService.addonsSkipped = this.CheckoutService.skipAddOns();

    if (
      this.CheckoutService.data?.includes_volumetric_shipment_with_missing_dimensions &&
      this.WalkthroughService.shouldFlowBeShown('order-sync-with-missing-dimensions')
    ) {
      this.OptionalDimensionsModalService.open({ modal: 'before-you-ship' });
    } else {
      this._redirectToFlow(this.CheckoutService.addonsSkipped);
    }
  }

  private _onPrepareCheckoutError(error: any) {
    if (!error) return;
    toastError(
      this.$translate.instant('toast.sorry-details', {
        details: !error.data ? '' : error.data.errors?.join('. ') ?? error.data.message,
      })
    );
  }

  _redirectToFlow(skipAddOns: boolean) {
    skipAddOns ? this._goToSummaryStep() : this._goToRequestPickupStep();
  }

  _goToRequestPickupStep() {
    // Go to checkout request pickup / add ons step
    return this.$state.go('app.request-pickup', {
      shipment_id: this.$state.params.shipment_id,
    });
  }

  _goToSummaryStep() {
    // Go to checkout order summary (payment) step
    return this.$state.go('app.order-summary', {
      shipment_id: this.$state.params.shipment_id,
    });
  }

  hideModal() {
    this.dashboardHeaderActiveModal = '';
  }

  openDashboardHeaderModal(dashboardHeaderModal: IDashboardHeaderModal) {
    // prevent throwing error ($digest already in progress)
    setTimeout(() => {
      this.$scope.$apply();
    }, 0);
    this.dashboardHeaderActiveModal = dashboardHeaderModal;
  }

  showNextUpsDapAgreeModal() {
    this.hideModal();
    this.upsDapCountries.shift();

    if (this.upsDapCountries.length > 0) {
      this.openDashboardHeaderModal('ups-dap-agreement');
    }
  }

  get planName() {
    const isPlanBadgeVisible = this.SubscriptionService.isPlanBadgeVisible(
      FEATURE_KEY.CustomizeShipmentTable
    );
    const badgeName = this.SubscriptionService.getPlanNameByFeatureKey(
      FEATURE_KEY.CustomizeShipmentTable
    );

    return isPlanBadgeVisible ? badgeName : null;
  }

  get isFeatureAccessible(): boolean {
    return this.SubscriptionService.isFeatureAccessible(FEATURE_KEY.CustomizeShipmentTable);
  }

  get totals(): IShipmentListAdvancedTotalData | null {
    return this.ShipmentListAdvancedService.totals;
  }

  get limit(): number {
    return this.ShipmentListAdvancedService.limit;
  }

  get pageNum(): number {
    return this.ShipmentListAdvancedService.pageNum;
  }

  get shipmentBusy(): boolean {
    return this.ShipmentListAdvancedService.shipmentBusy;
  }

  get totalBusy(): boolean {
    return this.ShipmentListAdvancedService.totalBusy;
  }

  get isEmptyShipment(): boolean {
    if (!this.shipments) {
      return true;
    }

    return this.shipments.length === 0;
  }

  get shipments(): IShipmentListItem[] | null {
    return this.ShipmentListAdvancedService.currentShipments;
  }

  get selectedOrderCount(): number {
    if (this.ShipmentListAdvancedService.isSelectedAll) {
      return this.totalOrderCount - this.excludedIds.length;
    }
    return this.ShipmentListAdvancedService.selectedCount;
  }

  get excludedIds(): string[] {
    return this.ShipmentListAdvancedService.excludedIds;
  }

  get totalOrderCount(): number {
    return this.totals ? this.totals.orders_count : 0;
  }

  get rowHeaderModel(): boolean {
    const { currentEntity } = this.ShipmentListAdvancedService;
    if (currentEntity && currentEntity.selected) {
      return currentEntity.selected;
    }

    return false;
  }

  get selectedAllShipments(): boolean {
    return this.ShipmentListAdvancedService.isSelectedAll;
  }

  get selectedShipment(): IShipmentListItem | null {
    return this.ShipmentListAdvancedService.selectedShipment;
  }

  get shipmentLoading(): IShipmentListLoading {
    return this.ShipmentListAdvancedService.loading;
  }

  get filterShipmentListData(): IShipmentListFiltersData | null {
    return this.ShipmentListAdvancedService.filter;
  }

  get shipmentListKeyword(): string | null {
    return this.ShipmentListAdvancedService.keyword;
  }

  get actionButtonType(): string | null {
    if (!this.filterShipmentListData) {
      return null;
    }

    const isFilterExist = (
      Object.keys(this.filterShipmentListData) as Array<keyof IShipmentListFiltersData>
    ).some((key) => this.filterShipmentListData && this.filterShipmentListData[key]);

    return isFilterExist ? 'teal' : null;
  }

  get allLoadingFalse(): boolean {
    return this.ShipmentListAdvancedService.allLoadingFalse;
  }

  get customViews(): IShipmentListCustomViewsItem[] | null {
    return this.ShipmentListAdvancedService.customViews;
  }

  get selectedView(): IShipmentListDefaultViewsItem | IShipmentListCustomViewsItem | null {
    return this.ShipmentListAdvancedService.selectedView;
  }

  get isUsingView(): boolean {
    return !!this.ShipmentListAdvancedService.selectedView;
  }

  get isCustomViewDifferent(): boolean {
    return this.ShipmentListAdvancedService.isCurrentViewFilterInitialStateChanged();
  }

  get isCompanyEfulfilment(): boolean {
    return this.UserSession.isCompanyEfulfilment();
  }

  get isTaxCollectEnabled(): boolean {
    return !!this.UserSession.company.dashboard_settings.show_tax_collect_filter;
  }

  get keywordShipmentList(): string | null {
    return this.ShipmentListAdvancedService.keyword;
  }

  get sortBy(): SortByAttribute | null {
    return this.ShipmentListAdvancedService.sortBy;
  }

  get sortDirection(): SortDirection | null {
    return this.ShipmentListAdvancedService.sortDirection;
  }

  get sortByOptions(): IShipmentSortByOptions[] {
    return this.ShipmentListAdvancedService.sortingOptions;
  }

  get isCurrentSubscriptionLoaded(): boolean {
    return this.SubscriptionService.isCurrentSubscriptionLoaded;
  }

  private async fetchShipmentsWithPrepareData(): Promise<void> {
    try {
      // Apply filter from url
      await this.ShipmentListAdvancedService.applyFilterFromUrl(this.$stateParams);

      // Fetch total and entities
      this.ShipmentListAdvancedService.fetchShipmentTotalWithFilter();
      this.fetchShipments();
    } catch (error) {
      toastError(this.$translate.instant('toast.default-error'));
    }
  }

  private fetchShipments(): void {
    this.ShipmentListAdvancedService.fetchShipmentEntitiesWithFilter(SCOPE_ORDERS_ALL);
  }

  private fetchCustomViewsWithShipments(): void {
    this.ShipmentListAdvancedService.setShipmentAndTotalBusy();
    this.viewsBusy = true;
    this.ShipmentListAdvancedService.fetchAdvancedCustomViews()
      .then(() => {
        const { customViews } = this.ShipmentListAdvancedService;
        this.ShipmentListAdvancedService.updateViewAndShipmentOnInitPage(
          null,
          customViews,
          SCOPE_ORDERS_ALL,
          (id) => {
            this.updateViewAndShipments(id);
          },
          () => {
            this.fetchShipmentsWithPrepareData();
          },
          () => {
            this.viewsBusy = false;
          },
          this.ShipmentListAdvancedService.selectedView?.id || this.$stateParams.view_id
        );
      })
      .catch(() => {
        this.viewsBusy = false;
      });
  }

  private updateViewAndShipments(viewId: string): void {
    this.ShipmentListAdvancedService.updateViewAndShipments(viewId, this.$stateParams);
    this.viewsBusy = false;
  }

  private isReverseSortOrder(sortKey: SortByAttribute): boolean {
    return this.ShipmentListAdvancedService.isReverseSortOrder(sortKey);
  }

  private resetListLimit(): void {
    this.listLimit = DEFAULT_LIST_LIMIT;
  }
}

const CreateShipmentsAdvancedComponent: ng.IComponentOptions = {
  controller: CreateShipmentsAdvanced,
  template,
  bindings: {},
};

export { CreateShipmentsAdvancedComponent };
