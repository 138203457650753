(function () {
  'use strict';

  CompanyService.$inject = [
    '$q',
    'Company',
    'UserStatusService',
    'UserSession',
    'AppBuild',
    'CompanyV2',
  ];
  /**
   * [CompanyService] Company endpoint related actions
   * updateStatus
   * getCompanyAnalytics
   * redeemVoucher
   * updateCompany
   * getCompanyUsers
   * createCompany
   */
  function CompanyService($q, Company, UserStatusService, UserSession, AppBuild, CompanyV2) {
    var service = this;

    // Updates everything that needs to be displayed to the user at all times
    service.updateStatus = function () {
      return $q(function (resolve, reject) {
        Company.getCurrentStatus(
          {
            company_id: UserSession.company.id,
          },
          function (response) {
            // Check force refresh here because updateStatus is called at every state change
            setForceRefresh(response.build_version);

            updateCompanyCredits(response.company);
            UserStatusService.updateShipmentCounters(response.company.shipments);
            service.isInactive = response.company.ask_inactivity_reason;

            // Some vouchers have a permanent effect on cost or subscription.
            // Visible in the promo-code component
            // Example format:
            // voucher: {
            //   code: 'VOUCHERCODE',
            //   percentage: 0.1,
            //   expires_at: Datetime,
            //   description: 'This is the description of the voucher',
            // }
            UserStatusService.voucher = response.voucher;

            resolve();
          },
          function () {
            reject();
          }
        );
      });
    };

    service.getCurrentStatus = function () {
      return Company.getCurrentStatus({
        company_id: UserSession.company.id,
      }).$promise;
    };

    service.getCompanyAnalytics = function (params) {
      return $q(function (resolve, reject) {
        Company.getAnalytics(
          {
            company_id: UserSession.company.id,
            from_date: params.from_date,
            to_date: params.to_date,
          },
          function (data) {
            resolve(data);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };

    service.getCompanyAnalyticsV2 = function (params) {
      return $q(function (resolve, reject) {
        CompanyV2.getAnalytics(
          {
            company_id: UserSession.company.id,
            from_date: params.from_date,
            to_date: params.to_date,
            scopes: params.scopes,
          },
          function (data) {
            resolve({ analytics: data });
          },
          function (error) {
            reject(error);
          }
        );
      });
    };

    service.getCompanyActivity = function () {
      return Company.getCompanyActivity(
        { company_id: UserSession.getCompanyId() },
        function (data) {
          return data;
        },
        function (err) {
          return err;
        }
      ).$promise;
    };

    service.redeemVoucher = function (voucherCode) {
      return $q(function (resolve, reject) {
        Company.redeemVoucher(
          {
            company_id: UserSession.company.id,
          },
          {
            code: voucherCode,
            country_id: UserSession.company.country_id,
          },
          function (response) {
            if (response.company.available_credit_balance !== UserStatusService.availableBalance) {
              resolve({
                unit: 'amount',
                figure:
                  response.company.available_credit_balance - UserStatusService.availableBalance,
              });
            } else {
              if (response.voucher && response.voucher.percentage) {
                resolve({
                  unit: 'percentage',
                  figure: parseInt(response.voucher.percentage * 100),
                });
              } else {
                resolve({
                  unit: '',
                  figure: 0,
                });
              }
            }

            updateCompanyCredits(response.company);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };

    service.refundCredits = function () {
      return $q(function (resolve, reject) {
        Company.refundCredits(
          {
            company_id: UserSession.company.id,
          },
          {},
          function (response) {
            service.updateStatus();
            resolve(response);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };

    service.updateCompany = function (company, recalculateShipments) {
      return $q(function (resolve, reject) {
        Company.update(
          {
            company_id: UserSession.company.id,
          },
          {
            company: company,
            recalculate_shipments: recalculateShipments,
          },
          function (response) {
            var updatedCompany = Object.assign(UserSession.company, response.company);
            updateUserSession(updatedCompany);
            resolve(response);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };

    service.getCompanyUsers = function () {
      return $q(function (resolve, reject) {
        Company.getCompanyUsers(
          {
            company_id: UserSession.company.id,
          },
          {},
          function (data) {
            resolve(data);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };

    service.checkIfUniqueName = function (companyName) {
      return $q(function (resolve, reject) {
        Company.checkIfUniqueName(
          {
            name: companyName,
          },
          {},
          function (response) {
            resolve(response);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };

    service.getPackingSlipBrandingData = function () {
      return Company.getPackingSlipBrandingData(
        {
          company_id: UserSession.company.id,
        },
        {}
      ).$promise;
    };

    service.getTrackingEmailBrandingData = function () {
      return Company.getTrackingEmailBrandingData(
        {
          company_id: UserSession.company.id,
        },
        {}
      ).$promise;
    };

    service.getTrackingPageBrandingData = function () {
      return Company.getTrackingPageBrandingData(
        {
          company_id: UserSession.company.id,
        },
        {}
      ).$promise;
    };

    service.updateDashboardSettings = function (payload) {
      return Company.updateDashboardSettings(
        {
          company_id: UserSession.company.id,
        },
        {
          dashboard_settings: payload,
        }
      ).$promise;
    };

    service.registerCourierSubAccounts = function (umbrellaName, addressId) {
      return Company.registerCourierSubAccounts(
        {
          company_id: UserSession.company.id,
        },
        {
          umbrella_name: umbrellaName,
          origin_address_id: addressId,
        }
      ).$promise;
    };

    service.closeAccount = function (reason, index, feedback, password) {
      return Company.closeAccount(
        {
          company_id: UserSession.company.id,
        },
        {
          password: password,
          reasons: {
            reason: reason,
            index: index,
            feedback: feedback,
          },
        }
      ).$promise;
    };

    service.submitChurnReasons = function (type, feedbackable_id, reason, index, feedback) {
      return Company.submitChurnReasons(
        {
          company_id: UserSession.company.id,
        },
        {
          feedbackable_id: feedbackable_id,
          feedbackable_type: type,
          reasons: {
            reason: reason,
            index: index,
            feedback: feedback,
          },
        }
      ).$promise;
    };

    service.updateCommercialInvoiceRemarks = function (remarks) {
      return $q(function (resolve, reject) {
        Company.update(
          {
            company_id: UserSession.company.id,
          },
          {
            commercial_invoice_remark: remarks,
          },
          function (response) {
            var updatedCompany = Object.assign(UserSession.company, response.company);
            updateUserSession(updatedCompany);
            resolve(response);
          },
          function (error) {
            reject(error);
          }
        );
      });
    };

    function setForceRefresh(buildVersion) {
      if (buildVersion > AppBuild.buildVersion) UserStatusService.showForceReload = true;
      else return;
    }

    function updateCompanyCredits(company) {
      if (company.credit_balance !== UserStatusService.actualBalance)
        UserStatusService.updateActualBalance(company.credit_balance);
      if (company.available_credit_balance !== UserStatusService.availableBalance)
        UserStatusService.updateAvailableBalance(company.available_credit_balance);
      return;
    }

    function updateUserSession(company) {
      return UserSession.update('company', company);
    }
  }

  angular
    .module('app.service.CompanyService', ['app.factory.Company', 'app.global.user-status'])
    .service('CompanyService', CompanyService);
})();
