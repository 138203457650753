import Chance from 'chance';
import { SenderAddressData, SenderAddressEditData } from './SenderAddressData';
import { AddressEditMock, AddressMock } from '../Address';

const chance = new Chance();

export const senderAddressMock = (): SenderAddressData => ({
  ...AddressMock(),
  id: chance.guid(),
  senderName: chance.name(),
  nickname: chance.string(),
  useForLater: chance.bool(),
  hkDistrictId: chance.integer({ min: 1 }),
});

export const senderAddressEditMock = (): SenderAddressEditData => ({
  ...AddressEditMock(),
  senderName: chance.name(),
  nickname: chance.string(),
  useForLater: chance.bool(),
  hkDistrictId: chance.integer({ min: 1 }),
  useExistingAddress: false,
});
