import angular from 'angular';

import { FormCanadaPostComponent } from './canada-post.component';

const MODULE_NAME = 'app.couriers.courier-create.connect-courier.form.canada-post';

angular
  .module(MODULE_NAME, [])
  .component('esConnectCourierFormCanadaPost', FormCanadaPostComponent);

export default MODULE_NAME;
