/**
 * @param {*} UserSession
 */
function zendeskChatInit(onZendeskLoaded, hasNewZendeskChatFeatureEnabled) {
  if (import.meta.env.VITE_APP_ENVIRONMENT !== 'production') return;

  const elementExists = document.getElementById('ze-snippet');

  if (elementExists) {
    return;
  }

  const key = hasNewZendeskChatFeatureEnabled
    ? import.meta.env.VITE_KEY_ZENDESK_CHAT_INDIA
    : import.meta.env.VITE_KEY_ZENDESK_CHAT;
  const url = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
  const scriptElement = document.createElement('script');
  scriptElement.type = 'text/javascript';
  scriptElement.async = true;
  scriptElement.src = url;
  scriptElement.id = 'ze-snippet';
  if (typeof onZendeskLoaded === 'function') {
    scriptElement.onload = onZendeskLoaded;
  }

  const firstScriptElement = document.getElementsByTagName('script')[0];
  firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
}
export { zendeskChatInit };
