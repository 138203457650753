import { Checkbox } from 'easyship-components';
import React from 'react';
import RoundedCorner from './RoundedCorner';
import ShipmentsTableHeaderCell from './ShipmentsTableHeaderCell';

export interface ShipmentsTableHeaderProps {
  children: React.ReactNode;
  isCheckboxChecked: boolean;
  onCheckboxChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function ShipmentsTableHeader({
  children,
  isCheckboxChecked,
  onCheckboxChanged,
}: ShipmentsTableHeaderProps) {
  return (
    <div
      data-test-id="shipments-table-header"
      className="flex w-fit min-w-full rounded bg-white items-center min-h-[40px] pr-[10px] mb-2"
    >
      <ShipmentsTableHeaderCell className="flex-grow-0 w-auto min-w-0 hover:bg-[#d2f1f2] cursor-pointer sticky left-[0] pl-[10px] bg-white z-[5] group">
        <RoundedCorner classNameInner="group-hover:bg-[#d2f1f2]" />
        <RoundedCorner position="bottom-left" classNameInner="group-hover:bg-[#d2f1f2]" />
        <Checkbox
          data-test-id="shipments-table-header-checkbox"
          className="!outline-none cursor-pointer !m-0 hover:bg-transparent checked:before:bg-[#36a3ac] checked:before:border-none after:bg-white h-[40px] before:top-[.75rem] after:top-[.75rem]"
          checked={isCheckboxChecked}
          onChange={onCheckboxChanged}
        />
      </ShipmentsTableHeaderCell>
      {children}
    </div>
  );
}
