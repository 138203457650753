import { PostalCodeMatches, StateMatches } from '@client/core/corelogic/models/PostalCodeMatches';
import { IPostalCodeMatch } from 'typings/postal-code';

export default class PostalCodeMatchesMapper {
  fromAPI(data: IPostalCodeMatch): PostalCodeMatches {
    let cities: string[] = [];
    const states =
      data.states?.reduce<StateMatches[]>((agg, state) => {
        if (state.name) {
          agg.push({
            name: state.name,
            cities: state.suburbs?.map(this.labelify) || [],
          });
        }

        return agg;
      }, []) || [];

    const stateFound = states.find((state) => state.name === data.state?.name);

    if (!stateFound && !!data.state?.name) {
      const index = states.push({ name: data.state.name, cities: [] });

      if (data.city) {
        states[index - 1]!.cities.push(this.labelify(data.city));
      }
    }

    if (data.cities) {
      cities = data.cities;
    }

    return { states, cities };
  }

  private labelify(str: string): string {
    return str.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
  }
}
