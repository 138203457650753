/* eslint-disable no-param-reassign */
import iconReturn from '@assets/images/dashboard/create-shipments/icon-return.svg?svgo';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { showToastWithAction } from '@client/core/components/react/ToastWithAction';

(function () {
  angular.module('easyshipDashboardApp').controller('OrderSummaryCtrl', OrderSummaryCtrl);

  OrderSummaryCtrl.$inject = [
    '$scope',
    '$sce',
    '$state',
    '$stateParams',
    '$timeout',
    '$filter',
    '$window',
    '$cookies',
    '$translate',
    'API',
    'UserSession',
    'UserStatusService',
    'EndpointService',
    'AddressService',
    'CheckoutService',
    'HelperService',
    'MixpanelService',
    'PaymentService',
    'SubscriptionService',
    'PaymentSourceService',
    '$rootScope',
    'OnboardingChecklistService',
    'ContactSalesModal',
    'AppCuesService',
    'ShipmentListAdvancedService',
    'UserRightsService',
    'User',
  ];
  function OrderSummaryCtrl(
    $scope,
    $sce,
    $state,
    $stateParams,
    $timeout,
    $filter,
    $window,
    $cookies,
    $translate,
    API,
    UserSession,
    UserStatusService,
    EndpointService,
    AddressService,
    CheckoutService,
    HelperService,
    MixpanelService,
    PaymentService,
    SubscriptionService,
    PaymentSourceService,
    $rootScope,
    OnboardingChecklistService,
    ContactSalesModal,
    AppCuesService,
    ShipmentListAdvancedService,
    UserRightsService,
    User
  ) {
    const originState = $state.current.parent;

    // GET params for this page
    $scope.EndpointService = EndpointService;
    $scope.EndpointService.set('multiple');

    $scope.UserSession = UserSession;
    $scope.UserStatusService = UserStatusService;
    $scope.CheckoutService = CheckoutService;
    $scope.HelperService = HelperService;
    $scope.API = API;
    $scope.UserRightsService = UserRightsService;
    $scope.canPurchaseLabel = UserRightsService.canPurchaseLabel;
    $scope.iconReturn = $sce.trustAsHtml(iconReturn);

    // Get the pickup params from previous state (app.request-pickup)
    $scope.busy = {
      page: true,
      creditCards: false,
    };
    $scope.error = {};

    $scope.SubscriptionService = SubscriptionService;
    $scope.ContactSalesModal = ContactSalesModal;
    $scope.showModal = {
      upgrade: false,
    };
    $scope.showAddPaymentModal = {
      addCard: false,
    };
    $scope.isShowShipmentDoneModal = false;
    $scope.isShowShipmentPaymentForm = true;
    $scope.isShowCreditCardForm = false;
    $scope.defaultCard = null;
    $scope.selectedCard = null;
    $scope.creditCardErrorCode = '';
    $scope.paymentFormId = 'top-up-payment-form';
    $scope.wrappers = {
      a(chunk) {
        return `<a href="{{API.help}}/hc/en-us/articles/360039684252-Shipping-Charge-Adjustment-Weight-Size" target="_blank" rel="noopener">${chunk}</a>`;
      },
    };

    // Redirects back to Multiple Shipments if no params
    if (!Object.keys(CheckoutService.data).length > 0) {
      if (originState === 'app.single') {
        $state.go('add-shipment');
      } else {
        $state.go('app.multiple', { page: 1 });
      }

      return;
    }

    if (originState === 'app.single') {
      $scope.isSingle = true;
    }

    (function init() {
      $scope.busy.creditCards = true;
      $scope.isUsingUspsLightweight = CheckoutService.data.is_using_usps_lightweight;
      $scope.displayMissingDimensionsWarning =
        CheckoutService.data.includes_volumetric_shipment_with_missing_dimensions;

      // Show Pay at Easyship card if there are courier to pay or even just insurance
      $scope.hasShipmentToPayAtEasyship = _hasShipmentToPayAtEasyship();
      $scope.hasShipmentToPayAtCourier = _hasShipmentToPayAtCourier();

      $scope.payOnScanReturnCouriers = _getPayOnScanReturnCouriers();
      _updateInsufficientFunds();
      $scope.busy.page = false;

      $rootScope.$on('payment-failed', function () {
        $scope.busyPaying = false;
      });
      SubscriptionService.destroyCurrentSubscription();
      SubscriptionService.fetchCurrentSubscription({
        company_id: UserSession.getCompanyId(),
      });

      SubscriptionService.fetchPlansDetail({
        country_id: UserSession.getCompanyCountryId(),
      });

      PaymentSourceService.getCreditCards()
        .then(function () {
          $scope.busy.creditCards = false;
          const defaultCard = PaymentSourceService.getDefaultCard();
          if (defaultCard && defaultCard.brand && defaultCard.last4 && !defaultCard.expired) {
            $scope.isShowCreditCardForm = false;
            $scope.defaultCard = PaymentSourceService.getDefaultCard();
          } else {
            $scope.isShowCreditCardForm = true;
          }
        })
        .catch(function () {
          $scope.isShowCreditCardForm = true;
          $scope.busy.creditCards = false;
          toastError($translate.instant('toast.default-error'));
        });
    })();

    function _isBalanceChargeable(totalToTopUp) {
      return totalToTopUp >= UserSession.getMinimumChargeAmount();
    }

    function _doesNotHaveOverdraft() {
      return !UserSession.company.is_authorized_overdraft;
    }

    function _needsTopUp() {
      const displayableTotalToTopUp = HelperService.doesKeyValueExists(
        CheckoutService.data,
        'displayableTotalToTopUp'
      );

      return displayableTotalToTopUp && _isBalanceChargeable(displayableTotalToTopUp);
    }

    function _getTotalsToPay() {
      return HelperService.doesKeyValueExists(CheckoutService.data, 'totals')
        ? CheckoutService.data.totals
        : null;
    }

    function _hasShipmentToPayAtEasyship() {
      const totalsToPay = _getTotalsToPay();
      if (!totalsToPay) return false;

      return !!totalsToPay.shipments_count_pay_at_easyship || CheckoutService.needsToPayInsurance();
    }

    function _hasShipmentToPayAtCourier() {
      const totalsToPay = _getTotalsToPay();
      if (!totalsToPay) return false;

      return !!totalsToPay.shipments_count_pay_at_courier;
    }

    function _updateInsufficientFunds() {
      $scope.insufficientFunds = _needsTopUp() && _doesNotHaveOverdraft();
    }

    function _getPayOnScanReturnCouriers() {
      return CheckoutService.data.return_couriers.filter(function (courier) {
        return courier.payment_recipient === 'EasyshipPayOnScan';
      });
    }

    $scope.isTotalShipmentOverLimit = function () {
      const shipmentsPerMonth =
        SubscriptionService.currentSubscription.all_features.shipments_per_month;

      if (shipmentsPerMonth.limit === -1) {
        return false;
      }

      return (
        shipmentsPerMonth.current_count + $scope.totalShipmentsCountPay() > shipmentsPerMonth.limit
      );
    };

    $scope.handleOnVerify = function () {
      User.getCurrent().$promise.then((response) => {
        UserSession.create(response);
      });
      $scope.$evalAsync();
    };

    /**
     * [prev] Change state to go back to previous action (your-info for single flow and request-pickup for multiple)
     * @return {[type]} [description]
     */
    $scope.prev = function () {
      if ($state.includes('app.single')) {
        let route;
        let params;

        if (CheckoutService.addonsSkipped) {
          route = 'choose-courier';
          params = { shipment: $stateParams.fullShipment };
        } else {
          route = 'your-info';
          params = { fullShipment: $stateParams.fullShipment };
        }

        $state.go(route, params);
        return;
      }

      if (CheckoutService.addonsSkipped) {
        $state.go('app.multiple', {
          page: ShipmentListAdvancedService.pageNum,
          from_request_pickup: true,
          shipment_id: $state.params.shipment_id,
        });
        return;
      }

      $state.go('app.request-pickup', {
        shipment_id: $state.params.shipment_id,
      });
    };

    $scope.totalShipmentsCountPay = function () {
      if (CheckoutService.data && CheckoutService.data.totals) {
        return (
          CheckoutService.data.totals.shipments_count_pay_at_courier +
          CheckoutService.data.totals.shipments_count_pay_at_easyship
        );
      }

      return 0;
    };

    $scope.suggestedPlanId = function () {
      return SubscriptionService.suggestedPlanForMoreShipments($scope.totalShipmentsCountPay());
    };

    $scope.closeChangePlanIntegrationModal = function () {
      $scope.isShowShipmentPaymentForm = true;
    };

    $scope.closeShipmentDoneModal = function () {
      $scope.isShowShipmentDoneModal = false;

      MixpanelService.track('Subscription - Premier Max - Close');
    };

    $scope.showUpgradeModal = function () {
      $scope.showModal.upgrade = true;
      $scope.isShowShipmentPaymentForm = false;
    };

    $scope.showCreditCardForm = function () {
      $scope.isShowCreditCardForm = true;
    };

    $scope.showEnterpriseCallModal = function () {
      $scope.isShowShipmentDoneModal = false;
      $scope.showModal.enterpriseCall = true;
      $scope.isShowShipmentPaymentForm = false;

      MixpanelService.track('Subscription - Premier Max - Schedule');
    };

    $scope.showAddCreditCardModal = function () {
      $scope.isShowShipmentPaymentForm = false;
      $scope.showAddPaymentModal.addCard = true;
    };

    $scope.isDisplayPaymentForm = function () {
      if (!$scope.hasShipmentToPayAtEasyship) {
        return false;
      }
      if (!$scope.insufficientFunds) {
        return false;
      }
      return $scope.isShowShipmentPaymentForm;
    };

    // (main action button) Button pay
    $scope.next = function () {
      if (!hasCreditCardSetup() || !isSubscriptionPaymentValid()) {
        return;
      }

      if (SubscriptionService.isMaxShipments || $scope.isTotalShipmentOverLimit()) {
        handleOverShipmentLimit();
        return;
      }

      processPayment();
    };

    $scope.onCardSelected = function (card) {
      $scope.selectedCard = card;
    };

    function hasCreditCardSetup() {
      $scope.creditCardErrorCode = null;

      const isPayingEasyship =
        CheckoutService.data &&
        CheckoutService.data.totals &&
        CheckoutService.data.totals.total_pay_at_easyship > 0;
      const isMissingRequiredPaymentSource =
        UserSession.company.require_payment_source && !PaymentSourceService.haveValidPaymentSource;

      if (isPayingEasyship && isMissingRequiredPaymentSource && !$scope.isDisplayPaymentForm) {
        $scope.showAddCreditCardModal();
        return false;
      }

      return true;
    }

    function isSubscriptionPaymentValid() {
      const isSubscriptionFailed =
        SubscriptionService.currentSubscription.subscription_state ===
        SubscriptionService.subscriptionStates.Failed;
      if (isSubscriptionFailed) {
        SubscriptionService.showTrialEndOrPaymentFailedModal();
        return false;
      }
      return true;
    }

    function showShipmentDoneModal() {
      $scope.isShowShipmentDoneModal = true;
      SubscriptionService.saveShipmentDoneModalSeen();
      MixpanelService.track('Subscription - Premier Max - Open');
    }

    function handleOverShipmentLimit() {
      if (SubscriptionService.isAuthorizeShipmentOverdraft) {
        if (SubscriptionService.isShipmentDoneModalSeen) {
          processPayment();
        } else {
          showShipmentDoneModal();
        }
      } else if (
        SubscriptionService.isEnterprisePlan ||
        $scope.suggestedPlanId() === SubscriptionService.planIds.Enterprise
      ) {
        $scope.showEnterpriseCallModal();
      } else {
        $scope.showUpgradeModal();
      }
    }

    function processPayment() {
      $scope.creditCardErrorCode = '';
      if ($scope.busyPaying) return;

      $scope.busyPaying = true;
      _updateInsufficientFunds();

      if ($scope.insufficientFunds) {
        PaymentSourceService.paymentAmount =
          CheckoutService.data.displayableTotalToTopUp ||
          CheckoutService.data.totals.total_to_top_up;
        PaymentSourceService.shipmentIds = CheckoutService.data.origins.reduce(function (
          ids,
          origin
        ) {
          return ids.concat(
            origin.couriers.reduce(function (originIds, courierService) {
              return originIds.concat(courierService.shipment_ids);
            }, [])
          );
        },
        []);

        PaymentService.submitPaymentForm($scope.paymentFormId)
          .then(function (results) {
            const valid = results && results.valid;

            // for old cards
            const paymentSourceId = results && results.payment && results.payment.token;

            if (!valid) {
              $scope.busyPaying = false;
              return;
            }

            confirmLabels({ paymentSourceId });
          })
          .catch(function (error) {
            if (UserSession.getCompanyAvailableCreditBalance() < 0) {
              showToastWithAction(
                $translate.instant('account.payment.notifications.negative-balence'),
                {
                  actionLabel: $translate.instant('account.payment.balance.add-button'),
                  onActionLabelClick() {
                    $state.go('app.account.payment');
                  },
                  actionIcon: 'close',
                }
              );
            } else {
              $scope.creditCardErrorCode = error.data ? error.data.error_code : 'unknown';
              toastError($translate.instant('account.payment.notifications.stripe-error'));
            }

            $scope.busyPaying = false;
          })
          .finally(function () {
            PaymentSourceService.shipmentIds = null;
          });
      } else {
        // has enough money
        confirmLabels();
      }
    }

    /**
     * Calls the process#confirm endpoint
     * On success redirects to the shipments page
     * @param  {object} paymentOptions Contains the paymentSourceId and other meta data about the payment
     *
     * call pay Orders
     * redirect
     */
    function confirmLabels(paymentOptions) {
      const options = paymentOptions || {};
      const token = options.paymentSourceId;
      const shipmentIds = [];
      const eventData = {};
      $scope.stripeErrorMessage = ''; // NOT USED IN TEMPLATE

      CheckoutService.payload.origins.forEach(function (origin, idx) {
        origin.pickups.forEach(function (pickup) {
          shipmentIds.push.apply(shipmentIds, pickup.shipment_ids);
        });

        if (!AddressService.isPickupInstructionBetaFeatureEnabled()) return;

        eventData[`origin_${idx}_pickup_instruction`] =
          AddressService.getMixpanelPickupInstructionValue(
            origin.pickup_instruction_slug,
            origin.pickup_instruction_users_input
          );
      });

      $window.localStorage.setItem('lastShipments', JSON.stringify(shipmentIds));

      CheckoutService.payOrders(CheckoutService.payload, token)
        .then(function (data) {
          $scope.orderSuccess = true;

          toastSuccess(
            $translate.instant(
              'checkout.summary.purchase-success',
              {
                count: data.shipments_count,
              },
              'messageformat'
            )
          );

          const timeOutAmount = UserSession.isLuxUser() ? 3000 : 1000;

          $timeout(function () {
            let viewId = null;

            if (
              !UserSession.getCompanyDashboardSettings().beta_feature_returns_page &&
              UserSession.hasReturnFeatureSupport()
            ) {
              viewId = 'default_shipment';
            }

            const abTestVariation =
              ($cookies.getObject('AB_TESTS') && $cookies.getObject('AB_TESTS').direct_to_quote) ||
              null;
            if (abTestVariation) {
              eventData.ab_test_key = 'direct_to_quote';
              eventData.ab_test_variation_key = abTestVariation;
            }
            MixpanelService.track('Purchase Label Success', eventData);

            AppCuesService.track('Purchased Shipping Label');

            CheckoutService.clearCheckoutDataAndPayload();

            OnboardingChecklistService.updateOnboarding('generate_label');
            OnboardingChecklistService.updateOnboarding('create_shipment');

            const isSingleShipment = shipmentIds.length === 1;
            if (isSingleShipment) {
              $state.go('app.shipments', {
                purchasedLabelSuccess: true,
                view_id: viewId,
                direct_print_id: shipmentIds[0],
                sectionName: 'shipments-all',
              });
            } else {
              $state.go('app.shipments', {
                purchasedLabelSuccess: true,
                view_id: viewId,
              });
            }

            $scope.busyPaying = false;
          }, timeOutAmount);
        })
        .catch(function (err) {
          $scope.busyPaying = false;
          if (err.data.error_codes.includes('subscription_over_limit')) {
            handleOverShipmentLimit();
          } else {
            MixpanelService.track('Purchase Label Failure', Object.assign(eventData, err.data));
          }
          _payOrdersErrorCallback(err);
        });
    }

    // BE will now send a status to identify the type
    // normal error: { status:  'failure', errors: [] }
    // payment error: { status:  'payment_failure', errors: [] }
    function _payOrdersErrorCallback(err) {
      if (!err) return;

      const errors = (err.data && err.data.errors) || [];
      if (errors[0]) {
        toastError(errors[0]);
      } else {
        toastError($translate.instant('toast.default-error'));
      }
    }

    function getTotalNumberOfShipments(origins) {
      let total = 0;

      origins.forEach(function (origin) {
        origin.couriers.forEach(function (courier) {
          if (courier.shipment_ids) total += courier.shipment_ids.length;
        });
      });

      return total;
    }

    function getPaymentMethodForAnalytics(totals) {
      if (!totals) return;

      if (CheckoutService.data.displayableTotalToTopUp && totals.total_to_pay_from_credit) {
        return 'hybrid';
      }
      if (CheckoutService.data.displayableTotalToTopUp) {
        return 'credit_card';
      }
      return 'easyship_bucks';
    }

    $scope.getDisplayAmountToCharge = function () {
      return $filter('intlCurrency')(
        CheckoutService.data.displayableTotalToTopUp,
        UserSession.getCompanyCurrency()
      );
    };
  }
})();
