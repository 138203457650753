import React from 'react';
import { IntlConfig, IntlProvider } from 'react-intl';
import dayjs from '@client/core/plugins/dayjs';

import enMessages from '@assets/i18n/en.json';
import frMessages from '@assets/i18n/fr.json';
import deMessages from '@assets/i18n/de.json';
import esMessages from '@assets/i18n/es.json';
import { EasyshipLocale } from '@client/core/corelogic/models/EasyshipLocale';

/* eslint-disable @typescript-eslint/no-explicit-any, no-plusplus, no-restricted-syntax, guard-for-in */
// @see: adapted from Bergi's code at https://stackoverflow.com/a/19101235
const flatten = (data: Record<string, any>): Record<string, string> => {
  const result = {} as Record<string, any>;
  function recurse(cur: Record<string, any>, prop: string) {
    let i;
    let l;
    let p;
    let isEmpty;
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (i = 0, l = cur.length; i < l; i++) recurse(cur[i], `${prop}[${i}]`);
      if (l === 0) result[prop] = [];
    } else {
      isEmpty = true;
      for (p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? `${prop}.${p}` : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, '');
  return result;
};

const enMessagesFlat = flatten(enMessages);

export const messageDictionary: Record<EasyshipLocale, Record<string, string>> = {
  en: enMessagesFlat,
  fr: { ...enMessagesFlat, ...flatten(frMessages) },
  de: { ...enMessagesFlat, ...flatten(deMessages) },
  es: { ...enMessagesFlat, ...flatten(esMessages) },
};

export const useLocale = (selectedLocale?: EasyshipLocale): EasyshipLocale => {
  const localeCodeInUse = selectedLocale ?? 'en';
  const applyToDayJs = () => {
    dayjs.locale(localeCodeInUse);
    return localeCodeInUse;
  };

  switch (localeCodeInUse) {
    case 'de':
      import('dayjs/locale/de').then(applyToDayJs);
      break;

    case 'fr':
      import('dayjs/locale/fr').then(applyToDayJs);
      break;

    case 'es':
      import('dayjs/locale/es').then(applyToDayJs);
      break;

    default:
      import('dayjs/locale/en').then(applyToDayJs);
  }

  return localeCodeInUse;
};

const defaultRichTextElements: IntlConfig['defaultRichTextElements'] = {
  b: (parts) => <strong>{parts}</strong>,
  // @ts-expect-error: This works, but 'react-intl' typing is wrong and expect a function
  br: <br />,
};

interface IntlProviderProps {
  selectedLocale?: EasyshipLocale;
  children: React.ReactNode;
}

export default function I18nProvider({ selectedLocale, children }: IntlProviderProps) {
  const locale = useLocale(selectedLocale);

  return (
    <IntlProvider
      locale={locale}
      messages={messageDictionary[locale]}
      defaultLocale="en"
      defaultRichTextElements={defaultRichTextElements}
    >
      {children}
    </IntlProvider>
  );
}
