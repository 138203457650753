import template from './billing.html?raw';
import style from './billing.module.scss';

class Billing {
  static $inject = [
    'UserSession',
    'UserStatusService',
    'ContactSalesModal',
    'BillingService',
    '$filter',
    'SubscriptionService',
    '$stateParams',
    '$translate',
  ];

  constructor(
    UserSession,
    UserStatusService,
    ContactSalesModal,
    BillingService,
    $filter,
    SubscriptionService,
    $stateParams,
    $translate
  ) {
    this.style = style;
    this.UserSession = UserSession;
    this.UserStatusService = UserStatusService;
    this.ContactSalesModal = ContactSalesModal;
    this.BillingService = BillingService;
    this.SubscriptionService = SubscriptionService;
    this.$filter = $filter;
    this.$stateParams = $stateParams;
    this.$translate = $translate;
    this.loading = true;
    this.error = null;
    this.wrappers = {
      a: (chunk) =>
        `<a href="https://support.easyship.com/hc/en-us/articles/360039684252" target="_blank" rel="noreferrer noopener">${chunk}</a>`,
    };
  }

  $onInit() {
    this.options = [
      'account.billing.tabs.transactions',
      'account.billing.tabs.invoices',
      'account.billing.tabs.credit-notes',
      'account.billing.tabs.receipts',
    ].map((key) => {
      return this.$translate.instant(key);
    });
    this.selected = this.options[0];

    const pendingState = 'pending_next_settlement';

    if (this.$stateParams && this.$stateParams.settlement_state === pendingState) {
      this.BillingService.filter.settlement_state = pendingState;
      this.BillingService.filter.transactionable_types = 'adjustment';
    }

    this.balance = this.UserStatusService.availableBalance;
    this.currency = this.UserSession.company.currency;
    this.fetchData();

    const subscriptionLabel = this.$translate.instant('account.billing.tabs.subscription');
    if (
      this.SubscriptionService.currentSubscription &&
      this.SubscriptionService.isSubscriptionAvailable
    ) {
      this.options.push(subscriptionLabel);
    } else {
      this.SubscriptionService.fetchCurrentSubscription({
        company_id: this.UserSession.getCompanyId(),
      }).then(() => {
        if (this.SubscriptionService.isSubscriptionAvailable) {
          this.options.push(subscriptionLabel);
        }
      });
    }
  }

  get isFirstTimeWaiver() {
    const { company } = this.UserSession;
    const isPending =
      company.dashboard_settings.beta_feature_fr_adjustment_waiver?.status === 'pending';
    const hasAmount = !!company.dashboard_settings.beta_feature_fr_adjustment_waiver?.amount;

    return isPending && hasAmount;
  }

  hasAch() {
    const settings = this.UserSession.getCompanyPaymentSettings();
    return settings && settings.ach;
  }

  fetchData() {
    this.loading = true;
    this.BillingService.fetchListing(this.selected)
      .then((response) => {
        this.nextBillingDate = response.nextBillingDate;
        this.listing = response.listing;
        this.itemCount = response.itemCount;
      })
      .catch((err) => {
        this.error = 'Sorry, something went wrong. Please try refreshing the page and try again.';
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleSearch() {
    this.BillingService.toggleAdvancedSearch();
    this.fetchData();
  }

  handleReset() {
    this.BillingService.toggleAdvancedSearch();
    this.BillingService.resetFilter();
    this.fetchData();
  }

  handleKeywordSearch() {
    this.fetchData();
  }

  handleFilterClose() {
    this.BillingService.toggleAdvancedSearch();
  }

  onChangeSection(section, keyword) {
    this.error = null;
    this.selected = section;
    this.BillingService.resetState();
    if (keyword) {
      this.BillingService.filter.keyword = keyword;
    }
    this.fetchData();
  }

  onChangePage(page) {
    this.BillingService.setPagination(page);
    this.fetchData();
  }

  onSelectItemPerPage() {
    this.BillingService.resetState();
    this.fetchData();
  }

  onReferenceClick(value) {
    const [Transactions] = this.options;
    this.onChangeSection(Transactions, value);
  }
}

const BillingComponent = {
  template,
  controller: Billing,
};

export { BillingComponent };
