import { IAddress, IAddressService } from 'typings/address';
import { ICountry, ICountryCurrencyList, ICountryService } from 'typings/auth/services/country';
import { IApiConfig } from 'typings/core/config/api';
import { IPostalCodeService } from 'typings/postal-code';
import { IUserSession } from 'typings/user-session';
import { IChangesObject, IComponentController } from 'angular';
import moment from 'moment';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './fedex.html?raw';
import style from './fedex.module.scss';

type FedexFormType = 'fedex' | 'fedex-cross-border';
type FedexStep = 'eula' | 'form' | 'invoice' | 'pin-choice' | 'pin-sms' | 'pin-email';

type FedexStepEnumType = {
  [key: string]: FedexStep;
};

const FedexStepEnum: FedexStepEnumType = {
  Eula: 'eula',
  Form: 'form',
  Invoice: 'invoice',
  PinChoice: 'pin-choice',
  PinSMS: 'pin-sms',
  PinEmail: 'pin-email',
};

interface ICourierDetails {
  [key: string]: any;
}

interface IResendPinPayload {
  id: string;
  account: {
    validation_option: 'sms' | 'email';
  };
}

const AU_ID = 14;
const CA_ID = 39;
const US_ID = 234;
const RESENT_LOCK_IN_SECONDS = 60;

class FormFedex implements IComponentController {
  isNewFedexLyocFeatureEnabled = false;
  esUmbrellaName: FedexFormType = 'fedex';
  esCourierDetails: ICourierDetails = {};
  esCurrentStep: FedexStep = FedexStepEnum.Eula;
  esIsEditMode = false;
  esOnChange!: (changes: { courierDetails: ICourierDetails }) => void;
  esOnResendPin!: ({ payload }: { payload: IResendPinPayload }) => Promise<void>;
  esLastPinRequestedAt: Date | null = null;
  esCourierId = '';
  esError = '';
  today: Date = new Date();
  oldestInvoiceDateAllowed: Date = new Date(new Date().setDate(this.today.getDate() - 180));
  fixedPriceAmountInputSettings = {
    maxDecimals: 2,
    allowNegative: false,
  };
  remainingPinLockSeconds = 0;
  style = style;
  fedexStep = FedexStepEnum.Eula;
  countries: ICountry[] = [];
  currencies: ICountryCurrencyList[] = [];
  stateRequired = false;
  translations: { [key: string]: string } = {};

  static $inject = [
    '$timeout',
    '$translate',
    'API',
    'UserSession',
    'CountryService',
    'AddressService',
    'PostalCodeService',
  ];
  constructor(
    private $timeout: ng.ITimeoutService,
    private $translate: angular.translate.ITranslateService,
    private API: IApiConfig,
    private UserSession: IUserSession,
    private CountryService: ICountryService,
    private AddressService: IAddressService,
    private PostalCodeService: IPostalCodeService
  ) {}

  $onInit() {
    this.isNewFedexLyocFeatureEnabled = this.UserSession.isNewFedexLyocEnabled();

    this.CountryService.getValidReceiverCountries().then(({ countries, currencies }) => {
      this.countries = countries;
      this.currencies = currencies;
    });

    if (this.esUmbrellaName !== 'fedex') {
      return;
    }

    this.$translate(['toast.default-error']).then((translations) => {
      this.translations = translations;
    });

    this.AddressService.getDefaultAddress('pickup').then((address: IAddress) => {
      this.setDefaultAddress(address);

      this.setStateRequiredValidation(this.esCourierDetails.country_code as string);

      this.esOnChange && this.esOnChange({ courierDetails: this.esCourierDetails });
    });

    if (this.isNewFedexLyocFeatureEnabled) {
      const { currency = 'USD' } = this.UserSession.company;
      this.esCourierDetails = {
        invoice_details: {
          currency,
        },
        validation_option: 'email',
        ...this.esCourierDetails,
      };
    }
  }

  $onChanges(onChangesObj: {
    [property: string]: IChangesObject<{ esLastPinRequestedAt: Date | null }>;
  }): void {
    if ('esLastPinRequestedAt' in onChangesObj) {
      this.processPinLockTick();
    }
  }

  setDefaultAddress(address: IAddress) {
    const { name, phone_number: phoneNumber } = this.UserSession.company;
    const { first_name: firstName, last_name: lastName, email } = this.UserSession.user;

    let defaultAddress: ICourierDetails = {
      contact: {},
      postal_code: address.postal_code,
      city: address.city,
      state: address.state,
      country_code: address.country.alpha2,
      line_1: address.line_1,
      line_2: address.line_2,
      ...this.esCourierDetails,
    };

    if (!this.isNewFedexLyocFeatureEnabled) {
      defaultAddress = {
        ...defaultAddress,
        contact: {
          name: `${firstName} ${lastName}`,
          phone: phoneNumber,
          email,
        },
        company_name: name,
        ...this.esCourierDetails,
      };
    }

    this.esCourierDetails = defaultAddress;
  }

  processPinLockTick() {
    if (!this.esLastPinRequestedAt) {
      this.remainingPinLockSeconds = 0;
      return;
    }

    const remainingSeconds =
      RESENT_LOCK_IN_SECONDS - moment().diff(moment(this.esLastPinRequestedAt), 'seconds');

    this.remainingPinLockSeconds = Math.max(remainingSeconds, 0);

    if (this.remainingPinLockSeconds > 0) {
      this.$timeout(() => this.processPinLockTick(), 250);
    }
  }

  getFedexEulaPdf() {
    let fileName = 'fedex-eula-distribution-agreement';

    if (this.esUmbrellaName === 'fedex-cross-border') {
      fileName = 'fedex-cross-border-eula';
    } else if (this.isNewFedexLyocFeatureEnabled) {
      fileName = 'fedex-eula-v2';
    }

    return `${this.API.easyship_storage}/courier-docs/${fileName}.pdf`;
  }

  onInputChange(value: string, key: string) {
    this.esCourierDetails[key] = value;

    if (key === 'country_code') {
      this.setStateRequiredValidation(this.esCourierDetails[key] as string);
    }

    this.esOnChange && this.esOnChange({ courierDetails: this.esCourierDetails });
  }

  onContactInfoChange(value: string, key: string) {
    this.esCourierDetails.contact[key] = value;

    if (key === 'postal_code') {
      this.retrieveState();
    }
  }

  onInvoiceChange(value: string, key: string) {
    if (!this.esCourierDetails.invoice_details) {
      this.esCourierDetails.invoice_details = {};
    }

    this.esCourierDetails.invoice_details[key] = value;

    if (key === 'date') {
      this.esCourierDetails.invoice_details[key] = moment(value).format('yyyy-MM-DD');
      return;
    }

    this.esOnChange && this.esOnChange({ courierDetails: this.esCourierDetails });
  }

  onGoTo(step: FedexStep) {
    this.esError = '';
    this.esCurrentStep = step;
  }

  onPinResend() {
    this.esOnChange({ courierDetails: { ...this.esCourierDetails, pin_code: null } });

    const payload: IResendPinPayload = {
      id: this.esCourierId,
      account: {
        validation_option: this.esCurrentStep === 'pin-sms' ? 'sms' : 'email',
      },
    };

    this.esOnResendPin({ payload })
      .then(() => {
        toastSuccess(
          this.$translate.instant('courier.connect.form.fedex.pin-choice.resend-success')
        );
      })
      .catch((error) => {
        this.esError =
          (error.data && error.data.status) || this.translations['toast.default-error'];
        toastError(this.$translate.instant('toast.default-error'));
      });
  }

  private setStateRequiredValidation(countryCode: string) {
    this.stateRequired = ['CA', 'US', 'AU'].includes(countryCode);
  }

  private retrieveState() {
    let countryId: number | null = null;
    let postCodeType = 'postal';
    let countryLabel = '';

    if (
      this.esCourierDetails.postal_code.length === 4 &&
      this.esCourierDetails.country_code === 'AU'
    ) {
      countryId = AU_ID;
      countryLabel = 'Australia';
    } else if (
      this.esCourierDetails.postal_code.length === 5 &&
      this.esCourierDetails.country_code === 'US'
    ) {
      countryId = US_ID;
      postCodeType = 'zip';
      countryLabel = 'the US';
    } else if (
      (this.esCourierDetails.postal_code.length === 6 ||
        this.esCourierDetails.postal_code.length === 7) &&
      this.esCourierDetails.country_code === 'CA'
    ) {
      countryId = CA_ID;
      countryLabel = 'Canada';
    } else {
      return;
    }

    this.PostalCodeService.getState({
      postal_code: this.esCourierDetails.postal_code as string,
      country_id: countryId,
    })
      .then(({ state, city }) => {
        this.esCourierDetails.state = state?.name;

        if (this.CountryService.isCountryWithPostalCodeCity(countryId as number)) {
          this.esCourierDetails.city = city;
        }
      })
      .catch(() => {
        toastError(
          `Sorry this ${postCodeType} code doesn't belong to ${countryLabel}, please try a different ${postCodeType}.`
        );
      });
  }
}

const FormFedexComponent: ng.IComponentOptions = {
  controller: FormFedex,
  template,
  bindings: {
    esUmbrellaName: '<',
    esCourierDetails: '<',
    esLastPinRequestedAt: '<',
    esCurrentStep: '=',
    esError: '<',
    esOnChange: '&',
    esOnResendPin: '&',
    esCourierId: '<',
  },
};

export { FormFedexComponent };
