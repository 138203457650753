import 'angular-translate';
import { IPlan, IPlansDetail, ISubscriptionObject } from 'typings/subscription';
import { IUserSession } from 'typings/user-session';
import { IApiConfig } from 'typings/core/config/api';
import { BillingInterval, FEATURE_KEY } from '@client/data/subscription';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import template from './table-card.html?raw';
import styleTableCard from './table-card.module.scss';

enum DefaultData {
  Title = 'subscription.compare.header',
  Label = 'subscription.compare.annual-pitch',
  LegacyLabel = 'subscription.compare.message-legacy',
  LearnMore = 'global.learn-more',
  ToggleOn = 'subscription.compare.toggle-monthly',
  ToogleOff = 'subscription.compare.toggle-annually',
}

class SubscriptionTableCard implements IComponentController {
  style = styleTableCard;
  esShowCloseButton = true;
  esTitle = '';
  esLabel = '';
  esLegacyLabel = '';
  esLearnMore = '';
  esToggleOn = '';
  esToogleOff = '';
  esToggleState = false;
  esLearnMoreUrl = '#';
  plansDetail: IPlansDetail | null = null;
  selectedPlanId: number | null = null;
  isCurrentPlanChangeable = true;
  _countryId: number | null = null;
  wrappers = {
    a: (chunk: string) =>
      `<a href="https://support.easyship.com/hc/en-us/articles/7796000345241-Supscription-Plan-Changes" target="_blank" rel="noopener">${chunk}</a>`,
  };

  static $inject = ['$translate', 'SubscriptionService', 'UserSession', 'API', 'MixpanelService'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private SubscriptionService: SubscriptionService,
    private UserSession: IUserSession,
    private API: IApiConfig,
    private MixpanelService: MixpanelService
  ) {}

  esToggleAction(state: { value: boolean }): void {}

  $onInit(): void {
    this.isCurrentPlanChangeable = this.SubscriptionService.isCurrentPlanChangeable;

    if (this.esShowCloseButton === undefined) {
      this.esShowCloseButton = true;
    }

    if (!this.esTitle) {
      this.esTitle = this.$translate.instant(DefaultData.Title);
    }

    if (!this.esLabel) {
      this.esLabel = this.$translate.instant(DefaultData.Label);
    }

    if (!this.esLegacyLabel) {
      this.esLegacyLabel = this.$translate.instant(DefaultData.LegacyLabel);
    }

    if (!this.esLearnMore) {
      this.esLearnMore = this.$translate.instant(DefaultData.LearnMore);
    }

    if (!this.esToggleOn) {
      this.esToggleOn = this.$translate.instant(DefaultData.ToggleOn);
    }

    if (!this.esToogleOff) {
      this.esToogleOff = this.$translate.instant(DefaultData.ToogleOff);
    }

    if (this.esToggleState === undefined) {
      this.esToggleState = false;
    }

    if (!this.esLearnMoreUrl) {
      this.esLearnMoreUrl = `${this.API.help}/hc/en-us/articles/360035582872-Easyship-Pricing-Plans-Breakdown`;
    }

    if (this.UserSession) {
      const { company } = this.UserSession;
      this.plansDetail =
        this.SubscriptionService.plansDetail &&
        this.filterPlansByFeatureFlag(this.SubscriptionService.plansDetail);

      if (!this.plansDetail) {
        this.SubscriptionService.fetchPlansDetail({
          country_id: company.country_id,
        }).then((response) => {
          this.plansDetail = this.filterPlansByFeatureFlag(response);
        });
      }

      if (!this.SubscriptionService.currentSubscription) {
        this.SubscriptionService.fetchCurrentSubscription({
          company_id: company.id,
        });
      }
    }
  }

  private filterPlansByFeatureFlag(plansDetail: IPlansDetail): IPlansDetail {
    const isCustomizeTableDisabled =
      !this.UserSession.getFeatureFlagsByFlagName('new_shipments_table');
    if (isCustomizeTableDisabled) {
      const filteredFeatures = plansDetail.features.filter(
        (feature) => feature.key !== FEATURE_KEY.CustomizeShipmentTable
      );

      const filteredPlansDetail: IPlansDetail = {
        ...plansDetail,
        features: filteredFeatures,
      };

      return filteredPlansDetail;
    }
    return plansDetail;
  }

  get billingInterval(): BillingInterval {
    return this.esToggleState ? BillingInterval.Year : BillingInterval.Month;
  }

  get currentSubscription(): ISubscriptionObject | null {
    return this.SubscriptionService.currentSubscription;
  }

  get isLegacyPlan(): boolean {
    if (this.currentSubscription) {
      return this.SubscriptionService.isCurrentLegacyPlan(this.currentSubscription, false);
    }

    return false;
  }

  get sortedPlansByRanking(): IPlan[] {
    return this.plansDetail?.plans?.sort((planA, planB) => planA.ranking - planB.ranking) ?? [];
  }

  get currentPlanName(): string {
    return this.SubscriptionService.currentPlanName;
  }

  get isPlanVersionOutdated(): boolean {
    return this.SubscriptionService.isPlanVersionOutdated;
  }

  get formattedMigrateDate(): string {
    return this.SubscriptionService.formattedMigrateDate;
  }

  toggleAction(value: boolean): void {
    this.esToggleState = value;
    this.esToggleAction({ value });

    this.MixpanelService.track('Subscription - Plans - Period Toggle');
  }
}

const SubscriptionTableCardComponent: ng.IComponentOptions = {
  controller: SubscriptionTableCard,
  template,
  bindings: {
    esTableHeaderAction: '&',
    esShowCloseButton: '<',
    esCloseAction: '&',
    esTitle: '<',
    esLabel: '<',
    esLegacyLabel: '<',
    esLearnMore: '<',
    esLearnMoreUrl: '<',
    esToggleId: '<',
    esToggleOn: '<',
    esToogleOff: '<',
    esToggleState: '=?',
    esToggleAction: '&',
  },
};

export { SubscriptionTableCardComponent };
