import { LocalStorageExpiry } from '@client/core/helpers/LocalStorageExpiry';
import { useEffect } from 'react';

let didInit = false;
const localStorageExpiry = new LocalStorageExpiry();

export function useSettingImpactRadius() {
  function initialize() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const irClickId = urlParams.get('irclickid');
    const mediaId = urlParams.get('utm_medium') === 'affiliate' && urlParams.get('utm_campaign');

    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 30);

    if (irClickId) {
      localStorageExpiry.set('irClickId', irClickId, expiryDate.toISOString());
    }
    if (mediaId) {
      localStorageExpiry.set('mediaId', mediaId, expiryDate.toISOString());
    }
  }
  useEffect(() => {
    if (!didInit) {
      initialize();
      didInit = true;
    }
  }, []);
}
