(function () {
  'use strict';

  var factories = [
    'app.factory.Address',
    'app.factory.AddressSearch',
    'app.factory.Auth',
    'app.factory.AutomationAction',
    'app.factory.AutomationRule',
    'app.factory.Company',
    'app.factory.Country',
    'app.factory.CountryCMS',
    'app.factory.Courier',
    'app.factory.GenerateLabel',
    'app.factory.GoogleAnalytics',
    'app.factory.HkDistrict',
    'app.factory.Invitee',
    'app.factory.ItemCategory',
    'app.factory.Lead',
    'app.factory.Message',
    'app.factory.PaymentSource',
    'app.factory.Platform',
    'app.factory.PlatformApi',
    'app.factory.OauthPlatformApi',
    'app.factory.StorePlatformApi',
    'app.factory.RedirectStorePlatformApi',
    'app.factory.CredentialsStorePlatformApi',
    'app.factory.PostalCode',
    'app.factory.Product',
    'app.factory.RateCalculator',
    'app.factory.ServiceType',
    'app.factory.Session',
    'app.factory.ShipmentItem',
    'app.factory.ShippingRules',
    'app.factory.Store',
    'app.factory.UserSession',
    'app.factory.Checkout',
    'app.factory.Batch',
    'app.factory.Sso',
    'app.factory.WalkthroughData',
    'app.factory.DestinationAddresses',
    'app.factory.Luxottica',
  ];

  angular.module('app.factories', factories);
})();
