/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import { IShipmentListLogsPriceCalculationItem } from 'typings/shipment';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';
import { useUserSession } from '@client/src/global/context/UserSessionProvider';
import AlertIcon from '@client/src/create-shipments/shipment-details-drawer/ShipmentDetailsDrawerToolbar/Tabs/components/TabLabelTitle/AlertIcon';
import TruncateText from '@client/core/components/react/TruncateText/TruncateText';
import CellLayout from './CellLayout';
import { CellProps } from './d';

export const ItemsCell = memo(({ row }: CellProps) => {
  const {
    company: { currency },
  } = useUserSession();
  const shipment = row.original;
  const { shipment_items_count: numberOfItems, total_customs_value: totalCustomsValue } = shipment;
  const totalSellingPrice = totalCustomsValue ? Number(totalCustomsValue).toFixed(2) : '0.00';

  const shipmentLogItems = (
    shipment.logs.price_calculation as IShipmentListLogsPriceCalculationItem
  ).shipment_items;
  const logItems = shipmentLogItems ? Object.values(shipmentLogItems) : [];

  const hasQuantityError = numberOfItems <= 0 || logItems.some(({ quantity }) => !!quantity);
  const hasSellingPriceError =
    Number(totalSellingPrice) < 0 ||
    logItems.some(({ declared_customs_value }) => !!declared_customs_value);

  const { company_sku: sku, description, quantity } = shipment.first_shipment_item;
  const hasSingleItem = numberOfItems === 1 || numberOfItems === quantity;

  return (
    <CellLayout>
      <Box className="flex flex-col justify-center min-w-[112px] gap-1 items-start px-2">
        {hasQuantityError ? (
          <div className="flex items-center gap-1">
            <AlertIcon severity="error" />
            <span className="text-red-700 text-[12px]">
              <FormattedMessage id="global.invalid-qty" />
            </span>
          </div>
        ) : hasSingleItem ? (
          <div className="font-bold flex">
            <TruncateText
              label={`${quantity} ${description}`}
              className="whitespace-normal line-clamp-2 text-ink-900"
            />
          </div>
        ) : (
          <div className="font-bold text-ink-900">
            <FormattedMessage
              id="global.pluralize.with-count.item"
              values={{ count: numberOfItems }}
            />
          </div>
        )}
        {hasSellingPriceError ? (
          <div className="flex items-center gap-1">
            <AlertIcon severity="error" />
            <span className="text-red-700 text-[12px]">
              <FormattedMessage id="global.invalid-price" />
            </span>
          </div>
        ) : (
          <TruncateText
            label={`${hasSingleItem && !!sku ? `${sku} • ` : ''}${currency} ${totalSellingPrice}`}
            className="flex gap-1 font-normal leading-5 text-ink-900 sm whitespace-normal line-clamp-1"
          />
        )}
      </Box>
    </CellLayout>
  );
});
