import { Chance } from 'chance';
import { OrderData, OrderEditData } from './OrderData';

const chance = new Chance();

export const orderMock = (): OrderData => ({
  platform: { name: chance.string(), orderNumber: chance.guid(), slug: chance.string() },
  tags: [],
  incoterms: chance.pickone(['DDU', 'DDP']),
  isInsured: chance.bool(),
  insuranceRequestedByBuyer: chance.bool(),
  buyerNotes: chance.string(),
  sellerNotes: chance.string(),
  includeReturnLabel: chance.bool(),
  parcels: [],
  eeiReference: chance.string(),
  totalActualWeight: chance.floating({ min: 0, max: 10 }),
  totalChargeableWeight: chance.floating({ min: 0, max: 10 }),
  store: { name: chance.string() },
});

export const orderEditMock = (): OrderEditData => ({
  platform: { name: chance.string(), orderNumber: chance.guid() },
  tags: [],
  incoterms: chance.pickone(['DDU', 'DDP']),
  isInsured: chance.bool(),
  sellerNotes: chance.string(),
  includeReturnLabel: chance.bool(),
  parcels: [],
  eeiReference: chance.string(),
  optionalService: { value: 'none' },
});
