import { IComponentController } from 'angular';
import template from './become-enterprise-card.html?raw';
import style from './become-enterprise-card.module.scss';
import image from './become-enterprise-card.svg?svgo';

class BecomeEnterpriseCard implements IComponentController {
  style = style;
  image = image;
}

const BecomeEnterpriseCardComponent: ng.IComponentOptions = {
  controller: BecomeEnterpriseCard,
  template,
  bindings: {
    esButtonAction: '&',
    esCloseAction: '&',
  },
};

export { BecomeEnterpriseCardComponent };
