import React, { useCallback, useEffect, useRef } from 'react';

export interface ShipmentsTableContainerProps {
  children: React.ReactNode;
}

function ShipmentsTableContainer({ children }: ShipmentsTableContainerProps) {
  const refTableBody = useRef<HTMLDivElement>(null);
  const refTableContainer = useRef<HTMLDivElement>(null);
  const refThumb = useRef<HTMLDivElement>(null);
  const refThumbContainer = useRef<HTMLDivElement>(null);
  const refBottomTable = useRef<HTMLDivElement>(null);

  const mouseState = useRef({
    x: 0,
    ratio: 0,
    pressed: false,
  });

  const calculateThumb = useCallback(() => {
    const content = refTableContainer.current as HTMLDivElement;
    const contentThumbContainer = refThumbContainer.current as HTMLDivElement;
    const { scrollLeft, scrollWidth, clientWidth } = content;

    const realScrollSpace = scrollWidth - clientWidth;
    if (realScrollSpace < 10) {
      contentThumbContainer.style.display = 'none';
      return;
    }

    if (contentThumbContainer.style.display === 'none')
      requestAnimationFrame(() => {
        contentThumbContainer.style.display = 'block';
      });
    const thumbWidth = Math.floor((clientWidth / scrollWidth) * 100);
    const thumbScrollSpace = (clientWidth * (100 - thumbWidth)) / 100;
    const ratio = thumbScrollSpace / realScrollSpace;
    const thumbPosition = Math.floor(ratio * scrollLeft);

    if (thumbPosition > 4) {
      refTableContainer.current?.classList.add('scrolled-left-table');
    } else refTableContainer.current?.classList.remove('scrolled-left-table');

    mouseState.current.ratio = ratio;

    requestAnimationFrame(() => {
      const content = refThumb.current as HTMLDivElement;
      content.style.width = `${thumbWidth}%`;
      content.style.left = `${thumbPosition}px`;
    });
  }, []);

  useEffect(() => {
    const contentContainer = refTableContainer.current as HTMLDivElement;
    const contentBody = refTableBody.current as HTMLDivElement;
    const contentThumb = refThumb.current as HTMLDivElement;

    const onMouseMoveHandler = (event: MouseEvent) => {
      if (!mouseState.current.pressed || !mouseState.current.ratio) return;
      const moveX = event.clientX - mouseState.current.x;
      if (Math.abs(moveX) < 3) return;
      mouseState.current = {
        ...mouseState.current,
        x: event.clientX,
      };
      const move = Math.ceil(moveX / mouseState.current.ratio);
      requestAnimationFrame(() => {
        contentContainer.scrollLeft += move;
      });
    };

    const onMouseDownHandler = (event: MouseEvent) => {
      if (event.button !== 0) return;
      document.removeEventListener('mousemove', onMouseMoveHandler);
      document.addEventListener('mousemove', onMouseMoveHandler);
      mouseState.current = {
        ...mouseState.current,
        x: event.clientX,
        pressed: true,
      };
      contentThumb.style.cursor = 'grabbing';
    };

    const onMouseUpHandler = () => {
      document.removeEventListener('mousemove', onMouseMoveHandler);
      mouseState.current = {
        ...mouseState.current,
        x: 0,
        pressed: false,
      };
      contentThumb.style.cursor = '';
    };

    contentContainer.addEventListener('scroll', calculateThumb);
    contentThumb.addEventListener('mousedown', onMouseDownHandler);
    document.addEventListener('mouseup', onMouseUpHandler);

    const observerResize = new ResizeObserver(() => {
      calculateThumb();
    });

    observerResize.observe(contentContainer);
    observerResize.observe(contentBody);

    const observerView = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        const content = refThumbContainer.current as HTMLDivElement;
        if (!entry?.isIntersecting) {
          content.classList.add('scroll-sticky');
        } else content.classList.remove('scroll-sticky');
      },
      {
        threshold: [0, 1],
        rootMargin: '-20px',
      }
    );

    observerView.observe(refBottomTable.current as HTMLDivElement);
    return () => {
      contentContainer.removeEventListener('scroll', calculateThumb);
      contentThumb.removeEventListener('mousedown', onMouseDownHandler);
      document.removeEventListener('mouseup', onMouseUpHandler);
      document.removeEventListener('mousemove', onMouseMoveHandler);
      observerResize.disconnect();
      observerView.disconnect();
    };
  }, [calculateThumb]);

  return (
    <>
      <div
        className="w-full min-w-full relative table-x-scroll-not-visible overflow-x-auto"
        ref={refTableContainer}
      >
        <div className="w-fit min-w-full" ref={refTableBody}>
          {children}
        </div>
        <div ref={refBottomTable} className="w-full h-[1px] bg-transparent p-0 m-0" />
      </div>
      <div className="sticky bottom-[10px] w-full h-[20px] z-10" ref={refThumbContainer}>
        <div className="relative w-full h-full">
          <div className="h-[20px] py-[5px] absolute cursor-grab top-0" ref={refThumb}>
            <div className="h-full w-full bg-sky-700 border-ink-100 rounded-[14px]" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ShipmentsTableContainer;
