import { IUserSession } from 'typings/user-session';
import { Persona } from '@client/data/persona';

import { IComponentController } from 'angular';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import template from './app-logo.html?raw';
import style from './app-logo.module.scss';

class AppLogoController implements IComponentController {
  style = style;

  static $inject = ['UserSession', 'SubscriptionService'];
  constructor(
    private UserSession: IUserSession,
    private SubscriptionService: SubscriptionService
  ) {}

  get plan(): string {
    return this.SubscriptionService.currentSubscription?.plan?.name || '';
  }

  get srcPath(): string {
    if (this.UserSession.isSkyPostal()) {
      return new URL(
        '../../../../../../assets/images/logos/easyship-logo/skypostal-powered-by-easyship.svg',
        import.meta.url
      ).href;
    }

    if (this.UserSession.isParcelCast()) {
      return '/images/couriers/parcelcast.png';
    }

    if (this.UserSession.isPostageForce()) {
      return '/images/couriers/postageforce.png';
    }

    if (this.UserSession.isPackifi()) {
      return '/images/couriers/packifi.png';
    }

    if (this.UserSession.isUberFreightUser()) {
      return new URL(
        '../../../../../../assets/images/logos/easyship-logo/uber-freight.svg',
        import.meta.url
      ).href;
    }

    return new URL(
      '../../../../../../assets/images/logos/easyship-logo/logo-dark.svg',
      import.meta.url
    ).href;
  }

  get userPersona(): Persona {
    return this.UserSession.getUserPersona();
  }
}

const AppLogoComponent: ng.IComponentOptions = {
  controller: AppLogoController,
  template,
  bindings: {},
};

export { AppLogoComponent };
