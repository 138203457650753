import { useMutation } from '@tanstack/react-query';
import { useAuthGateway } from '../providers/AuthGatewayProvider';

interface EmailValidationParams {
  email: string;
}

export default function useValidateEmailMutation() {
  const { authGateway } = useAuthGateway();

  return useMutation({
    mutationFn: ({ email }: EmailValidationParams) => authGateway.validateEmail({ email }),
  });
}
