import { IShowChangePlanObject } from 'typings/subscription';

import { OnboardingChecklist } from '@client/src/data/onboarding-checklist';
import { FEATURE_KEY, FeatureKey } from '@client/data/subscription';

import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { SubscriptionService } from '@client/src/global/services/subscription/subscription.service';
import { OnboardingChecklistService } from '@client/src/global/services/onboarding-checklist/onboarding-checklist.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import { MODAL, openModalByName } from '@/utils/angular-modal';
import style from './packing-slip.module.scss';
import template from './packing-slip.html?raw';

interface DocumentMainIdentifierOption {
  key: string;
  name: string;
}

interface IFormData {
  packing_slip_remark: string;
  packing_slip_return_address_id: string;
  packing_slip_identifier: string;
  packing_slip_show_buyer_notes: boolean;
  packing_slip_show_company_url: boolean;
  packing_slip_show_header: boolean;
  packing_slip_show_item_price: boolean;
  packing_slip_show_return_address: boolean;
  packing_slip_show_shipping_price: boolean;
  packing_slip_show_email: boolean;
  packing_slip_show_pick_location: boolean;
}

class PackingSlip implements IComponentController {
  style = style;
  formData: any;
  storedFootnoteValue = '';
  loading = {
    form: false,
    settings: false,
  };
  imagePreviewUrl = '';
  showPackingSlipFooter = false;
  packingSlipForm: ng.IFormController | null = null;
  showModal: Partial<IShowChangePlanObject> = {
    compare: false,
    upgrade: false,
    downgrade: false,
    enterpriseCall: false,
    callScheduledCard: false,
  };
  documentMainIdentifiers: DocumentMainIdentifierOption[] = [];
  translations: Record<string, string> = {};

  static $inject = [
    '$translate',
    'AddressService',
    'CompanyService',

    'MixpanelService',
    'SubscriptionService',
    'OnboardingChecklistService',
    'UserRightsService',
  ];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private AddressService: any,
    private CompanyService: any,
    private MixpanelService: MixpanelService,
    private SubscriptionService: SubscriptionService,
    private OnboardingChecklistService: OnboardingChecklistService,
    private UserRightsService: UserRightsService
  ) {
    this.documentMainIdentifiers = ['easyship_shipment_id', 'order_number'].map((identifier) => ({
      key: identifier,
      name: this.$translate.instant(`settings.packing-slip.identifiers.${identifier}`),
    }));
  }

  get isBrandedTrackingAvailable(): boolean {
    return this.SubscriptionService.isFeatureAccessible(FEATURE_KEY.BrandedTrackingExperience);
  }

  get canEditPackingSlipSetting(): boolean {
    return this.UserRightsService.canEditPackingSlipSetting;
  }

  get isElementDisabled(): boolean {
    return !this.isBrandedTrackingAvailable && this.SubscriptionService.isSubscriptionAvailable;
  }

  get isReturnAddressDisabled(): boolean {
    return this.isElementDisabled || this.formData.packing_slip_return_address_id === null;
  }

  get shippingAddress(): any {
    const shippingAddresses = this.AddressService.getShippingAddresses();
    if (!shippingAddresses || shippingAddresses.length <= 0) {
      return null;
    }

    return shippingAddresses;
  }

  isPlanBadgeVisible(featureKey: FeatureKey) {
    return this.SubscriptionService.isPlanBadgeVisible(featureKey);
  }

  getPlanNameByFeatureKey(featureKey: FeatureKey) {
    return this.SubscriptionService.getPlanNameByFeatureKey(featureKey);
  }

  $onInit() {
    this.loading.settings = true;
    this.$translate(['global.setting']).then((translations) => {
      this.translations = translations;
    });

    this.CompanyService.getPackingSlipBrandingData()
      .then(async (data: IFormData) => {
        this.formData = data;

        if (!this.formData.packing_slip_identifier) {
          this.formData.packing_slip_identifier = 'easyship_shipment_id';
        }

        this.storedFootnoteValue = this.formData.packing_slip_remark;
        this._setPreviewImage(this.formData);

        await this.AddressService.getDefaultAddress('return')
          .then((address: any) => {
            this.formData.packing_slip_return_address_id = address && address.id;
          })
          .catch(() => {
            this.formData.packing_slip_show_return_address = false;
          });
      })
      .catch(() => {
        toastError(this.$translate.instant('toast.default-error'));
      })
      .finally(() => {
        this.loading.settings = false;
      });
  }

  _getFirstShippingAddress(addresses: any): string | null {
    return (
      addresses &&
      addresses.shippingAddresses &&
      addresses.shippingAddresses[0] &&
      addresses.shippingAddresses[0].id
    );
  }

  viewPreview(): void {
    openModalByName(MODAL.PREVIEW_PACKiNG_SLIP);
    this.MixpanelService.track('Settings - Packing Slip - Preview Packing Slip');
  }

  onToggleChange(value: boolean, model: string): void {
    const data = { [model]: value };

    this.formData[model] = value;
    this._updatePackingSlip(data);

    const eventMapping: any = {
      packing_slip_show_item_price: 'Item Price',
      packing_slip_show_shipping_price: 'Shipping Price',
      packing_slip_show_header: 'Header',
      packing_slip_show_company_url: 'Show URL',
      packing_slip_show_return_address: 'Show Return Address',
      packing_slip_show_buyer_notes: 'Show Buyer Message',
      packing_slip_show_seller_notes: 'Show Seller Notes',
      packing_slip_show_email: 'Show receiver email',
      packing_slip_show_pick_location: 'Show Pick Location',
    };
    const event = eventMapping[model];

    this.MixpanelService.track(`Settings - Packing Slip - ${event}`, { selection: value });
  }

  onAddressChange(value: string, model: string): void {
    this.formData[model] = value;
  }

  onTextAreaChange(value: string, model: string): void {
    this.formData[model] = value;
  }

  onSelectChange(value: string, model: string): void {
    this.formData[model] = value;

    const data = {
      packing_slip_identifier: this.formData.packing_slip_identifier,
    };

    this._updatePackingSlip(data);
  }

  onSubmitForm(): void {
    if (this.packingSlipForm && this.packingSlipForm.$invalid) {
      toastError(this.$translate.instant('toast.incomplete-form'));
      return;
    }

    const data = {
      packing_slip_remark: this.formData.packing_slip_remark,
      packing_slip_return_address_id: this.formData.packing_slip_return_address_id,
    };

    this.loading.form = true;
    this._updatePackingSlip(data);
    this.MixpanelService.track('Settings - Packing Slip - Save');
  }

  _updatePackingSlip(data: any): void {
    this._slidePackingSlip(data);
    this._setPreviewImage(this.formData);

    this.CompanyService.updateCompany(data)
      .then(() => {
        toastSuccess(
          this.$translate.instant(
            'toast.update-success',
            { noun: this.translations['global.setting'].toLowerCase() },
            'messageformat'
          )
        );
      })
      .catch((error: any) => {
        toastError(
          this.$translate.instant('toast.update-error', {
            noun: this.translations['global.setting'].toLowerCase(),
            details: error.data?.status,
          })
        );
      })
      .finally(() => {
        this.loading.form = false;
      });

    this.OnboardingChecklistService.updateOnboarding(OnboardingChecklist.SetupBranding);
  }

  _setPreviewImage(formData: IFormData): void {
    const {
      packing_slip_show_header,
      packing_slip_show_shipping_price,
      packing_slip_show_item_price,
      packing_slip_show_company_url,
      packing_slip_show_return_address,
      packing_slip_remark,
    } = formData;

    const showHeader = packing_slip_show_header ? 'show-header' : 'hide-header';
    const showShippingCost = packing_slip_show_shipping_price ? 'show-shipping' : 'hide-shipping';
    const showItemCost = packing_slip_show_item_price ? 'show-item' : 'hide-item';
    const showWebsite = packing_slip_show_company_url ? 'show-website' : 'hide-website';
    const showFootnote = packing_slip_remark ? 'show-footnote' : 'hide-footnote';
    const showReturnAddress = packing_slip_show_return_address ? 'show-address' : 'hide-address';

    this.imagePreviewUrl = new URL(
      `../../../../assets/dynamic-images/dashboard/tools/packing-slip/${showHeader}_${showShippingCost}_${showItemCost}_${showWebsite}_${showFootnote}_${showReturnAddress}.png`,
      import.meta.url
    ).href;
  }

  _slidePackingSlip(data: any): boolean | any {
    const { packing_slip_show_company_url, packing_slip_remark } = data;

    // slide down packing slip when working on homepage url or form only. Otherwise, slip up
    if (
      typeof packing_slip_show_company_url !== 'undefined' ||
      typeof packing_slip_remark !== 'undefined'
    ) {
      return (this.showPackingSlipFooter = true);
    }

    this.showPackingSlipFooter = false;
  }

  onOpenCompareModal(): void {
    this.showModal.compare = true;
    this.MixpanelService.track('Subscription - Plans - Open', {
      trigger_source: 'Branded Packing Slip',
    });
  }

  get isPackingSlipFieldDisabled() {
    return this.isElementDisabled || !this.canEditPackingSlipSetting;
  }
}

const PackingSlipComponent = {
  template,
  controller: PackingSlip,
};

export { PackingSlipComponent };
