import { GammaHeroIcon } from '@client/src/components/images/GammaHeroIcon';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export function GammaHero() {
  const theme = useTheme();
  const isScreenSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      {!isScreenSm ? (
        <div className="px-[64px] pt-[100px]">
          <h1 className="text-ink-900 mb-10 font-bold text-[56px] leading-normal">
            <FormattedMessage id="auth-landing.gamma.title" values={{ isScreenSm, br: <br /> }} />
          </h1>
          <div className="grid grid-cols-2">
            <p className="text-xl leading-normal text-ink-900">
              <FormattedMessage id="auth-landing.gamma.description" />
            </p>
            <div className="relative">
              <GammaHeroIcon
                className="absolute left-[-100px]"
                style={{ width: '518px', height: '525px' }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="px-8 min-[536px]:px-[20%] pt-5">
          <div className="grid grid-cols-3 md:min-h-[300px]">
            <div className="relative">
              <GammaHeroIcon
                className="absolute left-[-60%] md:left-[-20%] top-[5%]"
                style={{ width: '350px', height: '350px' }}
              />
            </div>
            <div className="col-span-2 md:mb-0 mb-24 flex items-center md:pr-6">
              <div>
                <h1 className="text-ink-900 mb-4 font-bold text-[28px] text-right leading-normal">
                  <FormattedMessage
                    id="auth-landing.gamma.title"
                    values={{ isScreenSm, br: <br /> }}
                  />
                </h1>
                <p className="text-lg w-2/4 ml-auto text-right leading-normal text-ink-900">
                  <FormattedMessage id="auth-landing.gamma.description" />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
