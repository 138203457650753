import { IDeviceService } from 'typings/core/services/device';
import { IHelperService } from 'typings/helper';
import { IComponentController } from 'angular';
import { WebsiteApiService } from '@client/core/services/website-api/website-api.service';
import style from './pdf-embed.module.scss';
import template from './pdf-embed.html?raw';

class PdfEmbed implements IComponentController {
  style = style;
  esSrc: string | undefined = undefined;
  busy = {
    gettingGeoLocation: true,
  };
  country: string | null = null;

  static $inject = ['$sce', '$window', 'WebsiteApiService', 'DeviceService', 'HelperService'];
  constructor(
    private $sce: ng.ISCEService,
    private $window: ng.IWindowService,
    private WebsiteApiService: WebsiteApiService,
    private DeviceService: IDeviceService,
    private HelperService: IHelperService
  ) {}

  $onInit() {
    this.WebsiteApiService.getIpGeolocation()
      .then(({ data }) => {
        this.country = data.countryCode;
      })
      .finally(() => {
        this.busy.gettingGeoLocation = false;
      });
  }

  // Fix issue where mobile devices only display first page of the pdf
  // Tested on iPad & iPhone
  get googleViewerUrl(): string {
    return `https://docs.google.com/viewerng/viewer?url=${this.esSrc as string}&embedded=true`;
  }

  get trustedSrcUrl(): string {
    return this.$sce.trustAsResourceUrl(
      this.showPrintButton ? this.googleViewerUrl : (this.esSrc as string)
    );
  }

  /**
   * Make print button accessible except in China
   * Some browsers & devices that does not have print button
   * Tested on Safari, iPad & iPhone
   */
  get showPrintButton(): boolean {
    const isSafari = /^((?!chrome|android).)*safari/i.test(this.$window.navigator.userAgent);
    const noPdfPreviewSupport =
      this.DeviceService.isMobileOs() || (this.DeviceService.isMacintosh() && isSafari);

    return this.country !== 'CN' && noPdfPreviewSupport;
  }

  print(): void {
    this.HelperService.openNewTab(this.esSrc as string);
  }
}

const PdfEmbedComponent: ng.IComponentOptions = {
  controller: PdfEmbed,
  template,
  bindings: {
    esSrc: '<',
    esHidePrintButton: '<',
  },
};

export { PdfEmbedComponent };
