import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export function DeltaHero() {
  const theme = useTheme();
  const isScreenSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className="h-full w-full overflow-hidden sm:max-lg:h-[465px]">
      <img
        style={{ objectPosition: isScreenSm ? '7px' : '43% -55px' }}
        className="object-cover scale-150 relative h-full w-full"
        src="/images/dashboard/auth-landing/delta-hero-bg.jpg"
        alt="delta-hero"
      />
      <div className="absolute w-[246px] lg:w-[40%] md:max-lg:w-[60%] sm:top-[13%] md:max-lg:top-[2%] lg:max-xl:left-[5%] xl:max-2xl:left-[6%] 2xl:left-[7%] top-[12%] left-[17%]">
        <h1 className="xl:text-[56px] sm-max-lg:text-xl md:text-[52px] tall:text-ink-200 font-bold leading-normal text-ink-900 lg:mb-5 mb-2">
          <FormattedMessage id="auth-landing.delta.title" values={{ isScreenSm, br: <br /> }} />
        </h1>
        <p className="leading-normal">
          <FormattedMessage id="auth-landing.delta.description" />
        </p>
      </div>
    </div>
  );
}
