import { IComponentController } from 'angular';
import template from './complete-modal.html?raw';
import style from './complete-modal.module.scss';
import celebrate from './celebrate.svg?svgo';

class CompleteModal implements IComponentController {
  style = style;
  celebrate: string;

  static $inject = ['$sce'];
  constructor($sce: ng.ISCEService) {
    this.celebrate = $sce.trustAsHtml(celebrate);
  }
}

const CompleteModalComponent: ng.IComponentOptions = {
  controller: CompleteModal,
  template,
  bindings: {
    esClose: '&',
  },
};

export { CompleteModalComponent };
