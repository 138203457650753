import angular from 'angular';

import easyshipButton from '@client/core/components/buttons/button';
import { CompleteModalComponent } from './complete-modal.component';

const MODULE_NAME = 'app.global.components.dashboard-sidebar.onboarding-checklist.complete-modal';

angular
  .module(MODULE_NAME, ['pascalprecht.translate', easyshipButton])
  .component('esCompleteModal', CompleteModalComponent);

export default MODULE_NAME;
