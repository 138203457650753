import { BillingInterval } from '@client/data/subscription';
import { useSubscriptionService } from '@client/src/global/context/SubscriptionProvider';
import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { IPlan as IApiPlan, PlanRanking } from 'typings/subscription';
import { ICategorizedPlan, IPlan } from './types';
import {
  getRate,
  getTaxAmount,
  getTaxName,
  isCustomRate,
  isActionAnUpgrade,
  getActionButtonLabel,
  isCurrentSubscribedPlan,
  getSmallestRankingInScalePlans,
} from './utils';

interface IUsePreprocessPlansProps {
  apiPlans: IApiPlan[];
  billingInterval: BillingInterval;
  taxName: string;
}

interface IUsePreProcessPlansReturn {
  plans: IPlan[];
  categorizedPlan: ICategorizedPlan;
  smallestRankingInScalePlans: PlanRanking;
}

function usePreprocessPlans({
  apiPlans,
  billingInterval,
  taxName,
}: IUsePreprocessPlansProps): IUsePreProcessPlansReturn {
  const { currentSubscription, isLegacyTrialEnd, currentSubscriptionPeriod } =
    useSubscriptionService();
  const { formatMessage } = useIntl();

  const plans: IPlan[] = useMemo(
    () =>
      apiPlans.map<IPlan>((plan) => {
        if (!currentSubscription?.plan) {
          throw new Error('[useComparePlanTable] currentSubscription.plan does not exist');
        }

        const rate = getRate({ price: plan.price, billingInterval });
        const taxAmount = getTaxAmount({ price: plan.price, billingInterval });
        const action = getActionButtonLabel({
          currentSubscribedPlan: currentSubscription.plan,
          currentSubscriptionPeriod,
          plan,
          billingInterval,
          isLegacyTrialEnd,
        });

        const isCurrent = isCurrentSubscribedPlan({
          planId: plan.id,
          currentPlanId: currentSubscription.plan.id,
          currentInterval: currentSubscription.plan.interval,
          billingInterval,
          isLegacyTrialEnd,
        });

        const planSlugI18nKey = plan.slug ? `freemium-pricing.category.${plan.slug}` : '';

        return {
          id: plan.id,
          name: formatMessage({ id: planSlugI18nKey }),
          version: plan.version,
          ranking: plan.ranking,
          tier: plan.tier,
          rate,
          taxName: getTaxName(taxName),
          isTaxed: !!taxAmount,
          totalAmount: rate && rate + taxAmount,
          isCustom: isCustomRate({ price: plan.price, billingInterval }),
          isCurrent,
          isUpgrade: isActionAnUpgrade(action),
          action,
        };
      }),
    [
      apiPlans,
      billingInterval,
      currentSubscription.plan,
      formatMessage,
      isLegacyTrialEnd,
      currentSubscriptionPeriod,
      taxName,
    ]
  );

  const categorizedPlan = useMemo(() => {
    const planGroupByTier: Record<PlanRanking, IPlan[]> = groupBy(plans, 'tier');

    return Object.values(planGroupByTier).reduce<ICategorizedPlan>(
      (acc, curr) => {
        const isStandardPlan = curr.length === 1;
        if (isStandardPlan) {
          acc.standardPlans.push(...curr);
        } else {
          acc.scalePlans.push(...curr);
        }

        return acc;
      },
      {
        standardPlans: [],
        scalePlans: [],
      }
    );
  }, [plans]);

  const smallestRankingInScalePlans = useMemo(
    () => getSmallestRankingInScalePlans(categorizedPlan.scalePlans),
    [categorizedPlan.scalePlans]
  );

  return {
    plans,
    categorizedPlan,
    smallestRankingInScalePlans,
  };
}

export default usePreprocessPlans;
