import Chance from 'chance';
import { countryMock } from '../Country';
import { AddressData, AddressEditData } from './AddressData';

const chance = new Chance();

export const AddressMock = (): AddressData => ({
  companyName: chance.company(),
  phoneNumber: chance.phone(),
  email: chance.email(),
  country: countryMock(),
  postalCode: chance.zip(),
  state: chance.state({ full: true }),
  city: chance.city(),
  addressLine1: chance.address(),
  addressLine2: '',
});

export const AddressEditMock = (): AddressEditData => ({
  companyName: chance.company(),
  phoneNumber: chance.phone(),
  email: chance.email(),
  countryId: chance.integer({ min: 1 }),
  postalCode: chance.zip(),
  state: chance.state({ full: true }),
  city: chance.city(),
  addressLine1: chance.address(),
  addressLine2: '',
});
