import angular from 'angular';

import subscriptionService from '@client/src/global/services/subscription';

import { BecomeEnterpriseCardComponent } from './become-enterprise-card/become-enterprise-card.component';
import { WelcomeEnterprisePlanModalComponent } from './modal/modal.component';

const MODULE_NAME = 'app.global.components.welcome-enterprise-plan';

angular
  .module(MODULE_NAME, [subscriptionService])
  .component('esBecomeEnterpriseCard', BecomeEnterpriseCardComponent)
  .component('esWelcomeEnterprisePlanModal', WelcomeEnterprisePlanModalComponent);

export default MODULE_NAME;
