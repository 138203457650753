import { CurrentSubscription } from '@client/core/corelogic/ports/subscription.interface';
import { SubscriptionStates } from '@client/data/subscription';
import { ISubscriptionObject } from 'typings/subscription';

export default class CurrentSubscriptionMapper {
  fromAPI(subscription: ISubscriptionObject): CurrentSubscription {
    return {
      id: subscription.id ?? '',
      paymentSourceId: subscription.payment_source_id ?? '',
      state: this.getSubscriptionState(subscription.subscription_state ?? ''),
      plan: {
        id: subscription.plan?.id,
        name: subscription.plan?.name ?? '',
        slug: subscription.plan?.slug ?? '',
      },
      trialEndAt: subscription.trial_end_at ?? '',
      allFeatures: subscription.all_features,
    };
  }

  private getSubscriptionState(state: string): SubscriptionStates {
    if (this.isSubscriptionState(state)) {
      return state;
    }
    return SubscriptionStates.Pending;
  }

  private isSubscriptionState(state: string): state is SubscriptionStates {
    return state in SubscriptionStates;
  }
}
