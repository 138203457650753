import React from 'react';
import { AlphaHero } from './AlphaHero';
import { BetaHero } from './BetaHero';
import { GammaHero } from './GammaHero';
import { DeltaHero } from './DeltaHero';
import useNsofAbTestVariation, {
  NsofAbTestVariation,
  NsofAbTestVariationEnum,
} from '../../hooks/useNsofAbTestVariation';

const AbTestHero: Record<NsofAbTestVariation, React.FC> = {
  [NsofAbTestVariationEnum.enum.alpha]: AlphaHero,
  [NsofAbTestVariationEnum.enum.beta]: BetaHero,
  [NsofAbTestVariationEnum.enum.gamma]: GammaHero,
  [NsofAbTestVariationEnum.enum.delta]: DeltaHero,
  [NsofAbTestVariationEnum.enum.single]: AlphaHero,
  [NsofAbTestVariationEnum.enum.multi]: AlphaHero,
};

export function AuthLandingHero() {
  const { variation } = useNsofAbTestVariation();

  const AbTestHeroComponent = AbTestHero[variation];
  return <AbTestHeroComponent />;
}
