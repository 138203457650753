'use strict';
import template from './tools.html?raw';

angular.module('easyshipDashboardApp').config([
  '$stateProvider',
  '$urlRouterProvider',
  ($stateProvider, $urlRouterProvider) => {
    $stateProvider.state('app.tools', {
      abstract: true,
      url: '^/tools',
      template,
    });
  },
]);
