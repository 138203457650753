import React, { useEffect } from 'react';
import CountryFlag from '@client/core/components/react/CountryFlag';
import AlertIcon from '@client/src/create-shipments/shipment-details-drawer/ShipmentDetailsDrawerToolbar/Tabs/components/TabLabelTitle/AlertIcon';
import { FormattedMessage } from 'react-intl';
import TruncateText, {
  notifyListener,
} from '@client/core/components/react/TruncateText/TruncateText';
import { useShipmentReceiverAlert } from '@client/src/manage-shipments/hooks/useShipmentAlert';
import CellLayout from './CellLayout';
import { CellProps } from './d';
import { useVisibleColumns } from '../ShipmentsTableColumnSettings/useVisibleColumns';

export function ReceiverCell({ row }: CellProps) {
  const shipment = row.original;

  const isDestinationCountryUS = shipment.destination_country.alpha2 === 'US';
  const destinationDetail = isDestinationCountryUS
    ? `${shipment.state_abbr} ${shipment.postal_code}`
    : shipment.destination_country.name;

  const isValidCountry = !!destinationDetail?.trim()?.length;

  const { hasWarning, hasError } = useShipmentReceiverAlert(shipment);
  const { visibleColumnsMap } = useVisibleColumns();

  useEffect(() => {
    notifyListener();
  }, [visibleColumnsMap]);

  return (
    <CellLayout className="flex min-w-[152px] gap-2 px-2 items-center w-full flex-row">
      {isValidCountry && (
        <CountryFlag
          alpha2={shipment.destination_country.alpha2}
          className="min-w-[20px] !bg-cover"
        />
      )}
      <div className="flex-1 flex flex-col gap-1 text-ellipsis overflow-hidden">
        {shipment?.destination_name?.length ? (
          <div className="flex flex-row justify-start items-center w-full gap-1">
            <TruncateText label={shipment.destination_name} className="text-ink-900" />
            {(hasError || hasWarning) && <AlertIcon severity={hasError ? 'error' : 'warning'} />}
          </div>
        ) : (
          <div className="flex flex-row justify-start items-center gap-1">
            <AlertIcon severity="error" />
            <span className="text-red-700 text-[12px]">
              <FormattedMessage id="shipments.table.columns.receiver_column.no-receive" />
            </span>
          </div>
        )}
        {isValidCountry ? (
          <TruncateText
            label={destinationDetail}
            className="font-normal leading-5 text-ink-900 whitespace-normal line-clamp-2"
          />
        ) : (
          <div className="flex flex-row justify-start items-center gap-1">
            <AlertIcon severity="error" />
            <span className="text-red-700 text-[12px]">
              <FormattedMessage id="shipments.table.columns.receiver_column.no-country" />
            </span>
          </div>
        )}
      </div>
    </CellLayout>
  );
}
