import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export function CheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none">
      <g id="Icon/check">
        <path
          id="icon shape"
          d="M3.80001 9.45011C3.62836 9.44977 3.45837 9.48374 3.30004 9.55002C3.1417 9.61631 2.99822 9.71358 2.87801 9.83611C2.63924 10.0793 2.50266 10.4046 2.49634 10.7453C2.49002 11.0861 2.61443 11.4163 2.84401 11.6681C2.85501 11.6791 2.86601 11.6921 2.87801 11.7031L6.47801 15.3451C6.60156 15.472 6.74928 15.5728 6.91244 15.6417C7.0756 15.7106 7.25091 15.746 7.42801 15.746C7.60511 15.746 7.78041 15.7106 7.94357 15.6417C8.10674 15.5728 8.25446 15.472 8.37801 15.3451L17.112 6.50111C17.3508 6.25796 17.4874 5.9326 17.4937 5.59188C17.5 5.25115 17.3756 4.92095 17.146 4.66911C17.135 4.65811 17.124 4.64511 17.112 4.63411C16.9916 4.51184 16.8481 4.41474 16.6898 4.34846C16.5315 4.28218 16.3616 4.24805 16.19 4.24805C16.0184 4.24805 15.8485 4.28218 15.6902 4.34846C15.5319 4.41474 15.3884 4.51184 15.268 4.63411L7.42901 12.5821L4.72901 9.83811C4.60815 9.71437 4.4636 9.61624 4.30399 9.54958C4.14438 9.48292 3.97298 9.44909 3.80001 9.45011Z"
          fill="#50D4A4"
        />
      </g>
    </SvgIcon>
  );
}
