import { useQuery } from '@tanstack/react-query';
import { useGateways } from '@client/src/global/context/GatewayProvider';

export default function useStoresQuery() {
  const { storeGateway } = useGateways();

  return useQuery({
    queryKey: ['user_service_connect_stores'],
    queryFn: async () => storeGateway.getStores(),
    cacheTime: Infinity,
    staleTime: 60 * 1000,
  });
}
