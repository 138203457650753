import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function CellLayout({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        'flex flex-grow h-full hover:bg-teal-200 cursor-pointer w-fit min-w-full',
        className
      )}
    >
      {children}
    </div>
  );
}
