import { Checkbox } from 'easyship-components';
import React, { memo, useRef } from 'react';
import { twJoin } from 'tailwind-merge';
import ShipmentsTableHeaderCell from './ShipmentsTableHeaderCell';
import { ShipmentsTableSelectAllProps } from './d';
import RoundedCorner from './RoundedCorner';

const ShipmentsTableSelectAll = memo(
  ({ selectedCount, totalCount, onCheckboxChanged }: ShipmentsTableSelectAllProps) => {
    const checkboxRef = useRef<HTMLInputElement>(null);
    const isChecked = totalCount > 0 && selectedCount === totalCount;
    const isIndeterminate = selectedCount > 0 && selectedCount !== totalCount;

    return (
      <div
        data-test-id="shipments-table-select-all"
        className="flex items-center  rounded bg-white h-[40px] pr-[10px] mb-2"
      >
        <ShipmentsTableHeaderCell className="flex-grow-0 min-w-[42px] w-[42px] hover:bg-[#d2f1f2] cursor-pointer sticky left-0 pl-[10px] group">
          <RoundedCorner classNameInner="group-hover:bg-[#d2f1f2]" />
          <RoundedCorner position="bottom-left" classNameInner="group-hover:bg-[#d2f1f2]" />
          <Checkbox
            ref={checkboxRef}
            className={twJoin(
              '!outline-none cursor-pointer !m-0 hover:bg-transparent after:bg-white',
              (isChecked || isIndeterminate) && 'before:bg-[#36a3ac] before:border-none'
            )}
            checked={isChecked}
            indeterminate={isIndeterminate}
            onChange={onCheckboxChanged}
          />
        </ShipmentsTableHeaderCell>
        <div className="flex items-center justify-start sticky left-[42px]">
          <div className="px-2">
            <strong>
              {selectedCount}/{totalCount}
            </strong>{' '}
            shipments selected.
          </div>
          <button
            type="button"
            className=" text-blue-500"
            onClick={() => checkboxRef.current?.click()}
          >
            {isChecked ? 'Clear selection' : `Select all ${totalCount} shipments`}
          </button>
        </div>
      </div>
    );
  }
);

export default ShipmentsTableSelectAll;
