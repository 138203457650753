// import adjustmentsIcon from '@client/src/help-guide/help-guide-topic/assets/adjustments.svg?svgo';
import boxesIcon from '@client/src/help-guide/help-guide-topic/assets/boxes.svg?svgo';
// import brandedTrackingIcon from '@client/src/help-guide/help-guide-topic/assets/branded-tracking.svg?svgo';
import checkoutIcon from '@client/src/help-guide/help-guide-topic/assets/checkout.svg?svgo';
import createShipmentsIcon from '@client/src/help-guide/help-guide-topic/assets/create-shipments.svg?svgo';
import insuranceIcon from '@client/src/help-guide/help-guide-topic/assets/insurance.svg?svgo';
import lyocIcon from '@client/src/help-guide/help-guide-topic/assets/lyoc.svg?svgo';
import manageShipmentsIcon from '@client/src/help-guide/help-guide-topic/assets/manage-shipments.svg?svgo';
import notificationsIcon from '@client/src/help-guide/help-guide-topic/assets/notifications.svg?svgo';
import packingSlipIcon from '@client/src/help-guide/help-guide-topic/assets/packing-slip.svg?svgo';
import pickupsIcon from '@client/src/help-guide/help-guide-topic/assets/pickups.svg?svgo';
import printingSettingsIcon from '@client/src/help-guide/help-guide-topic/assets/printing-settings.svg?svgo';
import productsIcon from '@client/src/help-guide/help-guide-topic/assets/products.svg?svgo';
import shippingRulesIcon from '@client/src/help-guide/help-guide-topic/assets/shipping-rules.svg?svgo';
import storeIcon from '@client/src/help-guide/help-guide-topic/assets/store.svg?svgo';
import trackingIcon from '@client/src/help-guide/help-guide-topic/assets/tracking.svg?svgo';
import { IUserRoleAndPermission } from 'typings/user-session';

export enum Criteria {
  eFulfillmentOnly = 'eFulfillmentOnly',
}

interface WalkThrough {
  state: string;
  params?: Record<string, string>;
}

export interface HelpGuideTopic {
  key: string;
  walkthrough: WalkThrough;
  tutorial?: string;
  icon: string;
  requiredUserRole?: IUserRoleAndPermission;
  criteria?: Criteria;
}

type HelpGuideContent = Record<string, HelpGuideTopic[]>;
export const HELP_GUIDE_CONTENT: HelpGuideContent = {
  gettingStarted: [
    // {
    //   key: 'tour',
    //   walkthrough: {
    //     state: 'app.multiple',
    //     params: {
    //       tour: 'basic',
    //     },
    //   },
    //   // tutorial: '/hc/en-us/articles/360038954532-How-Does-Easyship-Work',
    //   icon: tourIcon,
    // },
    {
      key: 'store',
      walkthrough: {
        state: 'app.connect',
        params: {
          sidebaropen: 'connect',
          appcue: 'acee064c-384b-4650-a6c8-a6122d83a4bc',
        },
      },
      // tutorial: '/hc/en-us/articles/360039354811-How-to-Connect-an-eCommerce-Store',
      icon: storeIcon,
      requiredUserRole: 'integrations',
    },
    {
      key: 'create-shipments',
      walkthrough: {
        state: 'app.multiple',
        params: {
          createtour: 'true',
        },
      },
      // tutorial: '/hc/en-us/articles/360040359911-How-to-Create-Shipments-Basic-Option',
      icon: createShipmentsIcon,
      requiredUserRole: 'create_shipments',
    },
    {
      key: 'manage-shipments',
      walkthrough: {
        state: 'app.shipments',
        params: {
          managetour: 'continue',
        },
      },
      // tutorial: '/hc/en-us/articles/360045120291-Manage-Shipments-Video-Guide',
      icon: manageShipmentsIcon,
      requiredUserRole: 'manage_shipments',
    },
    // {
    //   key: 'adjustments',
    //   walkthrough: {
    //     state: 'app.help-guide',
    //     params: {
    //       appcue: '2c93ec67-0497-478b-abfc-3a058a64aa9a',
    //     },
    //   },
    //   // tutorial: '/hc/en-us/articles/360039684252-Shipping-Charge-Adjustment-Weight-Size',
    //   icon: adjustmentsIcon,
    // },
    {
      key: 'pickups',
      walkthrough: {
        state: 'app.pickups',
        params: {
          appcue: '8936bc43-014f-45b1-bd25-d73e0f933661',
        },
      },
      // tutorial: '/hc/en-us/articles/360047834112-Pickups-Page',
      icon: pickupsIcon,
      criteria: Criteria.eFulfillmentOnly,
      requiredUserRole: 'manage_shipments',
    },
    {
      key: 'lyoc',
      walkthrough: {
        state: 'app.couriers',
        params: {
          appcue: 'dad3df15-dbe0-4133-b470-a607ffecda40',
        },
      },
      tutorial: '',
      icon: lyocIcon,
      requiredUserRole: 'account_and_settings',
    },
    {
      key: 'tracking',
      walkthrough: {
        state: 'app.shipments',
        params: {
          page: '1',
          sidebaropen: 'settings',
          trackingtour: 'true',
        },
      },
      // tutorial: '/hc/en-us/articles/360044679592-Branding-and-Tracking-Video-Guide',
      icon: trackingIcon,
      requiredUserRole: 'manage_shipments',
    },
  ],
  levelingUp: [
    {
      key: 'products',
      walkthrough: {
        state: 'app.product-listing',
        params: {
          productshelp: 'true',
        },
      },
      // tutorial: '/hc/en-us/articles/360039354631-Adding-and-Editing-Products',
      icon: productsIcon,
      requiredUserRole: 'product_listing',
    },
    {
      key: 'shipping-rules',
      walkthrough: {
        state: 'automations',
        params: {
          appcue: '5968802d-759b-43df-95e3-53941ff9dd57',
          sidebaropen: 'settings',
        },
      },
      // tutorial: '/hc/en-us/articles/115003580152-Automate-Shipping-Process-Shipping-Rules',
      icon: shippingRulesIcon,
      requiredUserRole: 'shipping_rules',
    },
    {
      key: 'insurance',
      walkthrough: {
        state: 'insurance',
        params: {
          appcue: '303926ce-b2e1-491a-8888-3c3af91ff26f',
        },
      },
      // tutorial: '/hc/en-us/articles/115003451011-Add-Shipping-Insurance',
      icon: insuranceIcon,
      requiredUserRole: 'account_and_settings',
    },
    {
      key: 'checkout',
      walkthrough: {
        state: 'app.help-guide',
        params: {
          checkouthelp: 'true',
        },
      },
      // tutorial: '/hc/en-us/articles/115003453151-Shipping-Rates-at-Checkout-Feature',
      icon: checkoutIcon,
      requiredUserRole: 'integrations',
    },
    {
      key: 'boxes',
      walkthrough: {
        state: 'boxes',
        params: {
          appcue: 'bdddd593-c6df-41d6-a2b5-26c4b03d26f6',
        },
      },
      // tutorial: '/hc/en-us/articles/360025430011-Saving-Shipping-Boxes-Dimensions',
      icon: boxesIcon,
      requiredUserRole: 'account_and_settings',
    },
  ],
  customization: [
    {
      key: 'printing-settings',
      walkthrough: {
        state: 'app.shipments',
        params: {
          page: '1',
          sidebaropen: 'settings',
          appcue: '79bc15d3-7391-4b13-b696-c7ce0b61d297',
        },
      },
      // tutorial: '/hc/en-us/articles/360039354491-Download-and-Print-Shipping-Documents',
      icon: printingSettingsIcon,
      requiredUserRole: 'account_and_settings',
    },
    // {
    //   key: 'branded-tracking',
    //   walkthrough: {
    //     state: 'app.multiple',
    //     params: {
    //       brandingtour: 'true',
    //       sidebaropen: 'settings',
    //     },
    //   },
    //   // tutorial: '/hc/en-us/articles/360018533711-Customize-Branded-Tracking-Page',
    //   icon: brandedTrackingIcon,
    // },
    {
      key: 'packing-slip',
      walkthrough: {
        state: 'packing-slip',
        params: {
          appcue: 'fec8f32b-dee5-4965-9d71-42d5ac71dc8d',
        },
      },
      // tutorial: '/hc/en-us/articles/360039946152-Packing-Slips-Customization-Examples',
      icon: packingSlipIcon,
      requiredUserRole: 'account_and_settings',
    },
    {
      key: 'notifications',
      walkthrough: {
        state: 'notifications',
        params: {
          appcue: '86b60a5c-0caf-44b4-90d2-3eebdda13690',
        },
      },
      // tutorial: '/hc/en-us/articles/360039959152-How-to-Turn-Off-or-On-Notifications',
      icon: notificationsIcon,
      requiredUserRole: 'account_and_settings',
    },
  ],
};
