import { Box } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { twMerge } from 'tailwind-merge';

export interface ShipmentsTableRowCellProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (e: MouseEvent | null, index: number) => void;
  rowIndex?: number;
  style?: CSSProperties;
}

export default function ShipmentsTableRowCell({
  children,
  className,
  onClick,
  rowIndex,
  style,
}: ShipmentsTableRowCellProps) {
  return (
    <Box
      onClick={() => rowIndex !== undefined && onClick?.(null, rowIndex)}
      className={twMerge(
        'h-full flex flex-grow flex-shrink-0 items-center text-base font-bold min-w-[100px]',
        className
      )}
      style={style}
    >
      {children}
    </Box>
  );
}
