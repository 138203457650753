import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';
import OutlinedInput, { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Tooltip from '@client/core/components/react/Tooltip';
import FormLabel, { FormLabelProps } from './FormLabel';
import ProgressIndicator, { ProgressIndicatorProps } from './ProgressIndicator';

export interface InputProps
  extends OutlinedInputProps,
    Pick<FormLabelProps, 'flag' | 'helperIcon'> {
  label?: React.ReactNode;
  helperText?: string;
  maxLength?: number;
  loading?: boolean;
  warning?: boolean;
  progressIndicatorProps?: ProgressIndicatorProps;
  prependIconImg?: string;
  prependTooltip?: React.ReactNode;
  prependIcon?: string;
  tooltipWidth?: string;
  learnMoreLink?: string;
}

function Input(
  {
    label,
    helperText,
    flag,
    helperIcon,
    loading,
    warning,
    progressIndicatorProps,
    prependIconImg,
    prependTooltip,
    tooltipWidth,
    prependIcon,
    learnMoreLink,
    onKeyDown,
    ...props
  }: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const showCounter =
    typeof props.value === 'string' &&
    props.maxLength &&
    props.value.length > props.maxLength &&
    props.error;

  const inputStyle = {
    ...(warning ? { backgroundColor: theme.palette.yellow.light, ...props.style } : props.style),
    ...(prependTooltip ? { paddingLeft: '20px' } : {}),
  };

  function handleWheel(e: React.WheelEvent<HTMLInputElement>) {
    if (props.type === 'number') {
      (e.target as HTMLInputElement).blur();
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (isNavigationKey(e.key)) {
      e.stopPropagation();
    }
    onKeyDown && onKeyDown(e);
  }

  function isNavigationKey(keyName: string) {
    return (
      keyName === 'ArrowUp' ||
      keyName === 'ArrowRight' ||
      keyName === 'ArrowDown' ||
      keyName === 'ArrowLeft'
    );
  }

  const isOverflow =
    inputRef?.current && inputRef?.current.scrollWidth > inputRef?.current.clientWidth;

  return (
    <Box position="relative" clone>
      <FormLabel label={label} flag={flag} helperIcon={helperIcon}>
        <ProgressIndicator show={loading} {...progressIndicatorProps}>
          {prependTooltip && (
            <Tooltip
              style={{
                position: 'absolute',
                paddingLeft: '8px',
                top: `50%`,
                zIndex: 1,
                transform: 'translateY(-50%)',
              }}
              interactive
              tooltipWidth={tooltipWidth}
              title={
                <>
                  <span>{prependTooltip}</span>
                  &nbsp;
                  {learnMoreLink && (
                    <a href={learnMoreLink} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage id="global.learn-more" />
                    </a>
                  )}
                </>
              }
            >
              <span>
                {prependIconImg && <img src={prependIconImg} alt="" />}
                {prependIcon && <i className={prependIcon} />}
              </span>
            </Tooltip>
          )}
          <Tooltip
            title={isOverflow && props.inputProps ? props.inputProps.value : ''}
            placement="top"
          >
            <OutlinedInput
              ref={ref}
              inputRef={inputRef}
              onWheel={handleWheel}
              onKeyDown={handleKeyDown}
              style={inputStyle}
              {...props}
            />
          </Tooltip>
          {typeof props.value === 'string' && showCounter && (
            <Box position="absolute" top={-20} right={0}>
              <Typography variant="body2" style={{ color: theme.palette.red.dark }}>
                {props.value.length}/{props.maxLength}
              </Typography>
            </Box>
          )}

          {helperText && (
            <Box mt={1}>
              <Typography
                variant="body2"
                style={{
                  color: props.error ? theme.palette.red.dark : theme.palette.ink.light,
                }}
              >
                {helperText}
              </Typography>
            </Box>
          )}
        </ProgressIndicator>
      </FormLabel>
    </Box>
  );
}

export default React.forwardRef(Input);
