import { IUserSession } from 'typings/user-session';
import {
  IProductListingErrorResponse,
  BatteryType,
} from 'typings/dashboard/services/product-listing';
import { IStore } from 'typings/store';
import {
  CategorySelection,
  HsCodeSelection,
  CategoryHsCodeSelection,
} from '@client/src/global/components/category-hs-code/CategoryHsCodeInput';
import { ICountry, ICountryService } from 'typings/auth/services/country';
import { IComponentController } from 'angular';
import { MixpanelService } from '@client/core/services/mixpanel/mixpanel.service';
import { AppCuesService } from '@client/core/services/app-cues/app-cues.service';
import { UserRightsService } from '@client/core/services/user-rights/user-right.service';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import style from './add-products-modal-manual-input.module.scss';
import template from './add-products-modal-manual-input.html?raw';
import { ProductListingService } from '../../product-listing.service';

interface IDimensions {
  length: number | null;
  width: number | null;
  height: number | null;
}

interface IFormData {
  identifier: string;
  name: string;
  category_id: number | null;
  hs_code: string | null;
  origin_country_id: string;
  dimensions: IDimensions;
  weight: number | null;
  pick_location: string;
  contains_liquids?: boolean;
  contains_battery_pi966?: boolean;
  contains_battery_pi967?: boolean;
}

const PACKED_BATTERY = 'packed_battery';
const CONTAINED_BATTERY = 'contained_battery';

class AddProductsModalManualInput implements IComponentController {
  style = style;
  esStore: IStore | null = null;
  loading = false;
  countries: ICountry[] = [];
  categoryHsCodeSelectedValue: CategoryHsCodeSelection = null;
  dimensions: IDimensions = {
    length: null,
    width: null,
    height: null,
  };
  formData: IFormData = {
    identifier: '',
    name: '',
    category_id: null,
    hs_code: null,
    origin_country_id: '',
    dimensions: {
      length: null,
      width: null,
      height: null,
    },
    weight: null,
    pick_location: '',
  };
  showBatteryTypeSelect = false;
  batteryTypes = [
    { display: 'Ion Battery Packed with Equipment (UN3481-PI966)', value: PACKED_BATTERY },
    { display: 'Ion Battery Contained in Equipment (UN3481-PI967)', value: CONTAINED_BATTERY },
  ];
  batteryType: BatteryType | null = null;

  static $inject = [
    'UserSession',
    'ProductListingService',
    'CountryService',
    'MixpanelService',
    'AppCuesService',
    'UserRightsService',
    '$translate',
  ];
  constructor(
    private UserSession: IUserSession,
    private ProductListingService: ProductListingService,
    private CountryService: ICountryService,
    private MixpanelService: MixpanelService,
    private AppCuesService: AppCuesService,
    private UserRightsService: UserRightsService,
    private $translate: angular.translate.ITranslateService
  ) {}
  $onInit(): void {
    this.CountryService.getCountries().then(({ countries }) => {
      this.countries = countries;
    });
  }

  get companyWeightUnit(): string {
    return this.UserSession.getCompanyWeightUnit();
  }

  get companyDimensionsUnit(): string {
    return this.UserSession.getCompanyDimensionsUnit();
  }

  get canCreateProduct(): boolean {
    return this.UserRightsService.canCreateProduct;
  }

  onFieldChange(value: string | number | IDimensions, field: keyof IFormData): void {
    (this.formData[field] as string | number | IDimensions) = value;

    if (field === 'dimensions' && typeof value !== 'string' && typeof value !== 'number') {
      this.dimensions = value;
    }
  }

  onCategorySelect(value: CategorySelection): void {
    this.categoryHsCodeSelectedValue = value;
    this.showBatteryTypeSelect = false;
    this.batteryType = null;
  }

  onHsCodeSelect(value: HsCodeSelection): void {
    this.categoryHsCodeSelectedValue = value;

    this.formData = {
      ...this.formData,
      contains_liquids: false,
      contains_battery_pi966: false,
      contains_battery_pi967: false,
    };
  }

  onContainsLiquidsChange(): void {
    this.formData.contains_liquids = !this.formData.contains_liquids;
  }

  onContainsBatteriesChange(): void {
    this.showBatteryTypeSelect = !this.showBatteryTypeSelect;
    if (!this.showBatteryTypeSelect) {
      this.batteryType = null;
      this.formData = {
        ...this.formData,
        contains_battery_pi966: false,
        contains_battery_pi967: false,
      };
    }
  }

  onBatteryTypeChange(value: BatteryType): void {
    this.batteryType = value;
  }

  onSubmit(form: ng.IFormController): void {
    if (form.$invalid) {
      toastError(this.$translate.instant('product-listing.warnings.incomplete-product'));
      return;
    }

    this.loading = true;

    const categoryId =
      this.categoryHsCodeSelectedValue && 'categoryId' in this.categoryHsCodeSelectedValue
        ? this.categoryHsCodeSelectedValue.categoryId
        : null;

    const hsCode =
      this.categoryHsCodeSelectedValue && 'hsCodeNumber' in this.categoryHsCodeSelectedValue
        ? this.categoryHsCodeSelectedValue.hsCodeNumber
        : null;

    if (this.batteryType) {
      this.batteryType === PACKED_BATTERY
        ? (this.formData.contains_battery_pi966 = true)
        : (this.formData.contains_battery_pi967 = true);
    }

    const payload = {
      identifier: this.formData.identifier,
      name: this.formData.name,
      category_id: categoryId,
      hs_code: hsCode,
      origin_country_id: this.formData.origin_country_id
        ? Number(this.formData.origin_country_id)
        : null,
      length: this.formData.dimensions.length as number,
      width: this.formData.dimensions.width as number,
      height: this.formData.dimensions.height as number,
      weight: this.formData.weight as number,
      pick_location: this.formData.pick_location,
      ...(hsCode && {
        contains_liquids: this.formData.contains_liquids,
        contains_battery_pi966: this.formData.contains_battery_pi966,
        contains_battery_pi967: this.formData.contains_battery_pi967,
      }),
    };

    let productListingResource;

    if (this.esStore) {
      productListingResource = this.ProductListingService.saveStoreProduct(
        this.esStore.id,
        payload
      );
    } else {
      productListingResource = this.ProductListingService.save(payload);
    }

    productListingResource
      .then(() => {
        toastSuccess(this.$translate.instant('product-listing.warnings.create-success'));
        this.ProductListingService.hideModal();
        this.ProductListingService.getProductList().finally(() => {
          if (this.ProductListingService.products.length > 0) {
            this.AppCuesService.track('Products | Product created', null, true);
          }
        });

        this.MixpanelService.track('Products - Create', {
          hs_code: hsCode,
          category_id: categoryId,
          origin_country_id: this.formData.origin_country_id,
          contains_liquids: this.formData.contains_liquids,
          contains_battery_pi966: this.formData.contains_battery_pi966,
          contains_battery_pi967: this.formData.contains_battery_pi967,
        });
      })
      .catch((errorResponse: IProductListingErrorResponse) => {
        try {
          if (errorResponse && errorResponse.data) {
            const errorMessage = errorResponse.data.reduce(
              (accumulator, error) => `${accumulator}<li>${error}</li>`,
              ''
            );
            toastError(`<ul>${errorMessage}</ul>`);
          }
        } catch {
          toastError(this.$translate.instant('toast.default-error'));
        }

        this.loading = false;
      });
  }

  esOnBack(): void {
    // esBack expression bindings, need to add this in order for typescript to successfully compile
  }
}

const AddProductsModalManualInputComponent: ng.IComponentOptions = {
  controller: AddProductsModalManualInput,
  template,
  bindings: {
    esStore: '<',
    esOnBack: '&',
  },
};

export { AddProductsModalManualInputComponent };
