import { CouriersHorizontalIcon } from '@client/src/components/icons/CouriersHorizontalIcon';
import { CouriersIcon } from '@client/src/components/icons/CouriersIcon';
import { RevenueIcon } from '@client/src/components/icons/RevenueIcon';
import { RocketIcon } from '@client/src/components/icons/RocketIcon';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export function BetaHero() {
  const theme = useTheme();
  const isScreenSm = useMediaQuery(theme.breakpoints.down('sm'));

  const couriersIcon = useMemo(() => {
    if (isScreenSm) {
      return <CouriersHorizontalIcon style={{ width: '100%', height: 'auto' }} />;
    }
    return <CouriersIcon style={{ width: '100%', height: 'auto' }} />;
  }, [isScreenSm]);

  return (
    <div className="flex flex-col h-full justify-center sm:max-xl:px-5 xl:px-[140px] px-5">
      <div className="grid grid-cols-4 gap-x-6 sm:py-[52px] py-3 justify-center items-center w-full border-b-2 border-teal-500">
        <RevenueIcon style={{ width: '100%', height: 'auto' }} />
        <div className="col-span-3">
          <h3 className="font-bold text-ink-900 leading-normal sm:mb-4 sm:text-2xl text-lg">
            <FormattedMessage id="auth-landing.beta.title-rates" />
          </h3>
          <p className="hidden-sm hidden-xs leading-normal">
            <FormattedMessage id="auth-landing.beta.description-rates" />
          </p>
        </div>
      </div>

      <div className="grid sm:grid-cols-3 grid-cols-2 gap-x-6 sm:py-[52px] py-3 items-center w-full border-b-2 border-teal-500">
        <div className="sm:col-span-2">
          <h3 className="font-bold text-ink-900 leading-normal sm:mb-4 sm:text-2xl text-lg">
            <FormattedMessage id="auth-landing.beta.title-couriers" />
          </h3>
          <p className="hidden-xs hidden-sm leading-normal">
            <FormattedMessage id="auth-landing.beta.description-couriers" />
          </p>
        </div>
        {couriersIcon}
      </div>

      <div className="grid grid-cols-4 gap-x-6 sm:py-[52px] py-3 justify-center items-center w-full">
        <RocketIcon style={{ width: '100%', height: 'auto' }} />
        <div className="col-span-3">
          <h3 className="sm:text-2xl text-lg font-bold text-ink-900 leading-normal sm:mb-4">
            <FormattedMessage id="auth-landing.beta.title-easy" />
          </h3>
          <p className="hidden-sm hidden-xs leading-normal">
            <FormattedMessage id="auth-landing.beta.description-easy" />
          </p>
        </div>
      </div>
    </div>
  );
}
