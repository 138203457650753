import axios from 'axios';
import Cookies from 'universal-cookie';

import { API } from '@client/core/config/api.constant';

const cookies = new Cookies();

axios.interceptors.request.use(
  (config) => {
    // Do not inject header authorization if URL request is not ES API or /login
    if (!config.url?.includes(API.endpoint) || config.url.includes('login')) {
      return config;
    }

    const credentials = cookies.get(API.credentials);

    // eslint-disable-next-line no-param-reassign
    config.headers['X-Easyship-Client-Name'] = 'Dashboard';

    // ES API authorization
    if (credentials) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${credentials}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);
