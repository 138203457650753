import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Tooltip from '@client/core/components/react/Tooltip';

import { formatHsCodeNumber, SuggestedHsCodes } from '@client/core/corelogic/models/HsCode';
import { useItemCategoriesQuery } from '@client/core/corelogic/useItemCategoriesQuery';
import HsCodePopper from './HsCodePopper';
import ReactRootProviders from '../../context/ReactRootProviders';

export interface CategorySelection {
  categoryId: number;
}

export interface HsCodeSelection {
  hsCodeNumber: string;
  hsCodeDescription: string;
  containsLiquids?: boolean;
}

export type CategoryHsCodeSelection = CategorySelection | HsCodeSelection | null;

interface CategoryHsCodeInputProps {
  value: CategoryHsCodeSelection;
  additionalSuggestedHsCodes?: SuggestedHsCodes;
  onCategorySelect: (categoryId: number) => void;
  onHsCodeSelect: (hsCodeNumber: string, hsCodeDescription: string) => void;
  className?: string | undefined;
  error?: boolean;
  color?: string;
  hsError?: boolean;
}

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.sky.dark}`,
    cursor: 'pointer',

    '&:focus': {
      borderColor: `${theme.palette.ink.light} !important`,
      outline: 'none',
    },
  },
  inputError: {
    backgroundColor: theme.palette.red.light,
    borderColor: theme.palette.red.dark,

    '&:focus': {
      borderColor: `${theme.palette.red.dark} !important`,
    },
  },
  tooltip: {
    width: 213,
  },
}));

function CategoryHsCodeInput({
  className,
  value,
  error,
  hsError,
  ...popupProps
}: CategoryHsCodeInputProps) {
  const theme = useTheme();
  const classes = useStyles();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const { data: categories = [] } = useItemCategoriesQuery();

  useEffect(() => {
    let displayValue = '';
    const hasHsCodeNumber = value && 'hsCodeNumber' in value && value.hsCodeNumber;
    const hasHsCodeDescription = value && 'hsCodeDescription' in value && value.hsCodeDescription;

    if (hasHsCodeNumber && hasHsCodeDescription) {
      displayValue += `${formatHsCodeNumber(value.hsCodeNumber)} - ${value.hsCodeDescription}`;
    } else if (value && 'categoryId' in value) {
      displayValue =
        categories.find((category) => category.id === value.categoryId)?.fallbackName || '';
    }

    setDisplayValue(displayValue);
  }, [categories, value]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      event.preventDefault();
      setOpenPopup(true);
    }
  };

  const handleClosePopup = () => {
    /**
     * Add timeout to ensure that the popper still exists when
     * checking the Classic View - Shipment Edit off click
     */
    setTimeout(() => {
      setOpenPopup(false);
      anchorRef.current?.focus();
    });
  };

  const tooltipTitle = !openPopup && value && 'hsCodeDescription' in value ? displayValue : '';

  const isError = error || hsError;

  return (
    <>
      <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltipTitle}>
        <Box
          {...{ ref: anchorRef }}
          // Add 'Mui-error' in order to support the CSS logic of shipment panel view
          className={`
            ${classes.input}
            ${className}
            ${isError ? `${classes.inputError} Mui-error` : ''}
          `}
          width="100%"
          height={40}
          position="relative"
          display="flex"
          alignItems="center"
          padding="10px 25px 10px 10px"
          borderRadius={4}
          tabIndex={0}
          onClick={() => setOpenPopup(true)}
          onKeyDown={handleKeyDown}
        >
          <Typography variant="body2" className="truncate">
            {displayValue || (
              <span style={{ color: theme.palette.ink.light }}>
                <FormattedMessage id="global.choose-one" />
              </span>
            )}
          </Typography>

          {isError && (
            <Box
              position="absolute"
              right={10}
              fontSize={13}
              color={`${theme.palette.red.dark} !important`}
            />
          )}

          <Box
            position="absolute"
            right={hsError ? 30 : 10}
            fontSize={13}
            color={`${theme.palette.ink.main} !important`}
            className="icon-chevron-down"
          />
        </Box>
      </Tooltip>
      <HsCodePopper
        {...popupProps}
        anchorEl={anchorRef.current}
        open={openPopup}
        value={value}
        categories={categories}
        closePopup={handleClosePopup}
      />
    </>
  );
}

export default function WrappedCategoryHsCodeInput(props: CategoryHsCodeInputProps) {
  return (
    <ReactRootProviders>
      <CategoryHsCodeInput {...props} />
    </ReactRootProviders>
  );
}
