import { z } from 'zod';

export const NsofAbTestVariationEnum = z.enum([
  'alpha',
  'beta',
  'gamma',
  'delta',
  'single',
  'multi',
]);
export type NsofAbTestVariation = z.infer<typeof NsofAbTestVariationEnum>;

export default function useNsofAbTestVariation() {
  let variation: NsofAbTestVariation = NsofAbTestVariationEnum.enum.alpha;

  const urlParams = new URLSearchParams(window.location.search);
  const nsofAbTestVariation = NsofAbTestVariationEnum.safeParse(urlParams.get('variation'));

  if (nsofAbTestVariation.success) {
    variation = nsofAbTestVariation.data;
  }

  return { variation };
}
