import angular, { IComponentController } from 'angular';
import { react2angular } from 'react2angular';

import CategoryHsCodeInput, { CategorySelection, HsCodeSelection } from './CategoryHsCodeInput';
import CategoryHsCodeLabel from './CategoryHsCodeLabel';

class AngularCategoryHsCodeInput implements IComponentController {
  error = false;
  reactError = false;
  required = false;

  // @ts-ignore strictPropertyInitialization
  $ngModelController: ng.INgModelController;
  onCategorySelect = (changes: { value: CategorySelection }) => {};
  onHsCodeSelect = (changes: { value: HsCodeSelection }) => {};

  static $inject = ['$scope'];
  constructor(private $scope: ng.IScope) {
    this.handleCategorySelect = this.handleCategorySelect.bind(this);
    this.handleHsCodeSelect = this.handleHsCodeSelect.bind(this);
  }

  // $onInit and $doCheck are not performing any validation as this is done check Asana task https://app.asana.com/0/1202545069008234/1202572045985865
  $onInit() {
    if (this.error) {
      this.$ngModelController.$validators.isValid = () => false;
      this.$ngModelController.$setDirty();
      this.reactError = true;
    }
  }

  $doCheck() {
    if (this.required) {
      // Inform the parent form about the validity of this component
      this.$ngModelController.$validators.isValid = (modelValue) => {
        return !!(modelValue?.categoryId || modelValue?.hsCodeDescription);
      };

      this.reactError = this.$ngModelController.$dirty && this.$ngModelController.$invalid;
    }
  }

  /**
   * Define its own callback binding in order to support invokable expression.
   * Need this to support the components built in Angular.js
   * eg: esItemList, esProductListingEditProductsModalBulkEdit, etc
   */
  handleCategorySelect(categoryId: number) {
    this.onCategorySelect({ value: { categoryId } });
    this.$scope.$apply();
  }

  handleHsCodeSelect(hsCodeNumber: string, hsCodeDescription: string) {
    this.onHsCodeSelect({ value: { hsCodeNumber, hsCodeDescription } });
    this.$scope.$apply();
  }
}

const AngularCategoryHsCodeInputComponent = {
  controller: AngularCategoryHsCodeInput,
  template: `
    <r2a-category-hs-code-input
      value="$ctrl.ngModel"
      additional-suggested-hs-codes="$ctrl.additionalSuggestedHsCodes"
      on-category-select="$ctrl.handleCategorySelect"
      on-hs-code-select="$ctrl.handleHsCodeSelect"
      error="$ctrl.reactError"
      hs-error="$ctrl.hsError"
    ></r2a-category-hs-code-input>
  `,
  require: {
    $ngModelController: 'ngModel',
  },
  bindings: {
    ngModel: '<',
    additionalSuggestedHsCodes: '<',
    onCategorySelect: '&',
    onHsCodeSelect: '&',
    hsError: '<',
    required: '<',
    error: '<',
  },
};

const MODULE_NAME = 'app.global.components.category-hs-code';

angular
  .module(MODULE_NAME, [])
  .component(
    'r2aCategoryHsCodeInput',
    react2angular(CategoryHsCodeInput, [
      'value',
      'additionalSuggestedHsCodes',
      'onCategorySelect',
      'onHsCodeSelect',
      'error',
      'color',
      'hsError',
    ])
  )
  .component('esCategoryHsCodeInput', AngularCategoryHsCodeInputComponent)
  .component('esCategoryHsCodeLabel', react2angular(CategoryHsCodeLabel, ['color']));

export default MODULE_NAME;
