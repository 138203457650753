import Input from '@client/core/components/react/Input';
import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from '@client/core/components/react/Link';
import { toastError } from '@client/core/components/react/Toastify';
import { Email } from '@client/src/utils/email';
import { Button } from 'easyship-components';
import useLoginMutation from '../hooks/useLoginMutation';

export interface LoginFormEditData {
  email: string;
  password: string;
}
export interface LoginFormData {
  email: string;
  password: string;
}

type FormField = keyof LoginFormData;
type FieldErrors = Partial<Record<FormField, string>>;

interface LoginFormProps {
  defaultData?: Partial<LoginFormEditData>;
  recaptchaToken: string;
  onLoginError?: () => void;
}
export function LoginForm({ onLoginError, recaptchaToken, defaultData }: LoginFormProps) {
  const { formatMessage } = useIntl();
  const { mutateAsync: loginMutateAsync, isLoading } = useLoginMutation();
  const [errors, setErrors] = useState<FieldErrors>({});
  const ref = useRef<HTMLFormElement>(null);

  function validateEmail(email: string): string | null {
    if (!email) return 'Invalid email format';

    const { isValid } = new Email(email);
    if (!isValid) return 'Invalid email format';
    return null;
  }

  function validatePassword(password: string): string | null {
    if (!password) return 'Invalid password';
    return null;
  }

  function isFormValid(formData: FormData): boolean {
    const emailError = validateEmail(formData.get('email')?.toString() ?? '');
    const passwordError = validatePassword(formData.get('password')?.toString() ?? '');
    const newErrors: FieldErrors = {
      email: emailError ?? undefined,
      password: passwordError ?? undefined,
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((value) => value === undefined);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!ref.current) return;
    const formData = new FormData(ref.current);
    if (isFormValid(formData)) {
      loginMutateAsync({
        email: formData.get('email')?.toString().trim() ?? '',
        password: formData.get('password')?.toString() ?? '',
        token: recaptchaToken ?? '',
      }).catch((reason: unknown) => {
        toastError(
          typeof reason === 'string' ? reason : formatMessage({ id: 'toast.default-error' })
        );
        onLoginError?.();
      });
    } else {
      toastError('Please fill in the required fields');
    }
  }

  return (
    <form className="w-full" ref={ref} onSubmit={handleSubmit}>
      <div className="flex flex-col gap-6 mb-5">
        <Input
          name="email"
          error={Boolean(errors.email)}
          helperText={errors.email}
          label={formatMessage({ id: 'global.email' })}
          defaultValue={defaultData?.email ?? ''}
        />
        <Input
          label={formatMessage({ id: 'global.password' })}
          name="password"
          type="password"
          error={Boolean(errors.password)}
          defaultValue={defaultData?.password ?? ''}
        />
        <Button
          style={{ height: 60 }}
          type="submit"
          color="primary"
          loading={isLoading}
          className="w-full text-lg font-bold leading-6 rounded-md"
        >
          <FormattedMessage id="login.login-text" />
        </Button>
      </div>
      <Link href="/password-reset" className="text-base leading-4">
        <FormattedMessage id="login.new-forgot-password" />
      </Link>
    </form>
  );
}
