import Chance from 'chance';
import { ReceiverAddressData, ReceiverAddressEditData } from './ReceiverAddressData';
import { AddressEditMock, AddressMock } from '../Address';

const chance = new Chance();

export const receiverAddressMock = (): ReceiverAddressData => ({
  ...AddressMock(),
  receiverName: chance.name(),
  taxId: chance.string(),
  isSetAsResidentialAddress: chance.bool(),
  isIdentifiedAsResidentialAddress: 'unknown',
});

export const receiverAddressEditMock = (): ReceiverAddressEditData => ({
  ...AddressEditMock(),
  receiverName: chance.name(),
  taxId: chance.string(),
  isSetAsResidentialAddress: chance.bool(),
  isIdentifiedAsResidentialAddress: 'unknown',
});
