import { Incoterms, IShipmentListItem } from '@typings/shipment';
import {
  DiscountData,
  DomesticTaxes,
  FeeGroup,
  TranslationKey,
} from '@client/core/corelogic/models/CourierServiceRate';
import { ChargeableWeightCalculationMethod } from '@typings/rates';
import React, { CSSProperties } from 'react';
import { Row } from '@tanstack/react-table';

export interface TaxTooltipProps {
  shipment: IShipmentListItem;
  className?: string;
}

export enum ShipmentState {
  Calculating = 'calculating',
  Created = 'created',
  Cancelled = 'cancelled',
}

export interface CostTooltipData {
  isDomestic: boolean;
  incoterms: Incoterms;
  importTaxCharges: number;
  discount: DiscountData;
  feeGroups: FeeGroup[];
  domesticTaxes: DomesticTaxes;
  retailDiscount: number;
  totalCharges: number;
  chargeableWeightCalculationMethod?: ChargeableWeightCalculationMethod;
  currency: string;
  cubicTier: number;
  totalChargeableWeight: number;
  alerts: TranslationKey[];
  isEasyshipCourier: boolean;
}

export interface ShipmentsTableSelectAllProps {
  selectedCount: number;
  totalCount: number;
  onCheckboxChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface CellProps {
  row: Row<IShipmentListItem>;
}

export interface ShipmentsTableHeaderCellProps {
  className?: string;
  style?: CSSProperties;
}
