import { Button } from 'easyship-components';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from '@material-ui/core/Link';
import { IUserSession } from 'typings/user-session';
import { toastError } from '@client/core/components/react/Toastify';
import { ChevronBackward } from 'easyship-components/icons';
import MixpanelService from '@client/core/services/mixpanel/mixpanel.service';
import Cookies from 'universal-cookie';
import CredentialsForm, { CredentialsFormData } from './CredentialsForm';
import UserInformationForm, { UserInformationFormData } from './UserInformationForm';
import useSignupMutation from '../../hooks/useSignupMutation';
import { SignupFormData } from '../SignupForm.types';
import useValidateEmailMutation from '../../hooks/useValidateEmailMutation';

interface MultistepSignupFormProps {
  onContinueClicked?: () => void;
  onBackClicked?: () => void;
  currentStep: number;
  onSignupError?: () => void;
  onSignupSuccess?: (user: IUserSession) => void;
  recaptchaToken: string;
  defaultEmail: string;
}

const ACTIVE_ABTEST_KEY = 'new_signup_flow_3';
const cookies = new Cookies();
const currentAbTestVariation = cookies.get('AB_TESTS')?.new_signup_flow_3 ?? '';

function MultistepSignupForm(
  {
    onContinueClicked,
    currentStep,
    onBackClicked,
    onSignupSuccess,
    onSignupError,
    recaptchaToken,
    defaultEmail,
  }: MultistepSignupFormProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const { formatMessage } = useIntl();

  const { mutateAsync: validateEmailMutateAsync, isLoading: isValidateEmailLoading } =
    useValidateEmailMutation();

  const emailValue = useRef(defaultEmail);
  const passwordValue = useRef('');

  const userInformationFormRef = useRef<HTMLFormElement>(null);
  const userInfoRef = useRef<Partial<UserInformationFormData>>({
    firstName: '',
    lastName: '',
    companyName: '',
  });

  const { mutateAsync: signupMutateAsync, isLoading: isSignupLoading } = useSignupMutation();

  function handleNext(payload: CredentialsFormData): void {
    MixpanelService.track('Send Signup - 2 Step - 1st Step Submit Attempt', {
      ab_test_key: ACTIVE_ABTEST_KEY,
      ab_test_variation: currentAbTestVariation,
    });
    validateEmailMutateAsync({ email: payload.email.trim() })
      .then(() => {
        MixpanelService.track('Signup - 2 Step - 2nd Step Arrive', {
          ab_test_key: ACTIVE_ABTEST_KEY,
          ab_test_variation: currentAbTestVariation,
        });
        onContinueClicked?.();
        emailValue.current = payload.email;
        passwordValue.current = payload.password;
      })
      .catch((error) => {
        toastError(error.data.status);
      });
  }

  function handleBackClicked(): void {
    onBackClicked?.();
    if (userInformationFormRef.current) {
      const data = new FormData(userInformationFormRef.current).entries();
      const formData = Object.fromEntries(data);
      userInfoRef.current = {
        firstName: formData.firstName.toString() ?? '',
        countryId: parseInt(formData.countryId?.toString(), 10),
        companyName: formData.companyName.toString() ?? '',
        lastName: formData.lastName.toString() ?? '',
      };
    }
  }

  function handleSignup(payload: UserInformationFormData): void {
    MixpanelService.track('Signup - 2 Step - 2nd Step Submit Attempt', {
      ab_test_key: ACTIVE_ABTEST_KEY,
      ab_test_variation: currentAbTestVariation,
    });
    const signupPayload: SignupFormData = {
      email: emailValue.current.trim(),
      password: passwordValue.current,
      ...payload,
    };
    signupMutateAsync({
      payload: signupPayload,
      recaptchaToken,
    })
      .then((user: IUserSession) => {
        onSignupSuccess?.(user);
      })
      .catch((reason) => {
        toastError(
          typeof reason === 'string' ? reason : formatMessage({ id: 'toast.default-error' })
        );
        onSignupError?.();
      });
  }

  return (
    <>
      {currentStep === 1 ? (
        <CredentialsForm
          ref={ref}
          isLoading={isValidateEmailLoading}
          onSubmit={handleNext}
          defaultData={{ email: emailValue.current }}
        />
      ) : (
        <>
          <Button className="px-0 mb-10" flat onClick={handleBackClicked}>
            <ChevronBackward /> Back
          </Button>
          <UserInformationForm
            isLoading={isSignupLoading}
            onSubmit={handleSignup}
            defaultData={userInfoRef.current}
            ref={userInformationFormRef}
          />
        </>
      )}
      <div className="mt-5 text-base text-ink-500">
        <FormattedMessage id="signup.agreement.accept" />{' '}
        <Link href="https://www.easyship.com/legal/terms/overview" target="_blank">
          <FormattedMessage id="signup.agreement.terms" />
        </Link>{' '}
        <FormattedMessage id="global.and" />{' '}
        <Link href="https://www.easyship.com/legal/privacy" target="_blank">
          <FormattedMessage id="signup.agreement.privacy" />
        </Link>
      </div>
    </>
  );
}

export default React.forwardRef(MultistepSignupForm);
