import { lazy } from 'react';
import { ComponentDataType, openModal } from '@/utils/modals';

export const SchedulePickMultiLocationModal = lazy(
  () => import('@/pages/pickups/components/SchedulePickupMultiLocationModal')
);

export const PreviewPackingSlipModal = lazy(() => import('@/components/PreviewPackingSlipModal'));

export enum MODAL {
  SCHEDULE_PICK_UP = 'SCHEDULE_PICK_UP',
  PREVIEW_PACKiNG_SLIP = 'PREVIEW_PACKiNG_SLIP',
}

export const openModalByName = (modalName: MODAL, data?: ComponentDataType) => {
  switch (modalName) {
    case MODAL.SCHEDULE_PICK_UP:
      return openModal(SchedulePickMultiLocationModal, data);

    case MODAL.PREVIEW_PACKiNG_SLIP:
      return openModal(PreviewPackingSlipModal, data);

    default:
      /** no any */
      return null;
  }
};
