import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { LanguageOption } from '@client/auth/signup/signup.component';
import Button from '@client/core/components/react/Button';
import Menu from '@material-ui/core/Menu';
import { GlobeIcon } from '@client/src/components/icons/GlobeIcon';
import COLORS from '@client/src/colors';
import Box from '@material-ui/core/Box';
import { EasyshipLocale } from '@client/core/corelogic/models/EasyshipLocale';
import { ChevronDownIcon } from '@client/src/components/icons/ChevronDownIcon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menuItem: {
    '&:hover': {
      backgroundColor: `${COLORS.skyLight} !important`,
      color: `${COLORS.inkDark} !important`,
    },

    '&.Mui-selected': {
      backgroundColor: COLORS.tealNormal,
      color: `${COLORS.white} !important`,

      '&:hover': {
        backgroundColor: `${COLORS.tealNormal} !important`,
      },
    },
  },
}));

interface LocaleButtonSelectProps {
  onLocaleSelected?: (language: EasyshipLocale) => void;
  defaultValue?: EasyshipLocale;
}

export function LocaleButtonSelect({ defaultValue, onLocaleSelected }: LocaleButtonSelectProps) {
  const classes = useStyles();
  const languages: LanguageOption[] = [
    { name: 'English', code: 'en' },
    { name: 'Français', code: 'fr' },
    { name: 'Deutsch', code: 'de' },
    { name: 'Español', code: 'es' },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const findDefaultValue = languages.find((item) => item.code === defaultValue);
  const [selectedLocale, setSelectedLocale] = useState<LanguageOption>(
    findDefaultValue ?? languages[0]
  );
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = (language: LanguageOption) => {
    if (language.code) {
      setSelectedLocale(language);
      onLocaleSelected?.(language.code);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        style={{ padding: '16px 12px', textTransform: 'none', minWidth: 140 }}
      >
        <GlobeIcon style={{ height: 16, width: 16, marginRight: 8 }} />
        <Box color={COLORS.inkDarkest} clone>
          <Typography variant="body2">{selectedLocale.name}</Typography>
        </Box>
        <ChevronDownIcon style={{ height: 20, width: 20, marginLeft: 12 }} />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {languages.map((language) => (
          <MenuItem
            className={classes.menuItem}
            selected={language.code === selectedLocale.code}
            onClick={() => handleClose(language)}
            key={language.code}
            value={language.code}
            style={{ minWidth: '140px' }}
          >
            <Typography component="span" variant="body2">
              {language.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
