import { useQuery } from '@tanstack/react-query';

import { useGateways } from '../context/GatewayProvider';

export function useDashboardSettings() {
  const { userGateway } = useGateways();

  return useQuery({
    queryKey: ['dashboardSettings'],
    queryFn: () => userGateway.getDashboardSettings(),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
