import React, { ReactElement, useRef, useState } from 'react';
import { react2angular } from 'react2angular';
import COLORS from '@client/src/colors';
import { makeStyles } from '@material-ui/core/styles';
import { GeolocationGatewayProvider } from '@client/src/global/context/GeolocationProvider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { EasyshipLocale } from '@client/core/corelogic/models/EasyshipLocale';
import { LanguageOption } from '@client/auth/signup/signup.component';
import { IUserSession } from 'typings/user-session';
import clsx from 'clsx';
import logoDarkUrl from '@assets/images/logos/easyship-logo/logo-dark.svg';
import { LandingToggleButtonGroup } from '../../components/LandingToggleButtonGroup';
import { LoginForm } from '../../login/LoginForm';
import { ToggleOption } from '../../components/LandingToggleButtonGroup/types';
import useRecaptcha from '../../hooks/useRecaptcha';
import { LocaleButtonSelect } from '../../components/LocaleSelect';
import { useSettingImpactRadius } from '../../hooks/useSettingImpactRadius';
import SignupForm from '../../Signup/SignupForm';
import ReactRootProviders from '../../providers/ReactRootProviders';
import { AuthLandingHero } from '../../components/heroes';
import { AuthLandingHeader } from '../../components/AuthLandingHeader';
import useNsofAbTestVariation, {
  NsofAbTestVariationEnum,
} from '../../hooks/useNsofAbTestVariation';
import MultistepSignupForm from '../../Signup/MultistepSignupForm';

const useStyles = makeStyles(() => ({
  landingBackground: {
    background: `transparent linear-gradient(37deg, ${COLORS.greenLight} 0%, ${COLORS.tealNewLight} 100%) 0% 0% no-repeat padding-box`,
  },
  formSection: {
    backgroundColor: COLORS.skyNormal,
  },
  localeSelect: {
    background: 'transparent',
    width: 'auto',
    borderRadius: '4px',

    '&:hover': {
      background: COLORS.white,
    },
  },
}));

interface AuthLandingPageProps {
  onSignupSuccess?: (user: IUserSession) => void;
  defaultLocale?: EasyshipLocale;
  onChangeLocale?: (selectedLocale: EasyshipLocale) => void;
  onChangeToLogin?: () => void;
}

const LOCALE_DEFAULT_DATA: LanguageOption = { name: 'English', code: 'en' };

let DEFAULT_TOGGLE_OPTION: ToggleOption = 'signup';

export function AuthLandingPage({
  onSignupSuccess,
  defaultLocale,
  onChangeLocale,
  onChangeToLogin,
}: AuthLandingPageProps) {
  const classes = useStyles();

  const { recaptchaToken, fetchAndSetRecaptcha } = useRecaptcha();
  const [selectedForm, setSelectedForm] = useState<ToggleOption>(DEFAULT_TOGGLE_OPTION);
  const [selectedLocale, setSelectedLocale] = useState<EasyshipLocale>(
    defaultLocale || LOCALE_DEFAULT_DATA.code
  );
  const [currentSignupStep, setCurrentSignupStep] = useState(1);

  const signupFormRef = useRef<HTMLFormElement>(null);
  const multistepSignupFormRef = useRef<HTMLFormElement>(null);
  const emailValue = useRef('');

  const { variation: nsofVariation } = useNsofAbTestVariation();

  useSettingImpactRadius();

  function handleFormSelected(_: React.MouseEvent<HTMLElement>, value: ToggleOption) {
    if (value !== null) {
      if (signupFormRef.current) {
        emailValue.current = new FormData(signupFormRef.current).get('email')?.toString() ?? '';
      }
      if (multistepSignupFormRef.current) {
        emailValue.current =
          new FormData(multistepSignupFormRef.current).get('email')?.toString() ?? '';
      }
      if (value === 'login') {
        onChangeToLogin?.();
      }
      setSelectedForm(value);
    }
  }

  function handleLocaleSelected(value: EasyshipLocale) {
    setSelectedLocale(value);
    onChangeLocale?.(value);

    DEFAULT_TOGGLE_OPTION = selectedForm === 'login' ? 'login' : 'signup';
  }

  function handleContinueClicked(): void {
    setCurrentSignupStep((prevState) => prevState + 1);
  }

  function handleBackClicked(): void {
    setCurrentSignupStep((prevState) => prevState - 1);
  }

  return (
    <div
      className={clsx(
        'grid grid-cols-2 xl:h-full sm:max-xl:min-h-full xl:overflow-hidden',
        classes.landingBackground
      )}
    >
      <div className="w-full col-span-2 md:min-h-full min-h-auto md:col-span-1">
        <AuthLandingHero />
      </div>
      <div
        className={clsx(
          classes.formSection,
          'w-full md:min-h-full min-h-auto md:pb-4 pb-5 md:col-span-1 col-span-2'
        )}
      >
        <div className="lg:px-[100px] px-5 md:pt-12 pt-5">
          <div className="flex items-center justify-between">
            <img
              src={logoDarkUrl}
              alt="easyship-logo"
              className="md:h-10 h-7 md:w-[168px] w-[117px]"
            />
            <div className={clsx(classes.localeSelect, 'justify-center items-center')}>
              <LocaleButtonSelect
                onLocaleSelected={handleLocaleSelected}
                defaultValue={selectedLocale}
              />
            </div>
          </div>
          {currentSignupStep === 1 && (
            <LandingToggleButtonGroup
              value={selectedForm}
              exclusive
              onChange={handleFormSelected}
              style={{ width: '100%' }}
              className="px-3 mt-4 md:mt-10"
            />
          )}
          <div className="pt-4 pb-4 md:pt-10 md:pb-0">
            {selectedForm === 'login' && (
              <LoginForm
                onLoginError={fetchAndSetRecaptcha}
                recaptchaToken={recaptchaToken}
                defaultData={{ email: emailValue.current }}
              />
            )}
            {selectedForm === 'signup' && (
              <>
                <div className={clsx({ hidden: currentSignupStep !== 1 })}>
                  <AuthLandingHeader />
                </div>
                {nsofVariation === NsofAbTestVariationEnum.enum.multi ? (
                  <MultistepSignupForm
                    defaultEmail={emailValue.current}
                    recaptchaToken={recaptchaToken}
                    onBackClicked={handleBackClicked}
                    currentStep={currentSignupStep}
                    onContinueClicked={handleContinueClicked}
                    onSignupSuccess={onSignupSuccess}
                    onSignupError={fetchAndSetRecaptcha}
                    ref={multistepSignupFormRef}
                  />
                ) : (
                  <SignupForm
                    onSignupSuccess={onSignupSuccess}
                    onSignupError={fetchAndSetRecaptcha}
                    recaptchaToken={recaptchaToken}
                    ref={signupFormRef}
                    defaultData={{ email: emailValue.current }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function WrappedSignupPage({
  onSignupSuccess,
  onChangeToLogin,
}: Omit<AuthLandingPageProps, 'onChangeLocale' | 'defaultLocale'>): ReactElement {
  const [locale, setLocale] = useState<EasyshipLocale>('en');
  return (
    <ReactRootProviders selectedLocale={locale}>
      <GeolocationGatewayProvider>
        <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_KEY_RECAPTCHA ?? ''}>
          <AuthLandingPage
            defaultLocale={locale}
            onChangeLocale={(e) => setLocale(e)}
            onSignupSuccess={onSignupSuccess}
            onChangeToLogin={onChangeToLogin}
          />
        </GoogleReCaptchaProvider>
      </GeolocationGatewayProvider>
    </ReactRootProviders>
  );
}

export const AngularSignupPage = react2angular(WrappedSignupPage, [
  'onSignupSuccess',
  'onChangeToLogin',
]);
