import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export function GlobeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M8,.563a8,8,0,1,0,8,8A8,8,0,0,0,8,.563ZM10.655,12.1l-.365.364a.771.771,0,0,0-.2.345c-.049.183-.088.367-.154.544l-.561,1.511A6.447,6.447,0,0,1,8,15.014v-.883a2.415,2.415,0,0,0-.73-1.653,1.032,1.032,0,0,1-.3-.73V10.715a1.029,1.029,0,0,0-.531-.9c-.464-.256-1.123-.615-1.575-.842a4.65,4.65,0,0,1-1.021-.7l-.026-.023a3.7,3.7,0,0,1-.583-.669c-.3-.444-.8-1.175-1.116-1.65A6.487,6.487,0,0,1,5.446,2.64l.775.387a.516.516,0,0,0,.747-.462V2.2a6.447,6.447,0,0,1,.787-.078l.913.913a.516.516,0,0,1,0,.73l-.151.151-.334.334a.258.258,0,0,0,0,.365l.151.151a.258.258,0,0,1,0,.365l-.258.258a.258.258,0,0,1-.183.075H7.6a.258.258,0,0,0-.18.073L7.1,5.85a.258.258,0,0,0-.051.3l.5,1.006a.258.258,0,0,1-.231.374H7.143a.258.258,0,0,1-.169-.063l-.3-.26a.517.517,0,0,0-.5-.1l-1.005.335a.386.386,0,0,0-.05.711l.357.179a2.185,2.185,0,0,0,.978.231c.339,0,.729.88,1.032,1.032H9.637a1.033,1.033,0,0,1,.73.3l.442.442a.984.984,0,0,1,.288.7,1.5,1.5,0,0,1-.443,1.064Zm2.8-2.947A.774.774,0,0,1,13,8.83l-.58-.87a.773.773,0,0,1,0-.859l.632-.948a.773.773,0,0,1,.3-.263l.419-.209a6.25,6.25,0,0,1,.629,3.705Z"
        transform="translate(0 -0.563)"
        fill="#919eab"
      />
    </SvgIcon>
  );
}
