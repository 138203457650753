import React from 'react';
import { IntlConfig, IntlProvider } from 'react-intl';
import { useLocale, messageDictionary } from '@client/src/locale';

const defaultRichTextElements: IntlConfig['defaultRichTextElements'] = {
  b: (parts) => <strong>{parts}</strong>,
  // @ts-expect-error: This works, but 'react-intl' typing is wrong and expect a function
  br: <br />,
};

interface IntlProviderProps {
  children: React.ReactNode;
}

export default function I18nProvider({ children }: IntlProviderProps) {
  const locale = useLocale();

  return (
    <IntlProvider
      locale={locale}
      messages={messageDictionary[locale]}
      defaultLocale="en"
      defaultRichTextElements={defaultRichTextElements}
    >
      {children}
    </IntlProvider>
  );
}
