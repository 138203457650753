AccountRoutes.$inject = ['$stateProvider'];
function AccountRoutes($stateProvider) {
  $stateProvider
    .state('credit-card', { parent: 'add-credit', component: 'accountCreditCard' })
    .state('app.account.team', {
      url: '/team',
      component: 'team',
      data: {
        requiredUserRight: 'menu.account.team',
      },
    })
    .state('app.account.company', {
      url: '/company',
      component: 'companyAccount',
      data: {
        requiredUserRight: 'menu.account.company',
      },
    })
    .state('app.account.profile', {
      url: '/profile',
      component: 'profile',
      data: {
        requiredUserRight: 'menu.account.profile',
      },
    })
    .state('app.account.billing', {
      url: '/statements?settlement_state',
      component: 'billing',
      params: { settlement_state: '' },
      data: {
        requiredUserRight: 'menu.account.billing',
      },
    })
    .state('app.account.payment', {
      url: '/payment',
      component: 'payment',
      data: {
        requiredUserRight: 'menu.account.paymentMethods',
      },
    })
    .state('app.account.subscription', {
      url: '/subscription?open',
      component: 'subscription',
      params: { isOpenPlan: false },
      data: {
        requiredUserRight: 'menu.account.subscriptions',
      },
    });
}

export { AccountRoutes };
