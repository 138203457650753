const planSlugs = [
  'free',
  'plus',
  'premier',
  'enterprise',
  'gold',
  'scale-1',
  'scale-2',
  'scale-3',
] as const;
export type PlanSlug = (typeof planSlugs)[number];

export enum DefinedSubscriptionPlanId {
  Free = 1,
  Plus = 2,
  Premier = 3,
  SCALE_1 = 6,
  SCALE_2 = 7,
  SCALE_3 = 8,
}

/**
 * For flexible subscription plans,
 * customer success team could adjust the feature limit for each customer
 */
export enum FlexibleSubscriptionPlanId {
  Enterprise = 4,
  Gold = 5,
}

export const SCALE_PLAN_IDS = [
  DefinedSubscriptionPlanId.SCALE_1,
  DefinedSubscriptionPlanId.SCALE_2,
  DefinedSubscriptionPlanId.SCALE_3,
  FlexibleSubscriptionPlanId.Enterprise,
];

export const SubscriptionPlanIds = {
  ...DefinedSubscriptionPlanId,
  ...FlexibleSubscriptionPlanId,
};

export enum BillingInterval {
  Month = 'month',
  Year = 'year',
}

export enum BillingFrequency {
  Month = 'monthly',
  Year = 'yearly',
}

export enum SubscriptionStates {
  Pending = 'pending',
  PendingCharge = 'pending_charge',
  Activated = 'activated',
  Failed = 'failed',
}

export const FEATURE_KEY = {
  Analytics: 'analytics',
  EcommerceIntegrations: 'ecommerce_integrations',
  EmailSupport: 'email_support',
  ChatSupport: 'chat_support',
  PhoneSupport: 'phone_support',
  OnboardingSession: 'onboarding_session',

  /** Your Own Courier Accounts */
  Lyoc: 'lyoc',

  /** Shipping at Checkout */
  RatesAtCheckout: 'rates_at_checkout',

  /** Display Import Tax and Duty at Checkout */
  TaxAndDutyAtCheckout: 'tax_and_duty_at_checkout',
  ShipmentsPerMonth: 'shipments_per_month',

  /** Discounted Shipping Rates */
  ShippingSolutions: 'shipping_solutions',
  TeamMembers: 'team_members',
  LabelCustomization: 'label_customization',
  RoyalMail: 'royal_mail',
  BrandedTrackingExperience: 'branded_tracking_experience',
  MultiBoxShipments: 'multi_box_shipments',

  /** Return Labels */
  Returns: 'returns',

  /** Auto-sync Orders */
  AutoSync: 'auto_sync',

  /** Pre-generated Return Labels */
  AutomatedReturn: 'automated_return',

  /** Customer Success Manager */
  DedicatedAccountManager: 'dedicated_account_manager',
  MobileAppAccess: 'mobile_app_access',
  LyocLabellingFee: 'lyoc_labelling_fee',

  /** Saved Boxes */
  Boxes: 'boxes',
  Catalog: 'catalog',
  PrintingOptions: 'printing-options',
  Pickups: 'pickups',
  Insurance: 'insurance',
  ShippingRules: 'shipping_rules',
  AuPost: 'au_post',

  /** Lowest Possible USPS Rates */
  Usps: 'usps',
  ApiAccess: 'api_access',

  /** Import Tax & Duty Calculation */
  TaxAndDuty: 'tax_and_duty',
  GlobalFulfilmentNetwork: 'global_fulfilment_network',
  CrowdfundingIntegration: 'crowdfunding_integration',
  PickList: 'pick_list',
  ExportShipments: 'export_shipments',
  PrintingFormat4x6: 'printing_format_4x6',
  CustomizeShipmentTable: 'customize_shipment_table',
} as const;

export type FeatureKey = (typeof FEATURE_KEY)[keyof typeof FEATURE_KEY];
