import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export function ChevronDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 21">
      <path
        d="M4.39282 7.08029C4.29509 6.98216 4.16238 6.92688 4.02388 6.92659C3.88539 6.92631 3.75245 6.98106 3.65432 7.07879C3.55619 7.17653 3.50091 7.30924 3.50063 7.44773C3.50034 7.58623 3.55509 7.71916 3.65282 7.81729L9.62982 13.7743C9.72805 13.8722 9.86111 13.9272 9.99982 13.9272C10.1385 13.9272 10.2716 13.8722 10.3698 13.7743L16.3468 7.81729C16.3952 7.7687 16.4336 7.71106 16.4597 7.64765C16.4858 7.58424 16.4992 7.51631 16.499 7.44773C16.4989 7.37916 16.4852 7.31128 16.4589 7.24798C16.4325 7.18467 16.3939 7.12719 16.3453 7.07879C16.2967 7.0304 16.2391 6.99205 16.1757 6.96594C16.1123 6.93982 16.0443 6.92646 15.9758 6.92659C15.9072 6.92673 15.8393 6.94038 15.776 6.96675C15.7127 6.99312 15.6552 7.0317 15.6068 7.08029L9.99982 12.5133L4.39282 7.08029Z"
        fill="#919FAB"
      />
    </SvgIcon>
  );
}
