(function () {
  'use strict';

  ServiceType.$inject = ['$resource', 'API'];
  function ServiceType($resource, API) {
    var endpointUrl = API.endpoint;

    return $resource(
      endpointUrl + '/service_types',
      {
        // id: '@_id'
      },
      {
        query: {
          method: 'GET',
          params: {
            // id: 'me'
          },
        },
      }
    );
  }

  angular.module('app.factory.ServiceType', []).factory('ServiceType', ServiceType);
})();
