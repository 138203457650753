(function () {
  angular.module('easyshipDashboardApp').controller('LogoutCtrl', LogoutCtrl);

  LogoutCtrl.$inject = [
    '$scope',
    '$stateParams',
    '$window',
    '$state',
    'Auth',
    'AUTH_EVENTS',
    'SubscriptionService',
    'PusherService',
    'MixpanelService',
  ];

  function LogoutCtrl(
    $scope,
    $stateParams,
    $window,
    $state,
    Auth,
    AUTH_EVENTS,
    SubscriptionService,
    PusherService,
    MixpanelService
  ) {
    $scope.$on(AUTH_EVENTS.logoutSuccess, function () {
      PusherService.destroyPusher();
      MixpanelService.reset();
      redirectLocation($stateParams.return_to);
    });

    function redirectLocation(location) {
      const isUberFreight = $window.localStorage.getItem('isUberFreight');
      if ($stateParams.sso === 'luxottica') {
        $window.localStorage.removeItem('isLuxotticaSso');
        $state.go('home.auth.luxottica');
      } else if ($stateParams.sso === 'session') {
        $state.go('home.auth.sso-session');
      } else if (isUberFreight) {
        $window.localStorage.removeItem('isUberFreight');
        $state.go('home.uber-freight-login');
      } else if (location) {
        $window.location = location;
      } else {
        $state.go('home.auth.login');
      }
    }

    // If the user is logged in, logout the user
    // Redirect user back to original page if return_to is passed, otherwise redirect to login
    (function init() {
      const sessionToken = Auth.getCredentials();

      if (sessionToken) {
        SubscriptionService.destroyCurrentSubscription();
        Auth.logout();
        $window.localStorage.removeItem('expiresAt');
        $window.localStorage.removeItem('resendAllowedAt');
      } else {
        redirectLocation($stateParams.return_to);
      }
    })();
  }
})();
