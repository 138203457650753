import { Chance } from 'chance';
import { orderEditMock, orderMock } from '../Order/OrderData.mocks';
import { receiverAddressEditMock, receiverAddressMock } from '../receiverAddress';
import { senderAddressEditMock, senderAddressMock } from '../senderAddress';
import { ShipmentData, ShipmentFormData } from './ShipmentData';

const chance = new Chance();
const currencyCode = chance.currency().code;

export const shipmentMock = (): ShipmentData => ({
  id: chance.guid(),
  easyshipId: chance.guid(),
  createdAt: chance.date().toString(),
  orderCreatedAt: chance.date().toString(),
  order: orderMock(),
  senderAddress: senderAddressMock(),
  receiverAddress: receiverAddressMock(),
  rates: [],
  currencyCode,
  courierServiceSelectedId: chance.guid(),
  hasMissingDimensions: chance.bool(),
  courierAlerts: [],
  apiAddressesErrors: {},
  apiOrderErrors: {},
  effectiveIncoterms: 'DDP',
  isAboveThreshold: false,
  isValidToShip: chance.bool(),
  deliveryTime: {
    min: chance.integer({ min: 1, max: 10 }),
    max: chance.integer({ min: 1, max: 10 }),
  },
  totalCharge: chance.floating({ min: 0, max: 100, fixed: 2 }),
  tax: {
    sales: chance.floating({ min: 0, max: 100, fixed: 2 }),
    salesTaxName: chance.string({ length: 3 }),
    provincialSales: chance.floating({ min: 0, max: 100, fixed: 2 }),
    provincialSalesTaxName: chance.string({ length: 3 }),
  },
  collectState: chance.pickone(['collected', 'to_be_collected', 'none', 'cancelled']),
  courier: {
    name: chance.name(),
    umbrellaName: chance.name(),
  },
});

export const shipmentFormMock = (): ShipmentFormData => ({
  order: orderEditMock(),
  senderAddress: senderAddressEditMock(),
  receiverAddress: receiverAddressEditMock(),
});
