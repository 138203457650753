import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { react2angular } from 'react2angular';

import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { Alert, Button } from 'easyship-components';
import { Barcode as BarcodeIcon } from 'easyship-components/icons';
import { useUserPreferencesMutation } from '@client/src/create-shipments/shipment-details-drawer/sections/shared-courier-section';
import { getDateDifferenceByDays } from '@client/src/utils/date';
import { UserSessionProvider, useUserSession } from '../../context/UserSessionProvider';

export default function BarcodeScanningBanner() {
  const { mutate } = useUserPreferencesMutation();
  const { preferences, updatePreferences, company } = useUserSession();
  const [isVisible, setIsVisible] = useState<boolean>(
    Boolean(preferences.showBarcodeBanner) &&
      getDateDifferenceByDays(new Date(), company.createdAt) > 5
  );

  if (!isVisible) {
    return null;
  }

  const gotItHandler = () => {
    mutate({ showBarcodeBanner: false });
    updatePreferences({ showBarcodeBanner: false });
    setIsVisible(false);
  };

  return (
    <Alert
      action={
        <Button onClick={gotItHandler}>
          <FormattedMessage id="global.got-it" />
        </Button>
      }
      icon={<BarcodeIcon className="text-teal-500" />}
      contentClassName="items-center"
    >
      <strong>
        <FormattedMessage id="banners.barcode-scanning.have-barcode-scanner" />
      </strong>
      &nbsp;
      <span>
        <FormattedMessage id="banners.barcode-scanning.message" />
      </span>
      &nbsp;
      <a href="/tools/packing-slip">
        <FormattedMessage id="banners.barcode-scanning.see-settings" />
      </a>
    </Alert>
  );
}

export function WrappedBarcodeScanningBanner() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <BarcodeScanningBanner />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularBarcodeScanningBanner = react2angular(WrappedBarcodeScanningBanner, [], []);
