import angular from 'angular';

import progressButton from './progress-button';
import checklistModal from './checklist-modal';
import confirmationModal from './confirmation-modal';
import completeModal from './complete-modal';

const MODULE_NAME = 'app.global.components.dashboard-sidebar.onboarding-checklist';

angular.module(MODULE_NAME, [progressButton, checklistModal, confirmationModal, completeModal]);

export default MODULE_NAME;
