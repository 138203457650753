import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ShippingCountryData } from '@client/core/corelogic/models/ShippingCountryData';
import { useAuthGateway } from '../providers/AuthGatewayProvider';

export default function useShippingCountriesQuery(params?: UseQueryOptions<ShippingCountryData[]>) {
  const { shippingCountryGateway } = useAuthGateway();

  return useQuery({
    queryKey: ['shippingCountries'],
    queryFn: () => shippingCountryGateway.getShippingCountries(),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...params,
  });
}
