import React from 'react';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import MuiToggleButtonGroup, {
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from '@material-ui/lab/ToggleButtonGroup';
import { FormattedMessage } from 'react-intl';
import { ToggleOption } from './types';

interface LandingToggleButtonGroupProps extends MuiToggleButtonGroupProps {
  onChange?: (event: React.MouseEvent<HTMLElement>, value: ToggleOption) => void;
}

export function LandingToggleButtonGroup({ onChange, ...props }: LandingToggleButtonGroupProps) {
  return (
    <MuiToggleButtonGroup orientation="horizontal" onChange={onChange} {...props}>
      <MuiToggleButton disableRipple value="signup" className="w-1/2">
        <span className="text-lg">
          <FormattedMessage id="signup.signup-text" />
        </span>
      </MuiToggleButton>
      <MuiToggleButton disableRipple value="login" className="w-1/2">
        <span className="text-lg">
          <FormattedMessage id="login.login-text" />
        </span>
      </MuiToggleButton>
    </MuiToggleButtonGroup>
  );
}
