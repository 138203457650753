import { IAuthService } from 'typings/core/services/auth';

import { IComponentController } from 'angular';
import { toastError } from '@client/core/components/react/Toastify';
import template from './log-in-as.html?raw';
import style from './log-in-as.module.scss';

class LogInAsController implements IComponentController {
  style = style;

  static $inject = ['$window', '$stateParams', '$state', 'Auth', '$location'];
  constructor(
    private $window: ng.IWindowService,
    private $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    private Auth: IAuthService,
    public $location: ng.ILocationService
  ) {}

  $onInit(): void {
    if (!this.$stateParams.jwt) this.$state.go('home.auth.login');
    // To be removed after the new enterprise login (redirect-login) is fully implemented
    if (this.$state.is('enterprise-login-depreciated') || this.$state.is('enterprise-login')) {
      const jwt = this.$stateParams.jwt ?? this.$location.search().jwt;
      this.Auth.enterpriseLogIn(jwt)
        .then((url) => {
          this.$location.search({}); // clear JWT search param in the url

          const { pathname, search } = new URL(url);

          const nextPath = pathname + search;

          this.$location.url(nextPath);
        })
        .catch((error) => {
          toastError(error.message);
          this.$state.go('home.auth.login');
        });
    } else if (this.$state.is('redirect-login')) {
      const jwt = this.$stateParams.jwt ?? this.$location.search().jwt;
      this.Auth.redirectLogIn(jwt)
        .then((url) => {
          this.$location.search({}); // clear JWT search param in the url

          const { pathname, search } = new URL(url);

          const nextPath = pathname + search;

          this.$location.path(nextPath);
        })
        .catch((error) => {
          toastError(error.message);
          this.$state.go('home.auth.login');
        });
    } else {
      const jwt = this.$stateParams.jwt ?? this.$location.search().jwt;
      this.Auth.logInAs(jwt)
        .then(() => {
          this.$state.go('app.shipments');
        })
        .catch((error) => {
          toastError(error.message);
          this.$state.go('home.auth.login');
        });
    }
  }
}

const LogInAsComponent = {
  controller: LogInAsController,
  template,
};

export { LogInAsComponent };
