import React, { createContext, useContext, useMemo } from 'react';

import ICountryGateway from '@client/core/corelogic/ports/country.interface';
import NgCountryGateway from '@client/core/adapters/country/ngCountry';
import IShipmentGateway from '@client/core/corelogic/ports/shipment.interface';
import NgShipmentGateway from '@client/core/adapters/shipment/ngShipment';
import IBrokerGateway from '@client/core/corelogic/ports/broker.interface';
import NgBrokerGateway from '@client/core/adapters/broker/ngBroker';
import ICourierAccountGateway from '@client/core/corelogic/ports/courierAccount.interface';
import NgCourierAccountGateway from '@client/core/adapters/courier/ngCourierAccount';
import IItemGateway from '@client/core/corelogic/ports/item.interface';
import NgItemGateway from '@client/core/adapters/item/ngItem';
import IPlatformGateway from '@client/core/corelogic/ports/platform.interface';
import NgPlatformGateway from '@client/src/platform/gateways/api/NgPlatformGateway';
import IHsCodeGateway from '@client/core/corelogic/ports/hsCode.interface';
import NgHsCodeGateway from '@client/core/adapters/hsCode/ngHsCode';
import IUserGateway from '@client/core/corelogic/ports/user.interface';
import NgUserGateway from '@client/core/adapters/user/ngUser';
import IAddressGateway from '@client/core/corelogic/ports/address.interface';
import NgAddressGateway from '@client/core/adapters/address/ngAddress';
import FakeCountryGateway from '@client/core/adapters/country/fakeCountry';
import FakeShipmentGateway from '@client/core/adapters/shipment/fakeShipment';
import { FakeCourierAccountGateway } from '@client/core/adapters/courier/fakeCourierAccount';
import { FakeBrokerGateway } from '@client/core/adapters/broker/fakeBroker';
import FakeItemGateway from '@client/core/adapters/item/fakeItem';
import FakePlatformGateway from '@client/src/platform/gateways/fake/FakePlatformGateway';
import { FakeHsCodeGateway } from '@client/core/adapters/hsCode/fakeHsCode';
import { FakeUserGateway } from '@client/core/adapters/user/fakeUser';
import FakeAddressGateway from '@client/core/adapters/address/fakeAddress';
import IAdjustmentsGateway from '@client/core/corelogic/ports/adjustments.interface';
import NgAdjustmentsGateway from '@client/core/adapters/adjustments/ngAdjustments';
import FakeAdjustmentsGateway from '@client/core/adapters/adjustments/fakeAdjustments';
import ISubscriptionGateway from '@client/core/corelogic/ports/subscription.interface';
import NgSubscriptionGateway from '@client/core/adapters/subscription/ngSubscription';
import FakeSubscriptionGateway from '@client/core/adapters/subscription/fakeSubscription';
import ICompanyGateway from '@client/core/corelogic/ports/company.interface';
import NgCompanyGateway from '@client/core/adapters/company/ngCompany';
import FakeCompanyGateway from '@client/core/adapters/company/fakeCompany';
import ICollectGateway from '@client/core/corelogic/ports/collect.interface';
import NgCollectGateway from '@client/core/adapters/collect/ngCollect';
import FakeCollectGateway from '@client/core/adapters/collect/fakeCollect';
import { StoreGateway } from '@client/core/corelogic/ports/store.interface';
import NgStoreGateway from '@client/core/adapters/store/NgStoreGateway';
import FakeStoreGateway from '@client/core/adapters/store/FakeStoreGateway';

export interface GatewayContextData {
  countryGateway: ICountryGateway;
  shipmentGateway: IShipmentGateway;
  collectGateway: ICollectGateway;
  courierGateway: ICourierAccountGateway;
  brokerGateway: IBrokerGateway;
  itemGateway: IItemGateway;
  platformGateway: IPlatformGateway;
  hsCodeGateway: IHsCodeGateway;
  userGateway: IUserGateway;
  addressGateway: IAddressGateway;
  adjustmentsGateway: IAdjustmentsGateway;
  subscriptionGateway: ISubscriptionGateway;
  companyGateway: ICompanyGateway;
  storeGateway: StoreGateway;
}

const GatewayContext = createContext<GatewayContextData | undefined>(undefined);

interface GatewayProviderProps {
  children: React.ReactNode;
}

function GatewayProvider({ children }: GatewayProviderProps) {
  const data: GatewayContextData = useMemo(
    () => ({
      countryGateway: new NgCountryGateway(),
      shipmentGateway: new NgShipmentGateway(),
      collectGateway: new NgCollectGateway(),
      courierGateway: new NgCourierAccountGateway(),
      brokerGateway: new NgBrokerGateway(),
      itemGateway: new NgItemGateway(),
      platformGateway: new NgPlatformGateway(),
      hsCodeGateway: new NgHsCodeGateway(),
      userGateway: new NgUserGateway(),
      addressGateway: new NgAddressGateway(),
      adjustmentsGateway: new NgAdjustmentsGateway(),
      subscriptionGateway: new NgSubscriptionGateway(),
      companyGateway: new NgCompanyGateway(),
      storeGateway: new NgStoreGateway(),
    }),
    []
  );

  return <GatewayContext.Provider value={data}>{children}</GatewayContext.Provider>;
}

interface FakeGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<GatewayContextData>;
}

function FakeGatewayProvider({ children, value }: FakeGatewayProviderProps) {
  const data: GatewayContextData = useMemo(
    () => ({
      countryGateway: new FakeCountryGateway(),
      shipmentGateway: new FakeShipmentGateway(),
      collectGateway: new FakeCollectGateway(),
      courierGateway: new FakeCourierAccountGateway(),
      brokerGateway: new FakeBrokerGateway(),
      itemGateway: new FakeItemGateway(),
      platformGateway: new FakePlatformGateway(),
      hsCodeGateway: new FakeHsCodeGateway(),
      userGateway: new FakeUserGateway(),
      addressGateway: new FakeAddressGateway(),
      adjustmentsGateway: new FakeAdjustmentsGateway(),
      subscriptionGateway: new FakeSubscriptionGateway(),
      companyGateway: new FakeCompanyGateway(),
      storeGateway: new FakeStoreGateway(),
      ...value,
    }),
    [value]
  );

  return <GatewayContext.Provider value={data}>{children}</GatewayContext.Provider>;
}

function useGateways() {
  const gatewayContext = useContext(GatewayContext);

  if (gatewayContext === undefined) {
    throw new Error('useGateways must be used within a GatewayProvider');
  }
  return gatewayContext;
}

export { GatewayProvider, FakeGatewayProvider, useGateways };
