import { useMutation } from '@tanstack/react-query';
import { useAuthGateway } from '../providers/AuthGatewayProvider';
import { SignupFormData } from '../Signup/SignupForm.types';

interface SignupMutationParams {
  payload: SignupFormData;
  recaptchaToken: string;
}

export default function useSignupMutation() {
  const { authGateway } = useAuthGateway();

  return useMutation({
    mutationFn: ({ payload, recaptchaToken }: SignupMutationParams) =>
      authGateway.signup(payload, recaptchaToken),
  });
}
