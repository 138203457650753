import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import PopperJs from 'node_modules/popper.js';

import Popper, { PopperProps } from '@client/core/components/react/Popper';
import { SuggestedHsCodes } from '@client/core/corelogic/models/HsCode';
import { ItemCategory } from '@client/core/corelogic/models/ItemCategory';
import { CategoryHsCodeSelection } from './CategoryHsCodeInput';
import CategoryList from './CategoryList';
import SearchHsCode from './SearchHsCode';

interface HsCodePopperProps extends Omit<PopperProps, 'children'> {
  value: CategoryHsCodeSelection;
  categories: ItemCategory[];
  additionalSuggestedHsCodes?: SuggestedHsCodes;
  onCategorySelect: (categoryId: number) => void;
  onHsCodeSelect: (hsCodeNumber: string, hsCodeDescription: string) => void;
  closePopup(): void;
}

const POPPER_HEIGHT = 325;

export default function HsCodePopper(props: HsCodePopperProps) {
  const {
    value,
    categories,
    additionalSuggestedHsCodes = {},
    onCategorySelect,
    onHsCodeSelect,
    closePopup,
    ...popperProps
  } = props;
  const [defaultWidth, setDefaultWidth] = useState<number>(272);
  const [popperFlexDirection, setPopperFlexDirection] = useState<'column' | 'column-reverse'>(
    'column'
  );
  const [suggestedHsCodes, setSuggestedHsCodes] = useState<SuggestedHsCodes>(
    value &&
      'hsCodeNumber' in value &&
      value.hsCodeNumber &&
      'hsCodeDescription' in value &&
      value.hsCodeDescription
      ? { [value.hsCodeNumber]: value.hsCodeDescription }
      : {}
  );
  const [showSearchHsCode, setShowSearchHsCode] = useState(false);

  useEffect(() => {
    if (popperProps.open) {
      setShowSearchHsCode(false);
    }
  }, [popperProps.open]);

  const computePopperLayout = (popperRef: PopperJs | null) => {
    if (!popperRef) return;

    const inputRect = popperRef.reference.getBoundingClientRect();

    // Prevent popper width to be smaller than the input width
    if (inputRect.width > defaultWidth) {
      setDefaultWidth(inputRect.width);
    }

    popperRef.options.onUpdate = (data) => {
      setPopperFlexDirection(data.placement === 'bottom-start' ? 'column' : 'column-reverse');
    };
  };

  const handleCategorySelect = (categoryId: number) => {
    onCategorySelect(categoryId);
    closePopup();
  };

  const handleHsCodeSelect = (hsCodeNumber: string, hsCodeDescription: string) => {
    setSuggestedHsCodes({
      ...suggestedHsCodes,
      [hsCodeNumber]: hsCodeDescription,
    });
    onHsCodeSelect(hsCodeNumber, hsCodeDescription);
    closePopup();
  };

  return (
    <Popper
      /**
       * Add id to help fix the issue related to clicking any element inside the popper
       * close down the Classic View - Shipment Edit
       */
      id="categoryHsCodePopper"
      popperRef={computePopperLayout}
      noPadding
      placement="bottom-start"
      style={{
        zIndex: 1501,
        /**
         * Add fixed height to give the contents a fixed position which prevents it from
         * repositioning the placement of popper from top to bottom/vice versa
         * when switching from category list to HS code search
         */
        height: POPPER_HEIGHT,
        // Position the content above/below the input
        display: 'flex',
        flexDirection: popperFlexDirection,
        // Ensure elements under transparent space when in search mode are clickable
        pointerEvents: 'none',
      }}
      onClickAway={() => closePopup()}
      {...popperProps}
    >
      {/* Add max height to prevent empty white space from occupying the space of small content */}
      <Box
        width={showSearchHsCode ? 500 : defaultWidth}
        maxHeight={POPPER_HEIGHT}
        display="flex"
        flexDirection={popperFlexDirection}
        style={{
          // Ensure visible elements are clickable even the parent element's `pointer-events: none`
          pointerEvents: 'auto',
        }}
      >
        {showSearchHsCode ? (
          <SearchHsCode
            onHsCodeSelect={handleHsCodeSelect}
            backToCategory={() => setShowSearchHsCode(false)}
          />
        ) : (
          <CategoryList
            value={value}
            categories={categories}
            suggestedHsCodes={{ ...additionalSuggestedHsCodes, ...suggestedHsCodes }}
            onCategorySelect={handleCategorySelect}
            onHsCodeSelect={handleHsCodeSelect}
            showSearchHsCode={setShowSearchHsCode}
          />
        )}
      </Box>
    </Popper>
  );
}
