import React, { createContext, useContext, useMemo } from 'react';
import NgShipmentGateway from '@client/core/adapters/shipment/ngShipment';
import IShipmentGateway from '@client/core/corelogic/ports/shipment.interface';
import FakeShipmentGateway from '@client/core/adapters/shipment/fakeShipment';

export interface ShipmentGatewayContextData {
  shipmentGateway: IShipmentGateway;
}

const ShipmentGatewayContext = createContext<ShipmentGatewayContextData | undefined>(undefined);

interface ShipmentGatewayProviderProps {
  children: React.ReactNode;
}

function ShipmentGatewayProvider({ children }: ShipmentGatewayProviderProps) {
  const data: ShipmentGatewayContextData = useMemo(
    () => ({
      shipmentGateway: new NgShipmentGateway(),
    }),
    []
  );

  return <ShipmentGatewayContext.Provider value={data}>{children}</ShipmentGatewayContext.Provider>;
}

interface FakeShipmentGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<ShipmentGatewayContextData>;
}

function FakeShipmentGatewayProvider({ children, value }: FakeShipmentGatewayProviderProps) {
  const data: ShipmentGatewayContextData = useMemo(
    () => ({
      shipmentGateway: new FakeShipmentGateway(),
      ...value,
    }),
    [value]
  );

  return <ShipmentGatewayContext.Provider value={data}>{children}</ShipmentGatewayContext.Provider>;
}

function useShipmentGateway() {
  const shipmentGatewayContext = useContext(ShipmentGatewayContext);

  if (shipmentGatewayContext === undefined) {
    throw new Error('useShipmentGateway must be used within a ShipmentGatewayProvider');
  }
  return shipmentGatewayContext;
}

export { ShipmentGatewayProvider, FakeShipmentGatewayProvider, useShipmentGateway };
