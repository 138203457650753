import { useQuery } from '@tanstack/react-query';
import { CurrentSubscription } from '@client/core/corelogic/ports/subscription.interface';
import { SubscriptionPeriod } from 'typings/subscription';
import { SubscriptionStates } from '@client/data/subscription';
import { getDateDifferenceByDays, parseUTC } from '@client/src/utils/date';
import { useGateways } from '../context/GatewayProvider';

function getPeriod(currentSubscription: CurrentSubscription): SubscriptionPeriod {
  if (freeTrialDaysLeft(currentSubscription) > 0) return 'TRIAL';

  if (
    currentSubscription.trialEndAt &&
    currentSubscription.state === SubscriptionStates.Failed &&
    !currentSubscription.paymentSourceId
  ) {
    return 'END_OF_TRIAL';
  }
  return 'SUBSCRIBED';
}

function freeTrialDaysLeft(currentSubscription: CurrentSubscription): number {
  return getDateDifferenceByDays(parseUTC(currentSubscription.trialEndAt), new Date());
}

export function useCurrentSubscriptionQuery() {
  const { subscriptionGateway } = useGateways();

  return useQuery({
    queryKey: ['currentSubscription'],
    queryFn: () => subscriptionGateway.getCurrent(),
    cacheTime: Infinity,
    staleTime: Infinity,
    select: (data) => ({
      ...data,
      period: getPeriod(data),
      freeTrialDaysLeft: freeTrialDaysLeft(data),
    }),
  });
}
