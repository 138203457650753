import angular, { IComponentController } from 'angular';
import { IApiConfig } from 'typings/core/config/api';
import { ICourierAccountClientDetails, ICourierAccountsService } from 'typings/courier';

import template from './canada-post.html?raw';
import style from './canada-post.module.scss';

const CANADA_POST_FORM_ELEMENT_ID = 'canada-post-form-redirect';
const EASYSHIP_PLATFORM_ID = '9661954'; // ID that assigned by Canada Post to Easyship

class FormCanadaPost implements IComponentController {
  esCourierDetails: ICourierAccountClientDetails = { nickname: '' };
  esError: string | null = null;
  esEditMode = false;
  esToggleModalDescription = () => {};

  style = style;
  busy = {
    gettingTokenId: false,
  };
  canadaPostUrl = '';
  tokenId = '';
  redirectUrl = '';
  canadaPostFormId = CANADA_POST_FORM_ELEMENT_ID;
  easyshipPlatformId = EASYSHIP_PLATFORM_ID;

  static $inject = ['$sce', '$element', '$timeout', '$translate', 'API', 'CourierAccounts'];
  constructor(
    private $sce: ng.ISCEService,
    private $element: ng.IRootElementService,
    private $timeout: ng.ITimeoutService,
    private $translate: angular.translate.ITranslateService,
    private API: IApiConfig,
    private CourierAccounts: ICourierAccountsService
  ) {}

  $onInit() {
    this.canadaPostUrl = this.getCanadaPostUrl();

    if (this.esEditMode && !this.esError) {
      this.esError = this.$translate.instant('courier.connect.connection-error');
    }
  }

  onInputChange(value: string, key: keyof ICourierAccountClientDetails) {
    this.esCourierDetails[key] = value;
  }

  onSubmit(connectForm: ng.IFormController) {
    if (connectForm.$invalid) {
      this.esError = this.$translate.instant('toast.incomplete-form');

      return;
    }

    this.esError = null;
    this.busy.gettingTokenId = true;

    this.CourierAccounts.addCourier({
      umbrella_name: 'canada-post',
      account: this.esCourierDetails,
    })
      .then(({ id, canada_post_token_id }) => {
        if (!id || !canada_post_token_id) {
          this.esError = this.$translate.instant('courier.connect.missing-parameters');
          this.busy.gettingTokenId = false;

          return;
        }

        this.tokenId = canada_post_token_id;
        this.redirectUrl = `${this.API.dashboard}/couriers/${id}/redirect`;

        // Fix issue where tokenId & redirectUrl are empty which is probably because of input's debounced function
        this.$timeout(() => {
          this.$element.find(`#${this.canadaPostFormId}`).trigger('submit');
        }, 1000);
      })
      .catch(({ data }) => {
        this.esError = data.status;
        this.busy.gettingTokenId = false;
      });
  }

  private getCanadaPostUrl() {
    return this.$sce.trustAsResourceUrl(
      `https://www.canadapost-postescanada.ca/information/app/drc/merchant`
    );
  }
}

const FormCanadaPostComponent: ng.IComponentOptions = {
  controller: FormCanadaPost,
  template,
  bindings: {
    esCourierDetails: '<',
    esError: '<',
    esEditMode: '<',
    esOnBack: '&',
    esOnDelete: '&',
  },
};

export { FormCanadaPostComponent };
