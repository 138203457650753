import React from 'react';
import { CheckIcon } from '@client/src/components/icons/CheckIcon';
import { FormattedMessage } from 'react-intl';
import useNsofAbTestVariation, {
  NsofAbTestVariationEnum,
} from '../../hooks/useNsofAbTestVariation';

export function AuthLandingHeader() {
  const { variation: nsofVariation } = useNsofAbTestVariation();

  return (
    <>
      {nsofVariation === NsofAbTestVariationEnum.enum.alpha ? (
        <div className="flex items-center justify-center w-full sm:mb-10 mb-6">
          <CheckIcon className="mr-2" />
          <p className="sm:text-lg sm:leading-8 text-base leading-6 text-ink-900 mb-0 mr-5">
            <FormattedMessage id="auth-landing.alpha.no-credit" />
          </p>
          <CheckIcon className="mr-2" />
          <p className="sm:text-lg sm:leading-8 text-base leading-6 text-ink-900 mb-0">
            <FormattedMessage id="auth-landing.alpha.cancel-anytime" />
          </p>
        </div>
      ) : (
        <p className="sm:text-xl text-lg text-ink-900 sm:mb-10 mb-6 text-center">
          <FormattedMessage id="signup.free-trial" />
        </p>
      )}
    </>
  );
}
