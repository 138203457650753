import lottie from 'lottie-web';

import { IApiConfig } from 'typings/core/config/api';

import { IComponentController } from 'angular';
import { IUserSession } from 'typings/user-session';
import template from './empty-page.html?raw';
import style from './empty-page.module.scss';

class EmptyPage implements IComponentController {
  style = style;

  static $inject = ['API', '$state', '$element', 'UserSession'];
  constructor(
    private API: IApiConfig,
    private $state: ng.ui.IStateService,
    private $element: ng.IAugmentedJQuery,
    private UserSession: IUserSession
  ) {}

  $onInit(): void {
    lottie.loadAnimation({
      container: this.$element.find('.animation')[0],
      renderer: 'svg',
      loop: true,
      path: `${this.API.easyship_storage}/pickups/animations/demo/data.json`,
    });
  }

  goToShipmentsPage(): void {
    this.$state.go('app.multiple');
  }

  get showCreateShipment(): boolean {
    return this.UserSession.hasUserRole('create_shipments');
  }
}

const EmptyPageComponent: ng.IComponentOptions = {
  controller: EmptyPage,
  template,
};

export { EmptyPageComponent };
