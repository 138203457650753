import Input from '@client/core/components/react/Input';
import { toastError } from '@client/core/components/react/Toastify';
import useGeolocationQuery from '@client/core/corelogic/useGeolocation';
import useShippingCountriesQuery from '@client/src/auth/hooks/useShippingCountriesQuery';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'easyship-components';
import { z } from 'zod';
import CountrySelect from '../../CountrySelect';

const userInformationFormDefaultData: UserInformationFormData = {
  firstName: '',
  lastName: '',
  companyName: '',
  countryId: -1,
};

export interface UserInformationFormData {
  firstName: string;
  lastName: string;
  companyName?: string;
  countryId: number;
}

const userInformationFormSchema = z.object({
  firstName: z.string().nonempty(),
  lastName: z.string().nonempty(),
  countryId: z.number().nonnegative({
    message: 'Please fill in required fields',
  }),
  companyName: z
    .string()
    .max(100, {
      message: 'Company Name must be less than 100 characters',
    })
    .optional(),
});

interface UserInformationFormProps {
  defaultData?: Partial<UserInformationFormData>;
  onSubmit?: (payload: UserInformationFormData) => void;
  isLoading?: boolean;
}

type FormField = keyof UserInformationFormData;
type FieldErrors = Partial<Record<FormField, string>>;

function UserInformationForm(
  { defaultData, onSubmit, isLoading }: UserInformationFormProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const { isFetching: isGeolocationFetching, data: geolocation } = useGeolocationQuery();
  const { isFetching: isShippingCountriesFetching, data: shippingCountries = [] } =
    useShippingCountriesQuery();

  const [formData, setFormData] = useState<UserInformationFormData>({
    ...userInformationFormDefaultData,
    ...defaultData,
  });
  const [errors, setErrors] = useState<FieldErrors>({});

  useEffect(() => {
    if (shippingCountries.length === 0) return;

    if (defaultData?.countryId) {
      const defaultCountryFound = shippingCountries.find(
        (country) => country.id === defaultData.countryId
      );
      if (defaultCountryFound) {
        setFormData((formData) => ({ ...formData, countryId: defaultCountryFound.id }));
      }
    } else if (geolocation) {
      const geolocationCountryFound = shippingCountries.find(
        (country) => country.alpha2 === geolocation.countryCode
      );
      if (geolocationCountryFound) {
        setFormData((formData) => ({ ...formData, countryId: geolocationCountryFound.id }));
      }
    }
  }, [defaultData?.countryId, geolocation, shippingCountries]);

  const { formatMessage } = useIntl();

  function updateFormData(updates: Partial<UserInformationFormData>): void {
    setFormData((prevFormData) => ({ ...prevFormData, ...updates }));
  }

  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const userInformation = userInformationFormSchema.safeParse(formData);
    if (userInformation.success) {
      const payload: UserInformationFormData = {
        firstName: userInformation.data.firstName,
        lastName: userInformation.data.lastName,
        countryId: userInformation.data.countryId,
        companyName: userInformation.data.companyName,
      };
      onSubmit?.(payload);
    } else {
      const newErrors: FieldErrors = {
        firstName: userInformation.error.formErrors.fieldErrors.firstName?.[0] ?? undefined,
        lastName: userInformation.error.formErrors.fieldErrors.lastName?.[0] ?? undefined,
        companyName: userInformation.error.formErrors.fieldErrors.companyName?.[0] ?? undefined,
        countryId: userInformation.error.formErrors.fieldErrors.countryId?.[0] ?? undefined,
      };
      setErrors(newErrors);
      toastError('Please fill in the required fields');
    }
  }

  return (
    <form ref={ref} onSubmit={handleFormSubmit}>
      <div className="grid grid-cols-1 gap-3">
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <Input
            name="firstName"
            onChange={(e) => updateFormData({ firstName: e.target.value })}
            error={Boolean(errors.firstName)}
            label={formatMessage({ id: 'global.first-name' })}
            defaultValue={defaultData?.firstName}
          />
          <Input
            name="lastName"
            onChange={(e) => updateFormData({ lastName: e.target.value })}
            error={Boolean(errors.lastName)}
            label={formatMessage({ id: 'global.last-name' })}
            defaultValue={defaultData?.lastName}
          />
        </div>
        <div className="grid items-start grid-cols-1 gap-2 sm:grid-cols-2">
          <Input
            name="companyName"
            flag="optional"
            onChange={(e) => updateFormData({ companyName: e.target.value })}
            error={Boolean(errors.companyName)}
            helperText={errors.companyName}
            label={formatMessage({ id: 'global.company-name' })}
            defaultValue={defaultData?.companyName}
          />
          <CountrySelect
            name="countryId"
            label={formatMessage({ id: 'global.country' })}
            value={formData.countryId}
            loading={isGeolocationFetching || isShippingCountriesFetching}
            onChange={(e) => updateFormData({ countryId: e.target.value as number })}
            error={Boolean(errors.countryId)}
          />
        </div>
        <Button
          loading={isLoading}
          color="primary"
          className="w-full h-[60px] text-lg rounded-md mt-5"
          type="submit"
        >
          Create Account
        </Button>
      </div>
    </form>
  );
}

export default React.forwardRef(UserInformationForm);
