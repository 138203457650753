import Input from '@client/core/components/react/Input';
import React, { useEffect, useState } from 'react';
import { toastError } from '@client/core/components/react/Toastify';
import useGeolocationQuery from '@client/core/corelogic/useGeolocation';
import Link from '@client/core/components/react/Link';
import { FormattedMessage, useIntl } from 'react-intl';
import { Email } from '@client/src/utils/email';
import { IUserSession } from 'typings/user-session';
import { Button } from 'easyship-components';
import useShippingCountriesQuery from '../hooks/useShippingCountriesQuery';
import CountrySelect from './CountrySelect';
import useSignupMutation from '../hooks/useSignupMutation';
import { SignupFormData } from './SignupForm.types';
import useNsofAbTestVariation, { NsofAbTestVariationEnum } from '../hooks/useNsofAbTestVariation';

const signupFormDefaultData: SignupFormData = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  countryId: -1,
};

type FormField = keyof SignupFormData;
type FieldErrors = Partial<Record<FormField, string>>;

interface SignupFormProps {
  defaultData?: Partial<SignupFormData>;
  recaptchaToken: string;
  onSignupError?: () => void;
  onSignupSuccess?: (user: IUserSession) => void;
}

export function SignupForm(
  { onSignupSuccess, onSignupError, defaultData, recaptchaToken }: SignupFormProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const { formatMessage } = useIntl();
  const { isFetching: isGeolocationFetching, data: geolocation } = useGeolocationQuery();
  const { mutateAsync: signupMutateAsync, isLoading } = useSignupMutation();
  const [errors, setErrors] = useState<FieldErrors>({});
  const [formData, setFormData] = useState<SignupFormData>({
    ...signupFormDefaultData,
    ...defaultData,
  });

  const { isFetching: isShippingCountriesFetching, data: shippingCountries = [] } =
    useShippingCountriesQuery();

  const { variation: nsofVariation } = useNsofAbTestVariation();

  useEffect(() => {
    if (shippingCountries.length === 0) return;

    if (defaultData?.countryId) {
      const defaultCountryFound = shippingCountries.find(
        (country) => country.id === defaultData.countryId
      );
      if (defaultCountryFound) {
        setFormData((formData) => ({ ...formData, countryId: defaultCountryFound.id }));
      }
    } else if (geolocation) {
      const geolocationCountryFound = shippingCountries.find(
        (country) => country.alpha2 === geolocation.countryCode
      );
      if (geolocationCountryFound) {
        setFormData((formData) => ({ ...formData, countryId: geolocationCountryFound.id }));
      }
    }
  }, [defaultData?.countryId, geolocation, shippingCountries]);

  function updateFormData(updates: Partial<SignupFormData>): void {
    setFormData((prevFormData) => ({ ...prevFormData, ...updates }));
  }

  function isFormValid(): boolean {
    const emailError = validateEmail(formData.email);
    const passwordError = validatePassword(formData.password);
    const firstNameError = validateFirstName(formData.firstName);
    const lastNameError = validateLastName(formData.lastName);
    const companyNameError = validateCompanyName(formData.companyName);
    const countryIdError = validateCountryId(formData.countryId);

    const newErrors: FieldErrors = {
      email: emailError ?? undefined,
      password: passwordError ?? undefined,
      firstName: firstNameError ?? undefined,
      lastName: lastNameError ?? undefined,
      companyName: companyNameError ?? undefined,
      countryId: countryIdError ?? undefined,
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((value) => value === undefined);
  }

  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    if (isFormValid()) {
      const payload: SignupFormData = {
        email: formData.email.trim(),
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
        countryId: +formData.countryId,
        companyName: formData.companyName,
      };
      signupMutateAsync({
        payload,
        recaptchaToken,
      })
        .then(onSignupSuccess)
        .catch((reason) => {
          toastError(
            typeof reason === 'string' ? reason : formatMessage({ id: 'toast.default-error' })
          );
          onSignupError?.();
        });
    } else {
      toastError('Please fill in the required fields');
    }
  }

  return (
    <form ref={ref} onSubmit={handleFormSubmit}>
      <div className="flex flex-col gap-6">
        <Input
          onChange={(e) => updateFormData({ email: e.target.value })}
          error={Boolean(errors.email)}
          label={formatMessage({ id: 'global.email' })}
          name="email"
          helperText={errors.email}
          defaultValue={defaultData?.email}
        />
        <Input
          onChange={(e) => updateFormData({ password: e.target.value })}
          error={Boolean(errors.password)}
          label={formatMessage({ id: 'global.password' })}
          type="password"
          helperText={errors.password ? errors.password : 'Must be 8 characters or above'}
        />
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 sm:col-span-1">
            <Input
              onChange={(e) => updateFormData({ firstName: e.target.value })}
              error={Boolean(errors.firstName)}
              label={formatMessage({ id: 'global.first-name' })}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <Input
              onChange={(e) => updateFormData({ lastName: e.target.value })}
              error={Boolean(errors.lastName)}
              label={formatMessage({ id: 'global.last-name' })}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 sm:col-span-1">
            <Input
              flag="optional"
              onChange={(e) => updateFormData({ companyName: e.target.value })}
              error={Boolean(errors.companyName)}
              label={formatMessage({ id: 'global.company-name' })}
            />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <CountrySelect
              label={formatMessage({ id: 'global.country' })}
              value={formData.countryId}
              loading={isGeolocationFetching || isShippingCountriesFetching}
              onChange={(e) => updateFormData({ countryId: e.target.value as number })}
              error={Boolean(errors.countryId)}
            />
          </div>
        </div>
        <div>
          <Button
            className="w-full text-lg font-bold leading-6 rounded-md"
            style={{ height: 60 }}
            type="submit"
            color="primary"
            loading={isLoading}
          >
            {nsofVariation === NsofAbTestVariationEnum.enum.alpha ? (
              <FormattedMessage id="signup.try-free" />
            ) : (
              <FormattedMessage id="signup.start-shipping" />
            )}
          </Button>
          <div className="mt-5 text-base leading-4 text-ink-300">
            <FormattedMessage id="signup.agreement.accept" />{' '}
            <Link href="https://www.easyship.com/legal/terms/overview" target="_blank">
              <FormattedMessage id="signup.agreement.terms" />
            </Link>{' '}
            <FormattedMessage id="global.and" />{' '}
            <Link href="https://www.easyship.com/legal/privacy" target="_blank">
              <FormattedMessage id="signup.agreement.privacy" />
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
}

function validateEmail(email: string): string | null {
  const { isValid } = new Email(email);
  if (!isValid || email.length === 0) return 'Invalid email format';
  return null;
}

function validatePassword(password: string): string | null {
  if (password.length < 8) return 'Password must be 8 characters or above';
  return null;
}

function validateFirstName(firstName: string): string | null {
  if (firstName.length === 0) return 'Invalid first name';
  return null;
}

function validateLastName(lastName: string): string | null {
  if (lastName.length === 0) return 'Invalid last name';
  return null;
}

function validateCountryId(countryId: number): string | null {
  if (countryId < 1) return 'Invalid country';
  return null;
}

function validateCompanyName(companyName: string | undefined): string | null {
  if (companyName && companyName.length > 100)
    return 'Company Name must be less than 100 characters';
  return null;
}

export default React.forwardRef(SignupForm);
