import { ICompanyService, IPromoCodeResponse } from 'typings/company';
import { IUserStatusService, IVoucher } from 'typings/user-status';
import { IUserSession } from 'typings/user-session';

import { IComponentController } from 'angular';
import voucherImageUrl from '@assets/images/dashboard/add-credit/voucher.svg';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import template from './promo-code.html?raw';
import style from './promo-code.module.scss';

class PromoCode implements IComponentController {
  style = style;
  voucherImageUrl = voucherImageUrl;
  busyVoucher = false;
  voucherCode = '';
  isAddressModalOpen = false;
  saveAddressSuccess = () => {
    //
  };

  static $inject = ['$translate', 'CompanyService', 'UserStatusService', 'UserSession'];
  constructor(
    private $translate: angular.translate.ITranslateService,
    private CompanyService: ICompanyService,
    private UserStatusService: IUserStatusService,
    private UserSession: IUserSession
  ) {
    this.closeAddressModal = this.closeAddressModal.bind(this);
  }

  get voucher(): IVoucher | null {
    return this.UserStatusService.voucher;
  }

  redeemVoucher(): void {
    if (!this.UserSession.hasPickupAddress()) {
      this.isAddressModalOpen = true;
      this.saveAddressSuccess = () => {
        this.isAddressModalOpen = false;
        this.redeemVoucher();
      };
      return;
    }

    this.busyVoucher = true;
    this.CompanyService.redeemVoucher(this.voucherCode)
      .then((response: IPromoCodeResponse) => {
        if (response.unit === 'amount') {
          this.notifySuccess('account.payment.notifications.voucher-redeemed-amount', {
            figure: response.figure.toString(),
          });
        } else if (response.unit === 'percentage') {
          this.notifySuccess('account.payment.notifications.voucher-redeemed-percentage', {
            code: this.voucherCode,
          });
        } else {
          this.notifySuccess('account.payment.notifications.voucher-redeemed');
        }
        this.voucherCode = '';

        // Retrigger update status to get any added voucher
        // (only for voucher_type 'discount' and 'subscription')
        this.CompanyService.updateStatus();
      })
      .catch((err) => {
        if (err.data) {
          toastError(err.data.message);
        }
      })
      .finally(() => {
        this.busyVoucher = false;
      });
  }

  private notifySuccess(translationId: string, values?: Record<string, string>) {
    toastSuccess(this.$translate.instant(translationId, values, 'messageformat'));
  }

  onInputModelChange(value: string): void {
    this.voucherCode = value;
  }

  closeAddressModal() {
    this.isAddressModalOpen = false;
    this.saveAddressSuccess = () => {
      //
    };
  }
}

const PromoCodeComponent: ng.IComponentOptions = {
  controller: PromoCode,
  template,
  bindings: {},
};

export { PromoCodeComponent };
