import CountryFlag from '@client/core/components/react/CountryFlag';
import Select, { SelectProps } from '@client/core/components/react/Select';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useShippingCountriesQuery from '../hooks/useShippingCountriesQuery';

function CountrySelect({ ...props }: SelectProps, ref: React.ForwardedRef<HTMLElement>) {
  const { data: shippingCountries = [] } = useShippingCountriesQuery();
  return (
    <Select {...props} style={{ height: 40 }} ref={ref}>
      <MenuItem disabled value={-1}>
        <div className="text-base leading-4 text-ink-100">
          <FormattedMessage id="global.choose-one" />
        </div>
      </MenuItem>
      {shippingCountries.map((shippingCountry) => (
        <MenuItem key={shippingCountry.id} value={shippingCountry.id}>
          <Box component="span" mr={0.625}>
            <CountryFlag alpha2={shippingCountry.alpha2} />
          </Box>
          {shippingCountry.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default React.forwardRef(CountrySelect);
