// @flow
import * as React from 'react';
import { memo, useCallback, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useIntl } from 'react-intl';
import { Tooltip } from 'easyship-components';

type Props = {
  className?: string;
  label?: string;
  labelId?: string;
};

export interface Resizable {
  resizeHandler?: () => void;
}

export interface ResizeListener {
  (): void;
}

const DELAY = 500;

const store = {
  listeners: new Set<ResizeListener>(),
  th: undefined,
} as {
  listeners: Set<ResizeListener>;
  th: undefined | ReturnType<typeof setTimeout>;
};

export const notifyListener = () => {
  const triggerListeners = () => {
    store.listeners.forEach((listener) => {
      listener();
    });
  };
  clearTimeout(store.th);
  store.th = setTimeout(triggerListeners, DELAY);
};

window.addEventListener('resize', notifyListener);

const isTextOverflowWithEllipsis = (target?: HTMLElement | null): boolean =>
  !!(
    target &&
    (target.offsetWidth < target.scrollWidth || target.scrollHeight > target.clientHeight)
  );
/**
 * TruncateText is a component that truncates text with ellipsis and displays a tooltip with the full text on hover.
 *
 * @param {Object} props - The props for TruncateText component.
 * @param {string} props.className - The additional CSS class for styling the component. use `whitespace-normal line-clamp-N` to overwrite for multi row N
 * @param {string} props.label - The label for the text to be displayed.
 * @param {string} props.labelId - The id of the label for internationalization.
 * @param {string} props.direction - The direction for the text. Can be 'ltr' or 'rtl'.
 *
 * @returns {React.Element} The TruncateText component.
 */
const TruncateText = memo(({ className, label, labelId }: Props) => {
  const ref = useRef<HTMLDivElement & Resizable>(null);
  const [isActiveToolTip, setActiveToolTip] = React.useState<boolean>(false);

  const { formatMessage } = useIntl();
  const labelTitle = (labelId && formatMessage({ id: labelId })) || label || '';
  const listener = useCallback(() => {
    setActiveToolTip(isTextOverflowWithEllipsis(ref.current));
  }, []);

  useEffect(() => {
    if (labelTitle?.length) {
      listener();
    }
  }, [labelTitle, listener]);

  useEffect(() => {
    store.listeners.add(listener);
    return () => {
      store.listeners.delete(listener);
    };
  }, [listener]);
  return (
    <Tooltip label={labelTitle} disabled={!isActiveToolTip} className="block max-w-full">
      <div
        ref={ref}
        className={twMerge('overflow-hidden text-ellipsis whitespace-nowrap', className)}
      >
        {labelTitle}
      </div>
    </Tooltip>
  );
});

export default TruncateText;
