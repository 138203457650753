import Input from '@client/core/components/react/Input';
import { toastError } from '@client/core/components/react/Toastify';
import { SIGNUP_EMAIL_REGEX } from '@client/src/data/regex';
import { Button } from 'easyship-components';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { z } from 'zod';

export interface CredentialsFormData {
  email: string;
  password: string;
}

interface CredentialsFormProps {
  defaultData?: Partial<CredentialsFormData>;
  onSubmit?: (payload: CredentialsFormData) => void;
  isLoading?: boolean;
}

type FormField = keyof CredentialsFormData;
type FieldErrors = Partial<Record<FormField, string>>;

function CredentialsForm(
  { defaultData, onSubmit, isLoading }: CredentialsFormProps,
  ref: React.ForwardedRef<HTMLFormElement>
) {
  const { formatMessage } = useIntl();

  const credentialsFormSchema = z.object({
    email: z.string().regex(SIGNUP_EMAIL_REGEX, {
      message: formatMessage({ id: 'auth-landing.form.email.format-error' }),
    }),
    password: z.string().min(8, {
      message: formatMessage({ id: 'auth-landing.form.password.error' }),
    }),
  });

  const [errors, setErrors] = useState<FieldErrors>({});

  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const data = new FormData(e.currentTarget).entries();
    const formData = Object.fromEntries(data);

    const credentials = credentialsFormSchema.safeParse(formData);

    if (credentials.success) {
      const payload: CredentialsFormData = {
        email: credentials.data.email,
        password: credentials.data.password,
      };
      onSubmit?.(payload);
    } else {
      const newErrors: FieldErrors = {
        email: credentials.error.formErrors.fieldErrors.email?.[0] ?? undefined,
        password: credentials.error.formErrors.fieldErrors.password?.[0] ?? undefined,
      };
      setErrors(newErrors);
      toastError('Please fill in the required fields');
    }
  }

  return (
    <form ref={ref} className="w-full" onSubmit={handleFormSubmit}>
      <div className="grid grid-cols-1 gap-3">
        <Input
          name="email"
          error={Boolean(errors.email)}
          helperText={errors.email}
          label={formatMessage({ id: 'global.email' })}
          defaultValue={defaultData?.email ?? ''}
        />
        <Input
          label={formatMessage({ id: 'global.password' })}
          name="password"
          type="password"
          error={Boolean(errors.password)}
          helperText={
            errors.password
              ? errors.password
              : formatMessage({ id: 'auth-landing.form.password.helper' })
          }
          defaultValue={defaultData?.password ?? ''}
        />
      </div>
      <Button
        loading={isLoading}
        color="primary"
        className="w-full h-[60px] text-lg rounded-md mt-5"
        type="submit"
      >
        <FormattedMessage id="global.continue" />
      </Button>
    </form>
  );
}

export default React.forwardRef(CredentialsForm);
