import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AlphaHeroIcon } from '@client/src/components/images/AlphaHeroIcon';

export function AlphaHero() {
  return (
    <>
      <div className="sm:pt-12 sm:px-[60px] pt-8 px-5">
        <h3 className="sm:mb-10 mb-4 sm:!text-left text-center text-blue-900 text-lg tracking-wide font-bold">
          <FormattedMessage id="auth-landing.hero-title" />
        </h3>
        <h3 className="sm:!text-left text-center sm:text-[26px] text-lg leading-normal text-ink-900">
          <FormattedMessage id="auth-landing.hero-description" />
        </h3>
      </div>
      <AlphaHeroIcon style={{ width: '100%', height: 'auto' }} />
    </>
  );
}
