import angular from 'angular';

import { PdfEmbedComponent } from './pdf-embed.component';

const MODULE_NAME = 'core.components.utilities.pdf-embed';

angular
  .module(MODULE_NAME, [])
  .component('esPdfEmbed', PdfEmbedComponent);

export default MODULE_NAME;
