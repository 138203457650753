import angular from 'angular';

import { FormFedexComponent } from './fedex.component';

const MODULE_NAME = 'app.couriers.courier-create.connect-courier.form.fedex';

angular
  .module(MODULE_NAME, [])
  .component('esConnectCourierFormFedex', FormFedexComponent);

export default MODULE_NAME;
